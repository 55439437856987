import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Concerts from '../components/Concerts';
import Products from '../components/Products';
import Footer from '../components/Footer';
import { useEffect } from 'react';
const HomePage = () => {
  useEffect(() => {
    document.querySelector('body').style.backgroundColor = '#000';
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Concerts />
      <Products />
      <Footer />
    </>
  );
};

export default HomePage;
