export const tickets = [
  {
    zone: 'Front Right',
    row: '',
    tickets_left: 11,
    price: 89.98,
  },
  {
    zone: 'Front Left',
    row: '',
    tickets_left: 3,
    price: 99.98,
  },
  {
    zone: 'Back',
    row: '',
    tickets_left: 11,
    price: 89.98,
  },
  {
    zone: 419,
    row: 14,
    tickets_left: 5,
    price: 98.99,
  },
  {
    zone: 420,
    row: 5,
    tickets_left: 1,
    price: 98,
  },
  {
    zone: 424,
    row: 1,
    tickets_left: 4,
    price: 102.99,
  },
  {
    zone: 425,
    row: 1,
    tickets_left: 11,
    price: 98,
  },
  {
    zone: 426,
    row: 24,
    tickets_left: 2,
    price: 109.99,
  },
  {
    zone: 427,
    row: 13,
    tickets_left: 16,
    price: 79.99,
  },
  {
    zone: 430,
    row: 4,
    tickets_left: 4,
    price: 69.98,
  },
  {
    zone: 401,
    row: 19,
    tickets_left: 10,
    price: 89,
  },
  {
    zone: 402,
    row: 24,
    tickets_left: 23,
    price: 89.99,
  },
  {
    zone: 403,
    row: 11,
    tickets_left: 6,
    price: 198.99,
  },
  {
    zone: 404,
    row: 3,
    tickets_left: 1,
    price: 79.99,
  },
  {
    zone: 407,
    row: 8,
    tickets_left: 2,
    price: 79.99,
  },
  {
    zone: 119,
    row: '',
    tickets_left: 1,
    price: 199.99,
  },
  {
    zone: 120,
    row: '',
    tickets_left: 1,
    price: 89.99,
  },
  {
    zone: 122,
    row: 4,
    tickets_left: 5,
    price: 89.99,
  },
  {
    zone: 123,
    row: '',
    tickets_left: 2,
    price: 79.99,
  },
  {
    zone: 124,
    row: 6,
    tickets_left: 11,
    price: 89.99,
  },
  {
    zone: 125,
    row: '',
    tickets_left: 4,
    price: 89.99,
  },
  {
    zone: 126,
    row: '',
    tickets_left: 4,
    price: 99.99,
  },
  {
    zone: 127,
    row: 3,
    tickets_left: 1,
    price: 99.99,
  },
  {
    zone: 101,
    row: 1,
    tickets_left: 11,
    price: 98.99,
  },
  {
    zone: 103,
    row: 4,
    tickets_left: 1,
    price: 98.99,
  },
  {
    zone: 104,
    row: '',
    tickets_left: 5,
    price: 105.99,
  },
  {
    zone: 105,
    row: 1,
    tickets_left: 2,
    price: 105.99,
  },
  {
    zone: 106,
    row: 1,
    tickets_left: 1,
    price: 109.99,
  },
  {
    zone: 107,
    row: 2,
    tickets_left: 5,
    price: 159.99,
  },
  {
    zone: 108,
    row: 1,
    tickets_left: 2,
    price: 207.99,
  },
  {
    zone: 23,
    row: 1,
    tickets_left: 1,
    price: 107.99,
  },
  {
    zone: 24,
    row: 18,
    tickets_left: 10,
    price: 108.99,
  },
  {
    zone: 25,
    row: 10,
    tickets_left: 1,
    price: 79.99,
  },
  {
    zone: 29,
    row: 21,
    tickets_left: 4,
    price: 99.99,
  },
  {
    zone: 1,
    row: 14,
    tickets_left: 1,
    price: 97.99,
  },
  {
    zone: 2,
    row: 28,
    tickets_left: 6,
    price: 89.99,
  },
  {
    zone: 3,
    row: 1,
    tickets_left: 4,
    price: 89.99,
  },
  {
    zone: 8,
    row: 10,
    tickets_left: 2,
    price: 109.99,
  },
];
