const NorwayStadium = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1262.74 930'
      className='norway'>
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.cls-1{fill:#999;stroke:#fff;stroke-miterlimit:10;stroke-width:1px;}.cls-2{font-size:25px;}.cls-2,.cls-4{fill:#fff;font-family:Helvetica-Light, Helvetica;font-weight:300;}.cls-3{fill:#464646;}.cls-4{font-size:28px;}',
          }}
        />
      </defs>
      <g id='Lower_Tier'>
        <g
          id='_0L'
          data-name='L'
          className='has_listing active_block default_color'>
          <path
            id='_0L-2'
            data-name='L'
            className='cls-1 svg_shape'
            d='M1468.5,961.5l-8-17-26-26.87-17,4.87v102h31l49.33-12.33,28.73-28.73-39.34-40.17Z'
            transform='translate(-315.26 -95)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_0L-3'
            data-name='L'
            className='cls-1 svg_shape'
            d='M1434.54,917.63,1407,889.11l-.39.39H1190.5v135h227v-102Z'
            transform='translate(-315.26 -95)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_L' data-name='L'>
            <text
              className='cls-2 text'
              transform='translate(1146.07 899.23)'
              data-name='L'>
              L
            </text>
          </g>
          <g id='_L-2' data-name='L'>
            <text
              className='cls-2 text'
              transform='translate(988.65 875.88)'
              data-name='L'>
              L
            </text>
          </g>
        </g>
        <g
          id='_0M'
          data-name='M'
          className='has_listing active_block default_color'>
          <polygon
            id='_0M-2'
            data-name='M'
            className='cls-1 svg_shape'
            points='672.59 794.5 672.59 814.36 633.24 814.36 633.24 929.5 875.24 929.5 875.24 794.5 672.59 794.5'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_M' data-name='M'>
            <text className='cls-2 text' transform='translate(744.81 875.66)'>
              M
            </text>
          </g>
        </g>
        <g
          id='_0N'
          data-name='N'
          className='has_listing active_block default_color'>
          <polygon
            id='_0N-2'
            data-name='N'
            className='cls-1 svg_shape'
            points='593.24 814.36 593.24 794.5 391.24 794.5 391.24 929.5 633.24 929.5 633.24 814.36 593.24 814.36'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_N' data-name='N'>
            <text className='cls-2 text' transform='translate(502.45 875.52)'>
              N
            </text>
          </g>
        </g>
        <g
          id='_0O'
          data-name='0O'
          className='no_listing disabled_color active_block'>
          <polygon
            id='_0O-2'
            data-name='0O'
            className='cls-1 svg_shape'
            points='144.77 826.4 144.77 824 144.24 824.5 130.24 811.5 55.24 887.19 55.24 888.6 81.24 915.5 130.24 929.5 163.24 929.5 163.24 826.4 144.77 826.4'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <polygon
            id='_0O-3'
            data-name='0O'
            className='cls-1 svg_shape'
            points='176.06 794.5 144.77 824 144.77 826.4 163.24 826.4 163.24 929.5 391.24 929.5 391.24 794.5 176.06 794.5'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0O_text' data-name='0O_text'>
            <text className='cls-2 text' transform='translate(257.2 875.46)'>
              O
            </text>
          </g>
          <g id='_0O_text-2' data-name='0O_text'>
            <text className='cls-2 text' transform='translate(112.89 888.46)'>
              O
            </text>
          </g>
        </g>
        <g
          id='_0D'
          data-name='0D'
          className='no_listing disabled_color active_block'>
          <polygon
            id='_0D-2'
            data-name='0D'
            className='cls-1 svg_shape'
            points='164.18 0.5 131.17 0.5 85.24 13.5 54.24 40.68 54.24 42.34 112.24 99.5 126.24 84.5 144.89 103.5 163.24 103.5 164.18 0.5'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <polygon
            id='_0D-3'
            data-name='0D'
            className='cls-1 svg_shape'
            points='164.18 0.5 163.24 103.5 144.89 103.5 176.3 135.5 320.24 135.5 320.24 0.5 164.18 0.5'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0D_text' data-name='0D_text'>
            <text className='cls-2 text' transform='translate(222.58 72.57)'>
              D
            </text>
          </g>
          <g id='_0D_text-2' data-name='0D_text'>
            <text className='cls-2 text' transform='translate(109.79 57.72)'>
              D
            </text>
          </g>
        </g>
        <g
          id='_0E'
          data-name='0E'
          className='no_listing disabled_color active_block'>
          <rect
            id='_0E-2'
            data-name='0E'
            className='cls-1 svg_shape'
            x='320.24'
            y='0.5'
            width={205}
            height={135}
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0E_text' data-name='0E_text'>
            <text className='cls-2 text' transform='translate(414.58 72.21)'>
              E
            </text>
          </g>
        </g>
        <g
          id='_0F'
          data-name='F'
          className='has_listing active_block default_color'>
          <rect
            id='_0F-2'
            data-name='F'
            className='cls-1 svg_shape'
            x='525.24'
            y='0.5'
            width={214}
            height={135}
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_F' data-name='F'>
            <text
              className='cls-2 text'
              transform='translate(624.12 72.5)'
              data-name='F'>
              F
            </text>
          </g>
        </g>
        <g
          id='_0G'
          data-name='G'
          className='has_listing active_block default_color'>
          <rect
            id='_0G-2'
            data-name='G'
            className='cls-1 svg_shape'
            x='739.24'
            y='0.5'
            width={206}
            height={135}
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_G' data-name='G'>
            <text
              className='cls-2 text'
              transform='translate(831.5 72.75)'
              data-name='G'>
              G
            </text>
          </g>
        </g>
        <g
          id='_0H'
          data-name='H'
          className='has_listing active_block default_color'>
          <path
            id='_0H-2'
            data-name='H'
            className='cls-1 svg_shape'
            d='M1499.48,110.48l-48.5-15H1415.5V200.27h19.72v1.51l.28-.28,13.75,13.25,77.25-77.25C1517.94,128.93,1509.22,120.22,1499.48,110.48Z'
            transform='translate(-315.26 -95)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <polygon
            id='_0H-3'
            data-name='H'
            className='cls-1 svg_shape'
            points='1100.24 105.27 1100.24 0.5 945.24 0.5 945.24 135.5 1090.31 135.5 1119.96 106.78 1119.96 105.27 1100.24 105.27'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_G' data-name='H'>
            <text
              className='cls-2 text'
              transform='translate(1024.23 72.71)'
              data-name='H'>
              H
            </text>
          </g>
          <g id='_G-2' data-name='H'>
            <text
              className='cls-2 text'
              transform='translate(1148.22 64.37)'
              data-name='H'>
              H
            </text>
          </g>
        </g>
        <g
          id='_0A'
          data-name='0A'
          className='no_listing disabled_color active_block'>
          <polygon
            id='_0A-2'
            data-name='0A'
            className='cls-1 svg_shape'
            points='0.5 575.56 144.5 575.56 144.5 766.81 116.32 793.53 110.5 776.91 1.12 776.91 0.5 774.62 0.5 575.56'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_0A-3'
            data-name='0A'
            className='cls-1 svg_shape'
            d='M431.58,888.53l13.92,18-75.8,76.35c-9.14-9.13-20.08-19.08-29.9-28.9l-23.42-82H425.76Z'
            transform='translate(-315.26 -95)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0A_text' data-name='0A_text'>
            <text className='cls-2 text' transform='translate(57.57 827.3)'>
              A
            </text>
          </g>
          <g id='_0A_text-2' data-name='0A_text'>
            <text className='cls-2 text' transform='translate(59.18 690.46)'>
              A
            </text>
          </g>
        </g>
        <g
          id='_0B'
          data-name='0B'
          className='no_listing disabled_color active_block'>
          <rect
            id='_0B-2'
            data-name='0B'
            className='cls-1 svg_shape'
            x='0.5'
            y='341.41'
            width={144}
            height='234.14'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0B_text' data-name='0B_text'>
            <text className='cls-2 text' transform='translate(59.19 465.78)'>
              B
            </text>
          </g>
        </g>
        <g
          id='_0C'
          data-name='0C'
          className='no_listing disabled_color active_block '>
          <polygon
            id='_0C-2'
            data-name='0C'
            className='cls-1 svg_shape'
            points='0.5 152.91 112.45 152.91 112.45 132.92 114.51 132.92 144.5 163.77 144.5 341.41 0.5 341.41 0.5 152.91'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_0C-3'
            data-name='0C'
            className='cls-1 svg_shape'
            d='M427.71,247.91H315.76v-.11l23.15-81,29.85-29.85L427.5,194.5l-14,16,16.26,17.41h-2.05Z'
            transform='translate(-315.26 -95)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0C_text' data-name='0C_text'>
            <text className='cls-2 text' transform='translate(58.5 254.67)'>
              C
            </text>
          </g>
          <g id='_0C_text-2' data-name='0C_text'>
            <text className='cls-2 text' transform='translate(50.74 115.08)'>
              C
            </text>
          </g>
        </g>
        <g
          id='_0I'
          data-name='I'
          className='has_listing active_block default_color'>
          <polygon
            id='_0I-2'
            data-name='I'
            className='cls-1 svg_shape'
            points='1262.24 153.5 1150.29 153.5 1150.29 133.51 1148.23 133.51 1118.24 164.36 1118.24 342 1262.24 342 1262.24 153.5'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_0I-3'
            data-name='I'
            className='cls-1 svg_shape'
            d='M1465.55,248.5h112v-.11l-22.15-82L1526.5,137.5l-77.25,77.25,14.25,13.75h2Z'
            transform='translate(-315.26 -95)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_I' data-name='I'>
            <text
              className='cls-2 text'
              transform='translate(1193.29 106.63)'
              data-name='I'>
              I
            </text>
          </g>
          <g id='_I-2' data-name='I'>
            <text
              className='cls-2 text'
              transform='translate(1187.5 254.66)'
              data-name='I'>
              I
            </text>
          </g>
        </g>
        <g
          id='_0J'
          data-name='J'
          className='has_listing active_block default_color'>
          <rect
            id='_0J-2'
            data-name='J'
            className='cls-1 svg_shape'
            x='1118.24'
            y={342}
            width={144}
            height='234.14'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_J' data-name='J'>
            <text
              className='cls-2 text'
              transform='translate(1183.74 465.78)'
              data-name='J'>
              J
            </text>
          </g>
        </g>
        <g
          id='_0K'
          data-name='K'
          className='has_listing active_block default_color'>
          <polygon
            id='_0K-2'
            data-name='K'
            className='cls-1 svg_shape'
            points='1262.24 576.14 1118.24 576.14 1118.24 767.4 1146.42 794.12 1152.24 777.5 1261.62 777.5 1262.24 775.2 1262.24 576.14'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_0K-3'
            data-name='K'
            className='cls-1 svg_shape'
            d='M1461.68,889.12l27.82,26.38,17,9-19.28,18.77,39.34,40.17,27.9-27.9,22.42-83H1467.5Z'
            transform='translate(-315.26 -95)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_K' data-name='K'>
            <text
              className='cls-2 text'
              transform='translate(1183.19 690.46)'
              data-name='K'>
              K
            </text>
          </g>
          <g id='_K-2' data-name='K'>
            <text className='cls-2 text' transform='translate(1201.21 840.56)'>
              K
            </text>
          </g>
        </g>
      </g>
      <g id='Stage'>
        <polygon
          id='_0Stage'
          data-name='0Stage'
          className='svg_shape'
          points='911.74 441 259.74 441 259.74 237 179.74 237 179.74 690 259.74 690 259.74 496 911.74 496 911.74 441'
        />
        <g id='_0Stage_text' data-name='0Stage_text'>
          <text
            className='cls-2 text'
            transform='translate(225.87 489.94) rotate(-90)'>
            Stage
          </text>
        </g>
      </g>
      <g id='Floor'>
        <g
          id='_0Back'
          data-name='Back'
          className='has_listing active_block default_color'>
          <polygon
            id='_0Back-2'
            data-name='Back'
            className='cls-1 svg_shape'
            points='469.74 156 469.74 411.5 938.24 411.5 938.24 520.5 469.74 520.5 469.74 775 1033.74 775 1033.74 156 469.74 156'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0Back_text' data-name='Back'>
            <text
              className='cls-4 text'
              transform='translate(715.26 653.02)'
              data-name='Back'>
              Back
            </text>
          </g>
        </g>
        <g
          id='_0FrontLeft'
          data-name='Front Left'
          className='has_listing active_block default_color'>
          <polygon
            id='_0FrontLeft-2'
            data-name='Front Left'
            className='cls-1 svg_shape'
            points='286.24 697.5 467.22 749.5 470.24 749.5 470.24 520.5 335.35 520.5 286.24 576.5 286.24 697.5'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0FrontLeft_text' data-name='Front Left'>
            <text
              className='cls-4 text'
              transform='translate(322.52 637.72)'
              data-name='Front Left'>
              Front Left
            </text>
          </g>
        </g>
        <g
          id='_0FrontRight'
          data-name='Front Right'
          className='has_listing active_block default_color'>
          <polygon
            id='_0FrontRight-2'
            data-name='Front Right'
            className='cls-1 svg_shape'
            points='470.24 182.61 286.24 235.5 286.24 356.5 334.47 411.5 470.24 411.5 470.24 182.61'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0FrontRight_text' data-name='Front Right'>
            <text
              className='cls-4 text'
              transform='translate(314.52 312.72)'
              data-name='Front Right'>
              Front Right
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NorwayStadium;
