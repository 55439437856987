import styled from 'styled-components';

export const Footer = styled.div`
  background: rgb(242, 242, 242);
  /*! CSS Used from: https://www.theweeknd.com/sites/g/files/aaj15616/f/css/css_JSinkK16crV4-9AZX3BBYEjCvCkJmGhoBb--bNeP8Wc.css ; media=all */
  @media all {
    .element-invisible {
      position: absolute !important;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      overflow: hidden;
      height: 1px;
    }
    .menu {
      border: none;
      list-style: none;
      text-align: left;
    }
    .menu .leaf {
      list-style-image: url(https://www.theweeknd.com/sites/all/themes/omega/omega/images/misc/menu-leaf.png?1382488163);
      list-style-type: square;
    }
    .active {
      color: #000;
    }
    .messages {
      margin: 6px 0;
      padding: 10px 10px 10px 50px;
      background-position: 8px 8px;
      background-repeat: no-repeat;
      border: 1px solid;
    }
    fieldset {
      margin-bottom: 1em;
    }
    .form-item,
    .form-actions {
      margin-bottom: 1em;
    }
    .form-item label {
      display: block;
      font-weight: bold;
    }
    .form-item .description {
      font-size: 0.85em;
    }
    .form-checkboxes .form-item {
      margin-bottom: 0.4em;
    }
    label.option {
      display: inline;
      font-weight: normal;
    }
    .form-checkbox {
      vertical-align: middle;
    }
    .form-required {
      color: #f00;
    }
    footer,
    header,
    nav,
    section {
      display: block;
    }
    a {
      background: transparent;
    }
    a:active,
    a:hover {
      outline: 0;
    }
    img {
      border: 0;
    }
    input,
    select {
      color: inherit;
      font: inherit;
      margin: 0;
    }
    select {
      text-transform: none;
    }
    input[type='submit'] {
      -webkit-appearance: button;
      cursor: pointer;
    }
    input::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    input {
      line-height: normal;
    }
    input[type='checkbox'] {
      box-sizing: border-box;
      padding: 0;
    }
    fieldset {
      border: 1px solid #c0c0c0;
      margin: 0 2px;
      padding: 0.35em 0.625em 0.75em;
    }
    *,
    *:after,
    *:before {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      *behavior: url(https://www.theweeknd.com/sites/all/themes/iga-artist-themes/suzuki/behaviors/box-sizing/boxsizing.php);
    }
    img {
      max-width: 100%;
    }
  }
  .block--menu-menu-legal-links-non-modal .leaf a {
    color: rgb(33, 33, 33) !important;
    font-size: 9px !important;
    text-transform: uppercase !important;
    letter-spacing: 0.1em !important;
    font-family: HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue',
      Helvetica, Arial, 'Lucida Grande', sans-serif !important;
  }
  /*! CSS Used from: https://cache.umusic.com/_sites/_halo/theweeknd5/css/theme-theweeknd12-v3b.css ; media=all */
  @media all {
    .active {
      font-weight: inherit;
      color: inherit;
    }
    a img,
    img {
      border: 0;
    }
    .icon-twitter:before {
      content: '\\e807';
    }
    .icon-youtube:before {
      content: '\\e80b';
    }
    .icon-spotify:before {
      content: '\\e810';
    }
    .icon-soundcloud:before {
      content: '\\e811';
    }
    .icon-facebook:before {
      content: '\\e812';
    }
    .icon-google:before {
      content: '\\e816';
    }
    .icon-instagram:before {
      content: '\\e817';
    }
    .icon-apple:before {
      content: '\\e840';
    }
    .exacttarget-webform input[type='checkbox']:before,
    .l-social-first .form-checkboxes.et-lists input[type='checkbox']:before {
      content: '\\e89f';
    }
    .exacttarget-webform input[type='checkbox']:checked:before,
    .l-social-first
      .form-checkboxes.et-lists
      input[type='checkbox']:checked:before {
      content: '\\e8a0';
    }
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-item-name
      label:after,
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-item-name
      + .form-item-pass
      label:after,
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-type-textfield
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-item-name
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-item-name
      + .form-item-pass
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-type-textfield
      label:after {
      content: '\\e8b2';
    }
    footer,
    header,
    nav,
    section {
      display: block;
    }
    a:active,
    a:hover {
      outline: 0;
    }
    img {
      max-width: 100%;
      height: auto;
      -ms-interpolation-mode: bicubic;
      display: inline-block;
      vertical-align: middle;
    }
    input,
    select {
      color: inherit;
      font: inherit;
      margin: 0;
    }
    input,
    select {
      line-height: normal;
    }
    i {
      line-height: inherit;
    }
    select {
      text-transform: none;
    }
    input[type='submit'] {
      -webkit-appearance: button;
      cursor: pointer;
    }
    input::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    input[type='checkbox'] {
      box-sizing: border-box;
      padding: 0;
    }
    *,
    ::after,
    ::before {
      box-sizing: inherit;
    }
    select {
      width: 100%;
    }
    div,
    form,
    h2,
    h4,
    li,
    p,
    ul {
      margin: 0;
      padding: 0;
    }
    ul {
      margin-left: 1.25rem;
    }
    p {
      font-size: inherit;
      line-height: 1.6;
      margin-bottom: 1rem;
      text-rendering: optimizeLegibility;
    }
    i {
      font-style: italic;
    }
    h2,
    h4 {
      font-style: normal;
      text-rendering: optimizeLegibility;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
    ul {
      margin-bottom: 1rem;
    }
    h2 {
      font-size: 2.34375rem;
    }
    h4 {
      font-size: 1.64063rem;
    }
    a {
      background-color: transparent;
      color: #111;
      text-decoration: none;
      line-height: inherit;
      cursor: pointer;
    }
    a:focus,
    a:hover {
      color: #0f0f0f;
    }
    ul {
      line-height: 1.6;
      list-style-position: outside;
    }
    li {
      font-size: inherit;
    }
    ul {
      list-style-type: disc;
    }
    label {
      font-weight: 400;
    }
    [type='text'],
    [type='password'],
    [type='email'] {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 3.04688rem;
      padding: 0.625rem;
      border: 1px solid #cacaca;
      margin: 0 0 1.25rem;
      font-family: inherit;
      font-size: 1.25rem;
      color: #000;
      background-color: #fff;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 0;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    [type='text']:focus,
    [type='password']:focus,
    [type='email']:focus {
      border: 1px solid #8a8a8a;
      background-color: #fff;
      outline: 0;
      box-shadow: 0 0 5px #cacaca;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    }
    input:-ms-input-placeholder {
      color: #cacaca;
    }
    input::placeholder {
      color: #cacaca;
    }
    input:disabled {
      background-color: #e6e6e6;
      cursor: default;
    }
    [type='submit'] {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    [type='checkbox'] {
      margin: 0 0 1.25rem;
    }
    [type='checkbox'] + label {
      display: inline-block;
      margin-left: 0.625rem;
      margin-right: 1.25rem;
      margin-bottom: 0;
      vertical-align: baseline;
    }
    [type='checkbox'] + label[for] {
      cursor: pointer;
    }
    label {
      display: block;
      margin: 0;
      font-size: 1.09375rem;
      line-height: 1.8;
      color: #000;
    }
    fieldset {
      margin: 0;
    }
    select {
      border: 1px solid #cacaca;
    }
    fieldset {
      border: 0;
      padding: 0;
    }
    select {
      height: 3.04688rem;
      padding: 0.625rem;
      margin: 0 0 1.25rem;
      font-size: 1.25rem;
      font-family: inherit;
      color: #000;
      background-color: #fff;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%28138, 138, 138%29"></polygon></svg>');
      background-size: 9px 6px;
      background-position: right center;
      background-origin: content-box;
      background-repeat: no-repeat;
    }
    select:disabled {
      background-color: #e6e6e6;
      cursor: default;
    }
    select::-ms-expand {
      display: none;
    }
    .button {
      display: inline-block;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      -webkit-appearance: none;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      vertical-align: middle;
      border-radius: 0;
      padding: 0.75em 1em 0.6em;
      margin: 0 0 1rem;
      font-size: 0.9rem;
      border: 1px solid #fff;
      color: #fff;
    }
    .button,
    .button:focus,
    .button:hover {
      background-color: transparent;
    }
    .button:focus,
    .button:hover {
      border-color: gray;
      color: gray;
    }
    .l-social-first .password-reset-link .button {
      font-size: 0.6rem;
    }
    .menu {
      display: -ms-flexbox;
    }
    .l-social-first {
      font-size: 0.75rem;
    }
    .menu {
      -ms-flex-wrap: nowrap;
    }
    .menu a {
      margin-bottom: 0;
    }
    .menu {
      margin: 0;
      list-style-type: none;
      width: 100%;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      flex-wrap: nowrap;
    }
    .menu > li > a {
      padding: 0 0.5rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
    }
    .label {
      display: inline-block;
    }
    .menu > li > a img {
      margin-right: 0.25rem;
    }
    .menu > li {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .label {
      background: #000;
    }
    .label {
      padding: 0.33333rem 0.5rem;
      font-size: 0.8rem;
      line-height: 1;
      white-space: nowrap;
      cursor: default;
      border-radius: 0;
      color: #fff;
    }
    *,
    :after,
    :before {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
    footer,
    header,
    nav,
    section {
      display: block;
    }
    .exacttarget-webform .fieldset-description h4 {
      font-family: HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue',
        Helvetica, Arial, 'Lucida Grande', sans-serif;
    }
    .l-footer {
      position: relative;
      z-index: 9;
      color: #ccc;
      background: 0 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      padding: 1.125rem 0;
    }
    .l-footer > .l-region--footer {
      width: 100%;
    }
    .l-footer .block--menu-block-iga-common-socials-footer {
      text-align: center;
    }
    .l-footer .follow-socials {
      margin: 1.125rem auto;
      display: inline-block;
      padding: 1.125rem 0 0;
    }
    .l-footer .follow-socials a {
      border: none;
      font-size: 1rem;
      opacity: 1;
      color: #000;
      display: inline-block;
    }
    .l-footer nav .block__title {
      display: none;
    }
    .l-footer::after,
    .l-footer::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    .l-footer::after {
      clear: both;
    }
    .l-footer nav a {
      color: #212121;
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    .l-footer > nav {
      height: auto;
    }
    .l-footer > nav .menu {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      text-align: center;
    }
    input[type='checkbox'] {
      margin: 0;
    }
    form label {
      font-size: 0.5rem;
      font-weight: 700;
      margin: 0 0 0.3rem;
      text-transform: uppercase;
      color: #888;
      transition: color 0.2s ease-in-out;
    }
    form fieldset {
      border-color: #eee;
      width: 100%;
      clear: both;
    }
    .form-actions > input[type='submit'],
    .form-submit {
      display: inline-block;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      -webkit-appearance: none;
      vertical-align: middle;
      padding: 0.75em 1em 0.6em;
      background-color: #000;
      color: #fff;
      font-weight: 700;
      border-radius: 1px;
      margin: 0 10px 10px auto;
      border: none;
      transition: background 0.2s ease-in-out;
      font-size: calc(0.18519vw + 13.41px);
      outline: 0;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    .form-actions > input[type='submit']:focus,
    .form-actions > input[type='submit']:hover,
    .form-submit:focus,
    .form-submit:hover {
      background-color: #2f2f33;
      color: #fff;
    }
    .exacttarget-webform {
      margin: 0 auto;
    }
    .exacttarget-webform > div > .form-item {
      width: 100%;
      margin: 0;
      padding: 10px;
    }
    .exacttarget-webform .fieldset-wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .exacttarget-webform .fieldset-description {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    .exacttarget-webform .fieldset-description h4 {
      margin-bottom: 0;
      line-height: 28px;
      font-size: 0.85938rem;
      letter-spacing: 0.1em;
      color: #000;
    }
    .exacttarget-webform input.webform-submit {
      margin: 1.125rem auto 0;
      display: block;
      background-color: #222;
    }
    .exacttarget-webform .et-umg-disclaimer {
      font-size: 0.6rem;
      font-style: italic;
    }
    .exacttarget-webform .et-umg-disclaimer a {
      font-weight: 700;
    }
    .exacttarget-webform [type='email'],
    .exacttarget-webform [type='text'],
    .exacttarget-webform select {
      background-color: transparent;
      border: 3px solid #000;
      border-width: 0 0 3px;
      font-size: 12px;
      letter-spacing: 0.05em;
      color: #000;
      font-weight: 400;
      padding: 0 8px;
      transition: 0.1s background-color ease-in-out,
        0.1s border-color ease-in-out;
      margin: 0 0 10px;
      text-transform: uppercase;
      box-shadow: none;
    }
    .exacttarget-webform [type='email']:focus,
    .exacttarget-webform [type='email']:hover,
    .exacttarget-webform [type='text']:focus,
    .exacttarget-webform [type='text']:hover,
    .exacttarget-webform select:focus,
    .exacttarget-webform select:hover {
      border-color: #222;
      color: #222;
    }
    .exacttarget-webform [type='email']:-ms-input-placeholder,
    .exacttarget-webform [type='text']:-ms-input-placeholder,
    .exacttarget-webform select:-ms-input-placeholder {
      color: #000;
      opacity: 1;
    }
    .exacttarget-webform [type='email']::placeholder,
    .exacttarget-webform [type='text']::placeholder,
    .exacttarget-webform select::placeholder {
      color: #000;
      opacity: 1;
    }
    .exacttarget-webform input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      border: none;
      background-color: transparent;
      outline: 0;
    }
    .exacttarget-webform input[type='checkbox']:before {
      font-family: fontello;
      position: relative;
      top: -2px;
      font-size: 0.85938rem;
      color: #888;
    }
    .exacttarget-webform input[type='checkbox'] + label {
      font-weight: 700;
      color: #888;
    }
    .exacttarget-webform input[type='checkbox']:checked + label,
    .exacttarget-webform input[type='checkbox']:checked:before {
      color: #222;
    }
    .et-umg-disclaimer,
    .form-actions {
      padding: 0.39375rem 0;
      text-align: center;
    }
    .et-umg-disclaimer {
      color: #000;
    }
    .et-umg-disclaimer a {
      color: #000;
      font-weight: 700;
    }
    .form-type-checkboxes label {
      color: #888;
    }
    .form-type-checkboxes .form-item {
      display: inline-block;
    }
    .icon,
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-item-name
      label:after,
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-item-name
      + .form-item-pass
      label:after,
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-type-textfield
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-item-name
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-item-name
      + .form-item-pass
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-type-textfield
      label:after,
    [class*='icon-'] {
      font-family: fontello;
      position: relative;
      padding: 0 0.28125rem;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      display: inline-block;
      text-indent: 0;
      color: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .icon:before {
      font-family: fontello;
      font-weight: 400;
    }
    [class*='login-modal'] {
      background: rgba(249, 249, 250, 0.9);
    }
    .html .social-separator {
      position: relative;
      width: 100%;
      padding: 2.25rem 0;
    }
    .html .social-separator::after,
    .html .social-separator::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    .html .social-separator::after {
      clear: both;
    }
    .html .social-separator > span {
      line-height: 35px;
      position: relative;
      z-index: 0;
      left: 0;
      display: inline-block;
      float: left;
      width: calc(50% - 20px);
      height: 1px;
      border: none;
      background-color: rgba(136, 136, 136, 0.1);
    }
    .html .social-separator > span:last-child {
      right: 0;
    }
    .html .social-separator > p {
      font-size: 13px;
      font-weight: 700;
      line-height: 34px;
      position: relative;
      top: 0;
      display: inline-block;
      float: left;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      text-align: center;
      letter-spacing: -0.05em;
      color: #cacaca;
      border: 3px solid rgba(136, 136, 136, 0.1);
      border-radius: 50%;
    }
    div.reveal-modal {
      max-width: 640px;
      outline: 0;
      background: #f2f2f2;
      padding: 2.25rem;
    }
    div.reveal-modal.block--bolero-user-login-user-login-modal {
      padding: 0;
    }
    div.reveal-modal a.close-reveal-modal {
      font-weight: 100;
      z-index: 9;
      top: 0.35rem;
      right: 1rem;
      background: 0 0;
    }
    div.reveal-modal.l-social-first > header > div:not(.active) {
      background: 0 0;
    }
    a.login {
      font-size: 10px;
      letter-spacing: 0.03em;
      line-height: 40px;
      display: inline-block;
      width: auto;
      width: 100%;
      height: 40px;
      margin: 2px;
      padding: 0;
      text-align: center;
      vertical-align: middle;
      color: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      -webkit-transition: all 0.2s ease-in-out;
      -transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }
    a.login:focus,
    a.login:hover {
      opacity: 0.9;
      filter: alpha(opacity=90);
    }
    a.login .label {
      padding: 0 10px 0 6px;
      color: rgba(255, 255, 255, 0.8);
      background: 0 0;
    }
    a.login .social {
      font-weight: 700;
      color: #fff;
    }
    a.login i.icon {
      font-size: 14px;
      float: left;
      width: 36px;
      color: #fff;
      background-color: rgba(34, 34, 34, 0.3);
    }
    a.login.facebook {
      background-color: #3b5998;
    }
    a.login.google {
      background-color: #dd4b39;
    }
    a.login.twitter {
      background-color: #00aced;
    }
    .l-social-first {
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      border: 1px solid rgba(204, 204, 204, 0.5);
      border-width: 0 0 1px;
    }
    .l-social-first > * {
      color: #444;
      background-color: #fff;
    }
    .l-social-first > header {
      overflow: hidden;
      width: 100%;
      background-color: transparent;
    }
    .l-social-first > header::after,
    .l-social-first > header::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    .l-social-first > header::after {
      clear: both;
    }
    .l-social-first > header > div {
      position: relative;
      display: inline-block;
      float: left;
      width: 50%;
      padding: 1rem 0.8rem 10px;
      cursor: pointer;
      transition: 0.2s all ease;
      border: 1px solid transparent;
    }
    .l-social-first > header > div:first-child {
      border-width: 1px 1px 1px 0;
    }
    .l-social-first > header > div:first-child.active {
      z-index: 1;
      border-color: rgba(204, 204, 204, 0.5);
      border-bottom-color: transparent;
      background-color: #fff;
      box-shadow: 5px 10px 1px rgba(204, 204, 204, 0.3);
    }
    .l-social-first > header > div:last-child {
      border-width: 1px 0 1px 1px;
    }
    .l-social-first > header > div:before {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20%;
      background-image: linear-gradient(
        to bottom,
        rgba(136, 136, 136, 0),
        rgba(136, 136, 136, 0.2)
      );
      opacity: 0;
      filter: alpha(opacity=0);
    }
    .l-social-first > header > div:not(.active) {
      border-bottom: 1px solid rgba(204, 204, 204, 0.5);
      background: rgba(249, 249, 250, 0.9);
    }
    .l-social-first > header > div:not(.active):before {
      opacity: 1;
      filter: alpha(opacity=100);
    }
    .l-social-first > header > div:not(.active) h2 {
      color: rgba(136, 136, 136, 0.5);
    }
    .l-social-first > header > div:not(.active):hover h2 {
      color: #222;
    }
    .l-social-first > header > div h2 {
      font-size: 24px;
      margin-bottom: 0;
      color: #333;
    }
    .l-social-first > header > div span {
      display: none;
      font-size: 13px;
      font-weight: 400 !important;
      letter-spacing: -0.03em;
      color: #888;
    }
    .l-social-first .ae-social-login-links {
      padding: 1.125rem 5% 0.5rem;
    }
    .l-social-first .form-item-lists > label {
      text-align: center;
      color: #222;
      letter-spacing: 0.2em;
      margin: 5px auto;
    }
    .l-social-first .form-checkboxes.et-lists {
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 5px 0;
    }
    .l-social-first .form-checkboxes.et-lists > .form-item {
      display: inline-block;
      float: left;
      margin: 0 auto;
      padding: 0 !important;
    }
    .l-social-first .form-checkboxes.et-lists > .form-item.form-type-checkbox {
      width: auto;
      margin: 0 5px 0 0;
    }
    .l-social-first
      .form-checkboxes.et-lists
      > .form-item.form-type-checkbox
      label {
      width: auto;
      margin: 0;
    }
    .l-social-first .form-checkboxes.et-lists input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      outline: 0;
      border: none;
      background-color: transparent;
      margin-right: 3px;
    }
    .l-social-first .form-checkboxes.et-lists input[type='checkbox']:before {
      font-family: fontello;
      position: relative;
      top: -2px;
      font-size: 0.85938rem;
      color: #888;
    }
    .l-social-first
      .form-checkboxes.et-lists
      input[type='checkbox']:checked
      + label,
    .l-social-first
      .form-checkboxes.et-lists
      input[type='checkbox']:checked:before {
      color: #222;
    }
    .l-social-first #bolero-user-login-submit {
      display: inline-block;
      margin: 0 10px 0 0;
    }
    .l-social-first .password-reset-link {
      padding: 0 1.125rem;
      margin-top: 1.125rem;
    }
    .l-social-first .password-reset-link .button {
      margin: 0 5px 0 0;
      padding: 5px;
      color: #888;
      border: 1px solid #eee;
      background: 0 0;
    }
    .l-social-first .form-actions {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 1.125rem 0 3rem;
      text-align: center;
    }
    .l-social-first .form-actions input[type='submit'] {
      min-width: 200px;
    }
    .user-login-forms [class*='bolero-user-'] > div,
    .user-login-forms form[class*='user-'] > div {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-direction: columns;
      flex-direction: columns;
      width: 90%;
      margin: 0 auto;
    }
    .user-login-forms [class*='bolero-user-'] > div .form-item,
    .user-login-forms form[class*='user-'] > div .form-item {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0 0.5rem;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-item-name
      label:after,
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-item-name
      + .form-item-pass
      label:after,
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-type-textfield
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-item-name
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-item-name
      + .form-item-pass
      label:after,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item.form-type-textfield
      label:after {
      position: absolute;
      top: 1px;
      right: 0;
      color: #aaa;
    }
    .user-login-forms [class*='bolero-user-'] > div .form-item label,
    .user-login-forms form[class*='user-'] > div .form-item label {
      position: relative;
    }
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item
      label:hover
      + input
      + .description,
    .user-login-forms
      form[class*='user-']
      > div
      .form-item
      label:hover
      + input
      + .description {
      z-index: 3;
      opacity: 1;
      filter: alpha(opacity=100);
    }
    .user-login-forms [class*='bolero-user-'] > div .form-item .description,
    .user-login-forms form[class*='user-'] > div .form-item .description {
      position: absolute;
      z-index: 0;
      padding: 3px 6px;
      transition: 0.2s all ease-in-out;
      color: #fff;
      background: rgba(34, 34, 34, 0.8);
      opacity: 0;
      filter: alpha(opacity=0);
    }
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-type-password,
    .user-login-forms form[class*='user-'] > div .form-item.form-type-password {
      float: left;
      width: 50%;
    }
    .user-login-forms
      [class*='bolero-user-']
      > div
      .form-item.form-type-checkbox,
    .user-login-forms form[class*='user-'] > div .form-item.form-type-checkbox {
      width: auto;
    }
    .user-login-forms .messages {
      background-image: none;
    }
    .password > h2 {
      font-size: 2.10938rem;
      margin-bottom: 0;
      padding: 1rem 2rem 0;
    }
    .password > span {
      margin-bottom: 1rem;
      padding: 0 2rem 1rem;
    }
    .reveal-modal h2.block__title {
      color: #ccc;
      text-indent: 0;
      margin-bottom: 1.125rem;
    }
    .reveal-modal.mailing-list {
      background: #f2f2f2;
      border: none;
      padding: 6vh;
      box-shadow: 0 0 0 #000;
    }
    .reveal-modal.mailing-list .block__title {
      color: #000;
    }
    ul.menu {
      margin: 0 auto;
    }
    .menu .leaf {
      list-style-type: none;
      list-style-image: none;
    }
    .follow-socials .icon {
      width: 36px;
      height: 36px;
      line-height: 34px;
      margin: 0 2px;
      padding: 0;
      text-indent: -9999em;
      white-space: nowrap;
      overflow: hidden;
      float: none;
      transition: opacity 0.2s ease-in-out;
      text-align: center;
      opacity: 0.8;
    }
    .follow-socials .icon:after,
    .follow-socials .icon:before {
      float: left;
      text-indent: 0;
    }
    .follow-socials .icon:before {
      text-indent: 0;
      margin: 0 auto;
    }
    .follow-socials .icon:hover {
      opacity: 1;
    }
    .block__title {
      font-family: 'Druk Wide Web', sans-serif;
    }
    .block__title {
      display: block;
      position: relative;
      font-size: calc(1.01852vw + 12.74px);
      font-weight: 700;
      margin: 0 auto;
      clear: both;
      text-align: center;
      width: 100%;
      max-width: 1280px;
    }
    h2,
    h4 {
      font-family: 'Druk Web', futura-pt, HelveticaNeue-Bold,
        'Helvetica Neue Bold', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      line-height: 1.2em;
      color: #000;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0;
    }
    h2:after,
    h4:after {
      content: '';
    }
    h2:after::after,
    h2:after::before,
    h4:after::after,
    h4:after::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    h2:after::after,
    h4:after::after {
      clear: both;
    }
    .page-tour:not(.page-node) .l-footer {
      background: #f2f2f2;
    }
    @media screen and (min-width: 0\\0) {
      select {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==);
      }
    }
    @media screen and (min-width: 20.0625em) {
      .l-social-first > header > div {
        padding: 2rem 2rem 10px;
      }
      .l-social-first .password-reset-link {
        display: inline-block;
        padding: 0;
      }
      .user-login-forms [class*='bolero-user-'] > div,
      .user-login-forms form[class*='user-'] > div {
        -ms-flex-direction: row;
        flex-direction: row;
      }
      .user-login-forms
        [class*='bolero-user-']
        > div
        .form-item.form-type-textfield,
      .user-login-forms
        form[class*='user-']
        > div
        .form-item.form-type-textfield {
        width: 50%;
        float: left;
      }
    }
    @media screen and (min-width: 20.0625em) and (max-width: 37.4375em) {
      a.login .label {
        font-size: 11px;
      }
    }
    @media screen and (min-width: 37.5em) {
      h2 {
        font-size: 2.8125rem;
      }
      h4 {
        font-size: 1.875rem;
      }
      .l-footer > nav .menu {
        line-height: 50px;
        height: 50px;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
      }
      form label {
        font-size: 0.7rem;
      }
      .exacttarget-webform .webform-component > label {
        display: none;
      }
      .exacttarget-webform > div > .form-item {
        display: inline-block;
        float: left;
        width: 50%;
        padding: 3px;
      }
      .exacttarget-webform > div > .form-item.webform-component--email-address {
        width: 100%;
      }
      .exacttarget-webform [type='email'],
      .exacttarget-webform [type='text'],
      .exacttarget-webform select {
        padding: 5px 10px;
      }
      .exacttarget-webform input[type='checkbox']:before {
        margin-right: 5px;
      }
      .l-social-first .ae-social-login-links {
        padding: 2.25rem 5% 0.5rem;
      }
      a.login {
        width: calc(32% - 6px);
      }
      a.login .label {
        font-size: 9px;
        letter-spacing: 0.03em;
      }
      .l-social-first > header > div h2 {
        font-size: 36px;
      }
      .l-social-first
        .form-checkboxes.et-lists
        > .form-item.form-type-checkbox {
        margin: 0 10px 0 0;
      }
    }
    @media screen and (min-width: 37.5em) and (max-width: 61.9375em) {
      .l-footer .follow-socials {
        padding: 0;
      }
    }
    @media screen and (min-width: 62em) {
      h2 {
        font-size: 3.125rem;
      }
      h4 {
        font-size: 2.10938rem;
      }
      .l-social-first > header > div span {
        font-size: 16px;
        letter-spacing: 0;
      }
    }
    @media (min-width: 1400px) {
      .form-actions > input[type='submit'],
      .form-submit {
        font-size: 16px;
      }
      .block__title {
        font-size: 27px;
      }
    }
    @media (max-width: 320px) {
      .form-actions > input[type='submit'],
      .form-submit {
        font-size: 14px;
      }
      .block__title {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 37.4375em) {
      .reveal-modal.mailing-list {
        padding: 6vh 1.125rem;
      }
    }
    @media screen and (max-width: 20em) {
      .user-login-forms
        [class*='bolero-user-']
        > div
        .form-item.form-type-password.form-item-pass,
      .user-login-forms
        form[class*='user-']
        > div
        .form-item.form-type-password.form-item-pass {
        width: 100%;
      }
    }
    @media print {
      img {
        page-break-inside: avoid;
      }
      a[href^='#']:after {
        content: '';
      }
      * {
        background: 0 0 !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
      }
      a,
      a:visited {
        text-decoration: underline;
      }
      a[href]:after {
        content: ' (' attr(href) ')';
      }
      a[href^='#']:after {
        content: '';
      }
      img {
        max-width: 100% !important;
      }
      h2,
      p {
        orphans: 3;
        widows: 3;
      }
      h2 {
        page-break-after: avoid;
      }
    }
  }
  /*! CSS Used from: https://www.theweeknd.com/sites/g/files/aaj15616/f/css/css_gS35J00Qc2bUEcccBPWfr0Dw2EQCmDfNQM5TfqjXmDo.css ; media=all */
  @media all {
    .umg-disclaimer.et-umg-disclaimer {
      width: 100%;
    }
    .url-textfield {
      display: none !important;
    }
    .reveal-modal {
      border-radius: 3px;
      display: none;
      position: absolute;
      top: 0;
      visibility: hidden;
      width: 100%;
      z-index: 1005;
      left: 0;
      background-color: #fff;
      padding: 1.875rem;
      border: solid 1px #666;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
    @media only screen and (max-width: 40em) {
      .reveal-modal {
        min-height: 100vh;
      }
    }
    .reveal-modal > :first-child {
      margin-top: 0;
    }
    .reveal-modal > :last-child {
      margin-bottom: 0;
    }
    @media only screen and (min-width: 40.0625em) {
      .reveal-modal {
        left: 0;
        margin: 0 auto;
        max-width: 62.5rem;
        right: 0;
        width: 80%;
      }
    }
    @media only screen and (min-width: 40.0625em) {
      .reveal-modal {
        top: 6.25rem;
      }
    }
    .reveal-modal .close-reveal-modal {
      color: #aaa;
      cursor: pointer;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1;
      position: absolute;
      top: 0.625rem;
      right: 1.375rem;
    }
    .block.delta--user-login-modal.state--login .user-login-forms .register,
    .block.delta--user-login-modal.state--login .user-login-forms .password,
    #block-bolero-user-login-user-login-modal.state--login
      .user-login-forms
      .register,
    #block-bolero-user-login-user-login-modal.state--login
      .user-login-forms
      .password {
      display: none;
    }
    .block.delta--user-login-modal.state--login .user-login-forms .login,
    .block.delta--user-login-modal.state--login .ae-social-login-links,
    .block.delta--user-login-modal.state--login header,
    #block-bolero-user-login-user-login-modal.state--login
      .user-login-forms
      .login,
    #block-bolero-user-login-user-login-modal.state--login
      .ae-social-login-links,
    #block-bolero-user-login-user-login-modal.state--login header {
      display: block;
    }
  }
  /*! CSS Used from: Embedded */
  .l-footer nav a span.evidon-consent-link-text {
    margin-left: 0;
    color: #212121;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  .evidon-notice-link {
    text-indent: -9999em;
  }
  .evidon-consent-link {
    text-indent: 0;
  }
  .evidon-notice-link a:first-child,
  .evidon-consent-link img {
    display: none;
  }
  /*! CSS Used fontfaces */
  @font-face {
    font-family: fontello;
    src: url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.eot?21517319);
    src: url(https://cache.umusic.com/_sites/_asf/icons/fontello.eot?21517319#iefix)
        format('embedded-opentype'),
      url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.woff?21517319)
        format('woff'),
      url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.ttf?21517319)
        format('truetype'),
      url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.svg?21517319#fontello)
        format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Druk Wide Web';
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.eot);
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.eot?#iefix)
        format('embedded-opentype'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.woff2)
        format('woff2'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.woff)
        format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: 'Druk Web';
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.eot);
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.eot?#iefix)
        format('embedded-opentype'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.woff2)
        format('woff2'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.woff)
        format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
  }
`;
