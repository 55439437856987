import * as S from './styles';

const Footer = () => {
  return (
    <S.Footer>
      <footer className='l-footer' role='contentinfo'>
        <div className='l-region l-region--footer'>
          <div
            id='block-bolero-user-login-user-login-modal'
            data-reveal
            aria-hidden='true'
            role='dialog'
            style={{ display: 'none' }}
            className='block block--bolero-user-login block--bolero-user-login-user-login-modal l-social-first reveal-modal block--bolero-emcee-ext-user-login-modal block--bolero-user-login-user-login-modal delta--user-login-modal state--login'>
            <a className='close-reveal-modal' aria-label='Close'>
              ×
            </a>
            <header>
              <div className='login active bolero-emcee-login-processed'>
                <h2>Login</h2>
                <span> with your existing account</span>
              </div>
              <div className='register bolero-emcee-login-processed'>
                <h2>Register</h2>
                <span> to create a new account</span>
              </div>
            </header>
            <section className='ae-social-login-links'>
              <a
                href='#'
                data-ae-register-form-link='facebook'
                className='ae-link login facebook'>
                <i className='icon icon-facebook' />
                <span className='label'>
                  <span className='auth-action'>Login</span>
                  <span className='with'> with </span>
                  <span className='social'>Facebook</span>
                </span>
              </a>
              <a
                href='#'
                data-ae-register-form-link='twitter'
                className='ae-link login twitter'>
                <i className='icon icon-twitter' />
                <span className='label'>
                  <span className='auth-action'>Login</span>
                  <span className='with'> with </span>
                  <span className='social'>Twitter</span>
                </span>
              </a>
              <a
                href='#'
                data-ae-register-form-link='google'
                className='ae-link login google'>
                <i className='icon icon-google' />
                <span className='label'>
                  <span className='auth-action'>Login</span>
                  <span className='with'> with </span>
                  <span className='social'>Google</span>
                </span>
              </a>
            </section>
            <div className='social-separator'>
              <span />
              <p>or</p>
              <span />
            </div>
            <section className='user-login-forms'>
              <div
                className='login bolero-ajax-form-wrapper'
                data-bolero-form-id='bolero_user_login'>
                <form
                  className='bolero-user-login'
                  data-ae-type='login'
                  action='/tour'
                  method='post'
                  id='bolero-user-login'
                  acceptCharset='UTF-8'
                  data-ae-register-form='email'>
                  <div>
                    <div className='form-item form-type-textfield form-item-name'>
                      <label htmlFor='edit-name'>
                        E-mail Address{' '}
                        <span
                          className='form-required'
                          title='This field is required.'>
                          *
                        </span>
                      </label>
                      <input
                        type='text'
                        id='edit-name'
                        name='name'
                        defaultValue
                        size={60}
                        maxLength={60}
                        className='form-text required'
                      />
                      <div className='description'>
                        Enter your The Weeknd account e-mail address.
                      </div>
                    </div>
                    <div className='form-item form-type-password form-item-pass'>
                      <label htmlFor='edit-pass'>
                        Password{' '}
                        <span
                          className='form-required'
                          title='This field is required.'>
                          *
                        </span>
                      </label>
                      <input
                        type='password'
                        id='edit-pass'
                        name='pass'
                        size={60}
                        maxLength={128}
                        className='form-text required'
                      />
                      <div className='description'>
                        Enter the password that accompanies your username.
                      </div>
                    </div>
                    <input type='hidden' name='ae_user' />
                    <input
                      type='hidden'
                      name='form_build_id'
                      defaultValue='form-ty47Z0lrkQqQUDZp5sWiQMVpLw-qY9eLV4CN28GmCZA'
                    />
                    <input
                      type='hidden'
                      name='form_id'
                      defaultValue='bolero_user_login'
                    />
                    <div className='form-item form-type-checkboxes form-item-lists'>
                      <label htmlFor='edit-lists--2'>
                        Subscribe to The Weeknd Mailing Lists{' '}
                      </label>
                      <div
                        id='edit-lists--2'
                        className='form-checkboxes subscription-lists dm-lists et-lists'>
                        <div className='form-item form-type-checkbox form-item-lists-93839'>
                          <input
                            className='subscription-lists dm-lists et-lists form-checkbox bul-processed'
                            type='checkbox'
                            id='edit-lists-93839'
                            name='lists[93839]'
                            defaultValue={93839}
                          />{' '}
                          <label className='option' htmlFor='edit-lists-93839'>
                            Republic Records{' '}
                          </label>
                        </div>
                        <div className='form-item form-type-checkbox form-item-lists-94739'>
                          <input
                            className='subscription-lists dm-lists et-lists form-checkbox bul-processed'
                            type='checkbox'
                            id='edit-lists-94739'
                            name='lists[94739]'
                            defaultValue={94739}
                          />{' '}
                          <label className='option' htmlFor='edit-lists-94739'>
                            Universal Music Group{' '}
                          </label>
                        </div>
                        <div className='form-item form-type-checkbox form-item-lists-25849967'>
                          <input
                            className='subscription-lists dm-lists et-lists form-checkbox bul-processed'
                            type='checkbox'
                            id='edit-lists-25849967'
                            name='lists[25849967]'
                            defaultValue={25849967}
                          />{' '}
                          <label
                            className='option'
                            htmlFor='edit-lists-25849967'>
                            The Weeknd{' '}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='umg-disclaimer et-umg-disclaimer'>
                      Emails will be sent by or on behalf of Universal Music
                      Group 2220 Colorado Avenue, Santa Monica, CA 90404 (310)
                      865-4000. You may withdraw your consent at any time.{' '}
                      <a
                        href='https://privacypolicy.umusic.com/'
                        target='_blank'>
                        Privacy Policy
                      </a>{' '}
                      /{' '}
                      <a
                        href='https://www.universalmusic.com/CCPA/'
                        target='_blank'>
                        Do Not Sell My Personal Information
                      </a>
                    </div>
                    <div
                      className='form-actions form-wrapper'
                      id='edit-actions--2'>
                      <input
                        type='submit'
                        id='bolero-user-login-submit'
                        name='op'
                        defaultValue='Log in'
                        className='form-submit ajax-processed bolero-form-processed'
                      />
                      <div className='password-reset-link'>
                        <a
                          href='/user/password'
                          className='button bolero-emcee-login-processed'
                          title='Reset your password.'>
                          Reset
                        </a>
                        <span> your password if you're unable to login.</span>
                      </div>
                      <div className='messages' style={{ display: 'none' }} />
                    </div>
                    <div className='url-textfield'>
                      <div className='form-item form-type-textfield form-item-url'>
                        <label htmlFor='edit-url--2'>
                          Leave this field blank{' '}
                        </label>
                        <input
                          autoComplete='off'
                          type='text'
                          id='edit-url--2'
                          name='url'
                          defaultValue
                          size={20}
                          maxLength={128}
                          className='form-text'
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className='register bolero-ajax-form-wrapper'
                data-bolero-form-id='bolero_user_register'
              />
              <div className='password'>
                <h2>Reset Your Password</h2>
                <span> if you're unable to login</span>
                <div
                  className='bolero-ajax-form-wrapper'
                  data-bolero-form-id='bolero_user_password'
                />
              </div>
            </section>
          </div>
          <div
            id='block-block-121'
            className='block block--block block--block-121 delta--121'>
            <div className='block__content'>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '\n  .l-footer nav a span.evidon-consent-link-text {\n  margin-left: 0;\n  color: #212121;\n  font-size: 9px;\n  text-transform: uppercase;\n  letter-spacing: .1em;\n}\n.evidon-notice-link {\n  text-indent: -9999em;\n}\n.evidon-consent-link {\n  text-indent: 0;\n}\n.evidon-notice-link a:first-child,\n.evidon-consent-link img {\n  display: none;\n}\n',
                }}
              />
            </div>
          </div>
          <nav
            id='block-menu-block-iga-common-socials-footer'
            role='navigation'
            className='block block--menu-block block--menu-block-iga-common-socials-footer delta--iga-common-socials-footer'>
            <h2 className='block__title'>Social Links</h2>
            <div className='follow-socials menu-block-wrapper menu-block-iga_common_socials_footer menu-name-socials-menu parent-mlid-0 menu-level-1'>
              <ul className='menu'>
                <li className='first leaf menu-mlid-33166'>
                  <a
                    href='http://www.facebook.com/theweeknd'
                    className='icon icon-facebook follow-facebook'
                    target='_blank'>
                    facebook
                  </a>
                </li>
                <li className='leaf menu-mlid-49696'>
                  <a
                    href='http://instagram.com/theweeknd'
                    className='icon icon-instagram follow-instagram'
                    target='_blank'>
                    instagram
                  </a>
                </li>
                <li className='leaf menu-mlid-33186'>
                  <a
                    href='https://soundcloud.com/theweeknd'
                    className='icon icon-soundcloud follow-soundcloud'
                    target='_blank'>
                    soundcloud
                  </a>
                </li>
                <li className='leaf menu-mlid-152631'>
                  <a
                    href='https://itunes.apple.com/us/artist/the-weeknd/479756766'
                    className='icon icon-apple follow-apple'
                    target='_blank'>
                    Apple Music
                  </a>
                </li>
                <li className='leaf menu-mlid-33191'>
                  <a
                    href='https://open.spotify.com/artist/1Xyo4u8uXC1ZmMpatF05PJ'
                    className='icon icon-spotify follow-spotify'
                    target='_blank'>
                    spotify
                  </a>
                </li>
                <li className='leaf menu-mlid-33171'>
                  <a
                    href='https://twitter.com/TheWeeknd'
                    className='icon icon-twitter follow-twitter'
                    target='_blank'>
                    twitter
                  </a>
                </li>
                <li className='last leaf menu-mlid-33181'>
                  <a
                    href='https://www.youtube.com/user/TheWeekndVEVO'
                    className='icon icon-youtube follow-youtube'
                    target='_blank'>
                    youtube_vevo
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div
            data-reveal
            role='dialog'
            aria-hidden='true'
            id='mailing-list'
            className='block block--dotmailer-rest-webform hidden-toggle reveal-modal mailing-list delta--dm-mailing-list'>
            <h2 className='block__title'>Get Updates</h2>
            <div className='block__content'>
              <a className='close-reveal-modal' aria-label='Close'>
                ×
              </a>
              <div id='webform-client-form-wrapper-21'>
                <form
                  className='webform-client-form webform-client-form-21 dotmailer-webform exacttarget-webform webform-subscription-lists iga-common-webform webform-client-form-21 webform-subscription-lists-processed bolero-analytics-processed iga-common-webform-processed'
                  data-nid={21}
                  action='/tour'
                  method='post'
                  id='webform-client-form-21'
                  acceptCharset='UTF-8'>
                  <div>
                    <div className='form-item webform-component webform-component-email webform-component--email-address'>
                      <label htmlFor='edit-submitted-email-address'>
                        Email Address{' '}
                        <span
                          className='form-required'
                          title='This field is required.'>
                          *
                        </span>
                      </label>
                      <input
                        required='required'
                        className='email form-text form-email required'
                        placeholder='Email Address (Required)'
                        autoComplete='email'
                        type='email'
                        id='edit-submitted-email-address'
                        name='submitted[email_address]'
                        size={60}
                      />
                    </div>
                    <div className='form-item webform-component webform-component-textfield webform-component--zip-code'>
                      <label htmlFor='edit-submitted-zip-code'>
                        Postal Code{' '}
                        <span
                          className='form-required'
                          title='This field is required.'>
                          *
                        </span>
                      </label>
                      <input
                        required='required'
                        placeholder='Postal Code (Required)'
                        autoComplete='postal-code'
                        type='text'
                        id='edit-submitted-zip-code'
                        name='submitted[zip_code]'
                        defaultValue
                        size={60}
                        maxLength={128}
                        className='form-text required'
                      />
                    </div>
                    <div className='form-item webform-component webform-component-select webform-component--country'>
                      <label
                        className='element-invisible'
                        htmlFor='edit-submitted-country'>
                        Country{' '}
                        <span
                          className='form-required'
                          title='This field is required.'>
                          *
                        </span>
                      </label>
                      <select
                        required='required'
                        placeholder='Country (Required)'
                        autoComplete='country'
                        className='chosen-disable form-select required'
                        id='edit-submitted-country'
                        name='submitted[country]'>
                        <option value='AF'>Afghanistan</option>
                        <option value='AX'>Aland Islands</option>
                        <option value='AL'>Albania</option>
                        <option value='DZ'>Algeria</option>
                        <option value='AS'>American Samoa</option>
                        <option value='AD'>Andorra</option>
                        <option value='AO'>Angola</option>
                        <option value='AI'>Anguilla</option>
                        <option value='AQ'>Antarctica</option>
                        <option value='AG'>Antigua and Barbuda</option>
                        <option value='AR'>Argentina</option>
                        <option value='AM'>Armenia</option>
                        <option value='AW'>Aruba</option>
                        <option value='AU'>Australia</option>
                        <option value='AT'>Austria</option>
                        <option value='AZ'>Azerbaijan</option>
                        <option value='BS'>Bahamas</option>
                        <option value='BH'>Bahrain</option>
                        <option value='BD'>Bangladesh</option>
                        <option value='BB'>Barbados</option>
                        <option value='BY'>Belarus</option>
                        <option value='BE'>Belgium</option>
                        <option value='BZ'>Belize</option>
                        <option value='BJ'>Benin</option>
                        <option value='BM'>Bermuda</option>
                        <option value='BT'>Bhutan</option>
                        <option value='BO'>Bolivia</option>
                        <option value='BA'>Bosnia and Herzegovina</option>
                        <option value='BW'>Botswana</option>
                        <option value='BV'>Bouvet Island</option>
                        <option value='BR'>Brazil</option>
                        <option value='IO'>
                          British Indian Ocean Territory
                        </option>
                        <option value='VG'>British Virgin Islands</option>
                        <option value='BN'>Brunei</option>
                        <option value='BG'>Bulgaria</option>
                        <option value='BF'>Burkina Faso</option>
                        <option value='BI'>Burundi</option>
                        <option value='KH'>Cambodia</option>
                        <option value='CM'>Cameroon</option>
                        <option value='CA'>Canada</option>
                        <option value='CV'>Cape Verde</option>
                        <option value='BQ'>Caribbean Netherlands</option>
                        <option value='KY'>Cayman Islands</option>
                        <option value='CF'>Central African Republic</option>
                        <option value='TD'>Chad</option>
                        <option value='CL'>Chile</option>
                        <option value='CN'>China</option>
                        <option value='CX'>Christmas Island</option>
                        <option value='CC'>Cocos (Keeling) Islands</option>
                        <option value='CO'>Colombia</option>
                        <option value='KM'>Comoros</option>
                        <option value='CG'>Congo (Brazzaville)</option>
                        <option value='CD'>Congo (Kinshasa)</option>
                        <option value='CK'>Cook Islands</option>
                        <option value='CR'>Costa Rica</option>
                        <option value='HR'>Croatia</option>
                        <option value='CU'>Cuba</option>
                        <option value='CW'>Curaçao</option>
                        <option value='CY'>Cyprus</option>
                        <option value='CZ'>Czech Republic</option>
                        <option value='DK'>Denmark</option>
                        <option value='DJ'>Djibouti</option>
                        <option value='DM'>Dominica</option>
                        <option value='DO'>Dominican Republic</option>
                        <option value='EC'>Ecuador</option>
                        <option value='EG'>Egypt</option>
                        <option value='SV'>El Salvador</option>
                        <option value='GQ'>Equatorial Guinea</option>
                        <option value='ER'>Eritrea</option>
                        <option value='EE'>Estonia</option>
                        <option value='ET'>Ethiopia</option>
                        <option value='FK'>Falkland Islands</option>
                        <option value='FO'>Faroe Islands</option>
                        <option value='FJ'>Fiji</option>
                        <option value='FI'>Finland</option>
                        <option value='FR'>France</option>
                        <option value='GF'>French Guiana</option>
                        <option value='PF'>French Polynesia</option>
                        <option value='TF'>French Southern Territories</option>
                        <option value='GA'>Gabon</option>
                        <option value='GM'>Gambia</option>
                        <option value='GE'>Georgia</option>
                        <option value='DE'>Germany</option>
                        <option value='GH'>Ghana</option>
                        <option value='GI'>Gibraltar</option>
                        <option value='GR'>Greece</option>
                        <option value='GL'>Greenland</option>
                        <option value='GD'>Grenada</option>
                        <option value='GP'>Guadeloupe</option>
                        <option value='GU'>Guam</option>
                        <option value='GT'>Guatemala</option>
                        <option value='GG'>Guernsey</option>
                        <option value='GN'>Guinea</option>
                        <option value='GW'>Guinea-Bissau</option>
                        <option value='GY'>Guyana</option>
                        <option value='HT'>Haiti</option>
                        <option value='HM'>
                          Heard Island and McDonald Islands
                        </option>
                        <option value='HN'>Honduras</option>
                        <option value='HK'>Hong Kong S.A.R., China</option>
                        <option value='HU'>Hungary</option>
                        <option value='IS'>Iceland</option>
                        <option value='IN'>India</option>
                        <option value='ID'>Indonesia</option>
                        <option value='IR'>Iran</option>
                        <option value='IQ'>Iraq</option>
                        <option value='IE'>Ireland</option>
                        <option value='IM'>Isle of Man</option>
                        <option value='IL'>Israel</option>
                        <option value='IT'>Italy</option>
                        <option value='CI'>Ivory Coast</option>
                        <option value='JM'>Jamaica</option>
                        <option value='JP'>Japan</option>
                        <option value='JE'>Jersey</option>
                        <option value='JO'>Jordan</option>
                        <option value='KZ'>Kazakhstan</option>
                        <option value='KE'>Kenya</option>
                        <option value='KI'>Kiribati</option>
                        <option value='KW'>Kuwait</option>
                        <option value='KG'>Kyrgyzstan</option>
                        <option value='LA'>Laos</option>
                        <option value='LV'>Latvia</option>
                        <option value='LB'>Lebanon</option>
                        <option value='LS'>Lesotho</option>
                        <option value='LR'>Liberia</option>
                        <option value='LY'>Libya</option>
                        <option value='LI'>Liechtenstein</option>
                        <option value='LT'>Lithuania</option>
                        <option value='LU'>Luxembourg</option>
                        <option value='MO'>Macao S.A.R., China</option>
                        <option value='MK'>Macedonia</option>
                        <option value='MG'>Madagascar</option>
                        <option value='MW'>Malawi</option>
                        <option value='MY'>Malaysia</option>
                        <option value='MV'>Maldives</option>
                        <option value='ML'>Mali</option>
                        <option value='MT'>Malta</option>
                        <option value='MH'>Marshall Islands</option>
                        <option value='MQ'>Martinique</option>
                        <option value='MR'>Mauritania</option>
                        <option value='MU'>Mauritius</option>
                        <option value='YT'>Mayotte</option>
                        <option value='MX'>Mexico</option>
                        <option value='FM'>Micronesia</option>
                        <option value='MD'>Moldova</option>
                        <option value='MC'>Monaco</option>
                        <option value='MN'>Mongolia</option>
                        <option value='ME'>Montenegro</option>
                        <option value='MS'>Montserrat</option>
                        <option value='MA'>Morocco</option>
                        <option value='MZ'>Mozambique</option>
                        <option value='MM'>Myanmar</option>
                        <option value='NA'>Namibia</option>
                        <option value='NR'>Nauru</option>
                        <option value='NP'>Nepal</option>
                        <option value='NL'>Netherlands</option>
                        <option value='AN'>Netherlands Antilles</option>
                        <option value='NC'>New Caledonia</option>
                        <option value='NZ'>New Zealand</option>
                        <option value='NI'>Nicaragua</option>
                        <option value='NE'>Niger</option>
                        <option value='NG'>Nigeria</option>
                        <option value='NU'>Niue</option>
                        <option value='NF'>Norfolk Island</option>
                        <option value='MP'>Northern Mariana Islands</option>
                        <option value='KP'>North Korea</option>
                        <option value='NO'>Norway</option>
                        <option value='OM'>Oman</option>
                        <option value='PK'>Pakistan</option>
                        <option value='PW'>Palau</option>
                        <option value='PS'>Palestinian Territory</option>
                        <option value='PA'>Panama</option>
                        <option value='PG'>Papua New Guinea</option>
                        <option value='PY'>Paraguay</option>
                        <option value='PE'>Peru</option>
                        <option value='PH'>Philippines</option>
                        <option value='PN'>Pitcairn</option>
                        <option value='PL'>Poland</option>
                        <option value='PT'>Portugal</option>
                        <option value='PR'>Puerto Rico</option>
                        <option value='QA'>Qatar</option>
                        <option value='RE'>Reunion</option>
                        <option value='RO'>Romania</option>
                        <option value='RU'>Russia</option>
                        <option value='RW'>Rwanda</option>
                        <option value='BL'>Saint Barthélemy</option>
                        <option value='SH'>Saint Helena</option>
                        <option value='KN'>Saint Kitts and Nevis</option>
                        <option value='LC'>Saint Lucia</option>
                        <option value='MF'>Saint Martin (French part)</option>
                        <option value='PM'>Saint Pierre and Miquelon</option>
                        <option value='VC'>
                          Saint Vincent and the Grenadines
                        </option>
                        <option value='WS'>Samoa</option>
                        <option value='SM'>San Marino</option>
                        <option value='ST'>Sao Tome and Principe</option>
                        <option value='SA'>Saudi Arabia</option>
                        <option value='SN'>Senegal</option>
                        <option value='RS'>Serbia</option>
                        <option value='SC'>Seychelles</option>
                        <option value='SL'>Sierra Leone</option>
                        <option value='SG'>Singapore</option>
                        <option value='SX'>Sint Maarten</option>
                        <option value='SK'>Slovakia</option>
                        <option value='SI'>Slovenia</option>
                        <option value='SB'>Solomon Islands</option>
                        <option value='SO'>Somalia</option>
                        <option value='ZA'>South Africa</option>
                        <option value='GS'>
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value='KR'>South Korea</option>
                        <option value='SS'>South Sudan</option>
                        <option value='ES'>Spain</option>
                        <option value='LK'>Sri Lanka</option>
                        <option value='SD'>Sudan</option>
                        <option value='SR'>Suriname</option>
                        <option value='SJ'>Svalbard and Jan Mayen</option>
                        <option value='SZ'>Swaziland</option>
                        <option value='SE'>Sweden</option>
                        <option value='CH'>Switzerland</option>
                        <option value='SY'>Syria</option>
                        <option value='TW'>Taiwan</option>
                        <option value='TJ'>Tajikistan</option>
                        <option value='TZ'>Tanzania</option>
                        <option value='TH'>Thailand</option>
                        <option value='TL'>Timor-Leste</option>
                        <option value='TG'>Togo</option>
                        <option value='TK'>Tokelau</option>
                        <option value='TO'>Tonga</option>
                        <option value='TT'>Trinidad and Tobago</option>
                        <option value='TN'>Tunisia</option>
                        <option value='TR'>Turkey</option>
                        <option value='TM'>Turkmenistan</option>
                        <option value='TC'>Turks and Caicos Islands</option>
                        <option value='TV'>Tuvalu</option>
                        <option value='VI'>U.S. Virgin Islands</option>
                        <option value='UG'>Uganda</option>
                        <option value='UA'>Ukraine</option>
                        <option value='AE'>United Arab Emirates</option>
                        <option value='GB'>United Kingdom</option>
                        <option value='US' selected='selected'>
                          United States
                        </option>
                        <option value='UM'>
                          United States Minor Outlying Islands
                        </option>
                        <option value='UY'>Uruguay</option>
                        <option value='UZ'>Uzbekistan</option>
                        <option value='VU'>Vanuatu</option>
                        <option value='VA'>Vatican</option>
                        <option value='VE'>Venezuela</option>
                        <option value='VN'>Vietnam</option>
                        <option value='WF'>Wallis and Futuna</option>
                        <option value='EH'>Western Sahara</option>
                        <option value='YE'>Yemen</option>
                        <option value='ZM'>Zambia</option>
                        <option value='ZW'>Zimbabwe</option>
                      </select>
                    </div>
                    <input type='hidden' name='details[sid]' />
                    <input
                      type='hidden'
                      name='details[page_num]'
                      defaultValue={1}
                    />
                    <input
                      type='hidden'
                      name='details[page_count]'
                      defaultValue={1}
                    />
                    <input
                      type='hidden'
                      name='details[finished]'
                      defaultValue={0}
                    />
                    <input
                      type='hidden'
                      name='form_build_id'
                      defaultValue='form-9MbUViH0Bmn9NNADZHLB3rvwRClCDpbYeZMtVwyLP1k'
                    />
                    <input
                      type='hidden'
                      name='form_id'
                      defaultValue='webform_client_form_21'
                    />
                    <fieldset
                      className='dotmailer-webform-lists subscription-lists form-wrapper'
                      id='edit-lists'>
                      <div className='fieldset-wrapper'>
                        <div className='fieldset-description'>
                          <h4>Subscribe to Email Updates From:</h4>
                        </div>
                        <div className='form-item form-type-checkbox form-item-25849967'>
                          <input
                            name={25849967}
                            className='dm-list-25849967 dm-list subscription-list form-checkbox'
                            data-list-id={25849967}
                            data-account-id={237881}
                            data-list-name='The Weeknd'
                            type='checkbox'
                            id='edit-25849967'
                            defaultValue={1}
                            defaultChecked='checked'
                          />{' '}
                          <label className='option' htmlFor='edit-25849967'>
                            The Weeknd{' '}
                          </label>
                        </div>
                        <div className='form-item form-type-checkbox form-item-93839'>
                          <input
                            name={93839}
                            className='dm-list-93839 dm-list subscription-list form-checkbox'
                            data-list-id={93839}
                            data-account-id={231800}
                            data-list-name='Republic Records'
                            type='checkbox'
                            id='edit-93839'
                            defaultValue={1}
                            defaultChecked='checked'
                          />{' '}
                          <label className='option' htmlFor='edit-93839'>
                            Republic Records{' '}
                          </label>
                        </div>
                        <div className='form-item form-type-checkbox form-item-94739'>
                          <input
                            name={94739}
                            className='dm-list-94739 dm-list subscription-list form-checkbox'
                            data-list-id={94739}
                            data-account-id={231777}
                            data-list-name='Universal Music Group'
                            type='checkbox'
                            id='edit-94739'
                            defaultValue={1}
                            defaultChecked='checked'
                          />{' '}
                          <label className='option' htmlFor='edit-94739'>
                            Universal Music Group{' '}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <div className='umg-disclaimer et-umg-disclaimer'>
                      Emails will be sent by or on behalf of Universal Music
                      Group 2220 Colorado Avenue, Santa Monica, CA 90404 (310)
                      865-4000. You may withdraw your consent at any time.{' '}
                      <a
                        href='https://privacypolicy.umusic.com/'
                        target='_blank'>
                        Privacy Policy
                      </a>{' '}
                      /{' '}
                      <a
                        href='https://www.universalmusic.com/CCPA/'
                        target='_blank'>
                        Do Not Sell My Personal Information
                      </a>
                    </div>
                    <div className='url-textfield'>
                      <div className='form-item form-type-textfield form-item-url'>
                        <label htmlFor='edit-url'>
                          Leave this field blank{' '}
                        </label>
                        <input
                          autoComplete='off'
                          type='text'
                          id='edit-url'
                          name='url'
                          defaultValue
                          size={20}
                          maxLength={128}
                          className='form-text'
                        />
                      </div>
                    </div>
                    <div className='form-actions'>
                      <input
                        className='webform-submit button-primary form-submit'
                        type='submit'
                        id='edit-submit'
                        name='op'
                        defaultValue='Subscribe'
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className='l-region l-region--footer-nav'>
            <nav
              id='block-menu-menu-legal-links-non-modal'
              role='navigation'
              className='block block--menu block--menu-menu-legal-links-non-modal delta--menu-legal-links---non-modal'>
              <h2 className='block__title'>Legal Links - Non Modal</h2>
              <ul className='menu'>
                <li className='first leaf'>
                  <a href='http://www.republicrecords.com/' target='_blank'>
                    Copyright Republic Records
                  </a>
                </li>
                <li className='leaf'>
                  <a href='https://privacypolicy.umusic.com/' target='_blank'>
                    Privacy Policy
                  </a>
                </li>
                <li className='leaf'>
                  <a
                    href='https://privacypolicy.umusic.com/terms/'
                    target='_blank'>
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className='leaf evidon-notice-link'>
                  <a href='/'>cookie consent</a>
                  <a
                    href='#'
                    className='evidon-consent-link'
                    tabIndex={0}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontSize: '11pt',
                      color: 'rgb(0, 0, 0)',
                    }}>
                    <img
                      src='https://c.evidon.com/pub/icong1.png'
                      className='evidon-consent-link-image'
                      alt='AdChoices Icon'
                      style={{ verticalAlign: 'bottom' }}
                    />
                    <span
                      id='_evidon-link-text'
                      className='evidon-consent-link-text'
                      style={{ marginLeft: '6px' }}>
                      Cookie Consent
                    </span>
                  </a>
                </li>
                <li className='last leaf'>
                  <a
                    href='https://privacy.universalmusic.com/CCPA'
                    target='_blank'>
                    Do Not Sell My Personal Information
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </nav>
      </footer>
    </S.Footer>
  );
};

export default Footer;
