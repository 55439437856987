import styled from 'styled-components';

export const TicketsList = styled.div`
  width: calc(100% - 320px);
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 24px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
  .genres {
    font-weight: 700;
    margin-top: 32px;
    span:first-child {
      margin-left: 10px;
      color: #e21937;
      font-style: italic;
    }
    span {
      color: #e21937;
      font-style: italic;
      font-weight: 400;
    }
    i {
      font-weight: 400;
    }
  }
  h1 {
    color: #212121;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    margin-bottom: 20px;
  }
  .international {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    p {
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
      padding: 12px 16px;
      min-height: 48px;
      max-width: 360px;
      min-width: 90px;
      line-height: 1.25;
      color: #222222 !important;
      opacity: 1;
      border-bottom: 4px solid rgb(226, 25, 55);
      width: fit-content;
      span {
        font-weight: 400;
      }
    }
  }
  .iframe {
    width: 100%;
    height: 500px;
    margin-top: 64px;
    @media (max-width: 768px) {
      height: 300px;
    }
  }
  .filter_by {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    p {
      color: #9e9e9e;
      margin: 0px;
      font-family: Montserrat, Arial, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.43;
      margin-right: 16px;
    }
  }
`;

export const ItemsList = styled.div`
  width: 100%;
  flex-direction: column;
`;
