export const norwaytickets = [
  {
    zone: 'Front Right',
    row: '',
    tickets_left: 11,
    price: 99.98,
  },
  {
    zone: 'Front Left',
    row: '',
    tickets_left: 5,
    price: 99.98,
  },
  {
    zone: 'Back',
    row: '',
    tickets_left: 11,
    price: 89.98,
  },
  {
    zone: 'J',
    row: 14,
    tickets_left: 5,
    price: 98.99,
  },
  {
    zone: 'K',
    row: 5,
    tickets_left: 4,
    price: 98.98,
  },
  {
    zone: 'N',
    row: 3,
    tickets_left: 8,
    price: 119.98,
  },
  {
    zone: 'G',
    row: 7,
    tickets_left: 4,
    price: 109.98,
  },
  {
    zone: 'H',
    row: 14,
    tickets_left: 2,
    price: 98.98,
  },
  {
    zone: 'M',
    row: 9,
    tickets_left: 5,
    price: 109.98,
  },
  {
    zone: 'L',
    row: 4,
    tickets_left: 7,
    price: 98.98,
  },
  {
    zone: 'I',
    row: 2,
    tickets_left: 7,
    price: 119.98,
  },
  {
    zone: 'F',
    row: 6,
    tickets_left: 4,
    price: 119.98,
  },
];
