export const ticketsUk = [
  {
    zone: 'Front Right',
    row: '',
    tickets_left: 11,
    price: 89.98,
  },
  {
    zone: 'Front Left',
    row: '',
    tickets_left: 3,
    price: 99.98,
  },
  {
    zone: 'Back',
    row: '',
    tickets_left: 11,
    price: 89.98,
  },
  {
    zone: 139,
    row: 14,
    tickets_left: 5,
    price: 101.99,
  },
  {
    zone: 141,
    row: 5,
    tickets_left: 1,
    price: 98,
  },
  {
    zone: 147,
    row: 1,
    tickets_left: 4,
    price: 101.99,
  },
  {
    zone: 148,
    row: 1,
    tickets_left: 11,
    price: 79,
  },
  {
    zone: 151,
    row: 24,
    tickets_left: 2,
    price: 109.99,
  },
  {
    zone: 154,
    row: 13,
    tickets_left: 16,
    price: 99.99,
  },
  {
    zone: 155,
    row: 4,
    tickets_left: 4,
    price: 109,
  },
  {
    zone: 104,
    row: 19,
    tickets_left: 10,
    price: 109,
  },
  {
    zone: 255,
    row: 24,
    tickets_left: 23,
    price: 137.99,
  },
  {
    zone: 254,
    row: 11,
    tickets_left: 6,
    price: 89.99,
  },
  {
    zone: 253,
    row: 3,
    tickets_left: 1,
    price: 89.99,
  },
  {
    zone: 248,
    row: 8,
    tickets_left: 2,
    price: 109.99,
  },
  {
    zone: 247,
    row: '',
    tickets_left: 1,
    price: 129.99,
  },
  {
    zone: 152,
    row: '',
    tickets_left: 1,
    price: 105.99,
  },
  {
    zone: 153,
    row: '',
    tickets_left: 1,
    price: 205.99,
  },
  {
    zone: 246,
    row: '',
    tickets_left: 1,
    price: 79.99,
  },
  {
    zone: 244,
    row: 4,
    tickets_left: 5,
    price: 79.99,
  },
  {
    zone: 242,
    row: '',
    tickets_left: 2,
    price: 89.99,
  },
];
