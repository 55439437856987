import styled from 'styled-components';

export const TopBanner = styled.div`
  /*! CSS Used from: Embedded */
  .css-1qibcwi {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .css-133kpae {
    width: 100%;
    max-width: 1256px;
    height: auto;
    max-height: 500px;
    margin: 0px auto;
    position: relative;
  }
  .css-133kpae::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        216deg,
        rgba(0, 0, 0, 0) 52%,
        rgba(0, 0, 0, 0.5)
      ),
      linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }
  .css-1pmhwip {
    position: relative;
    display: block;
    padding-bottom: 56.25%;
    background-image: url(https://dynamicmedia.livenationinternational.com/d/x/x/527f6551-d55c-4c97-be2b-90a38c88057e.jpg?auto=webp&blur=5&width=1);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    transition: all 0.5s ease 0s;
  }
  .css-jhufcc {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1256px;
  }
  .css-1c9i981 {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    width: 100%;
    max-width: 1256px;
    animation: 1s ease 0s 1 normal forwards running animation-1ox1f7y;
  }
  @media (min-width: 320px) {
    .css-1c9i981 {
      padding: 10px;
    }
  }
  @media (min-width: 768px) {
    .css-1c9i981 {
      padding: 20px;
    }
  }
  @media (min-width: 1024px) {
    .css-1c9i981 {
      padding: 30px 20px;
    }
  }
  .css-1yxmbwk {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    padding: 8px;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  @media print {
    .css-1yxmbwk {
      -webkit-print-color-adjust: exact;
    }
  }
  .css-1yxmbwk:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  @media (hover: none) {
    .css-1yxmbwk:hover {
      background-color: transparent;
    }
  }
  .css-hbrx4q {
    display: block;
    font-weight: 600;
    width: 100%;
    font-size: 28px;
    line-height: 2.188rem;
    margin-bottom: 20px;
  }
  @media (min-width: 1024px) {
    .css-hbrx4q {
      font-size: 36px;
      line-height: 2.625rem;
    }
  }
  .css-w0pj6f {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    inset: 0px;
    border-radius: inherit;
  }
  .css-18n14td {
    background-image: url(https://dynamicmedia.livenationinternational.com/d/x/x/527f6551-d55c-4c97-be2b-90a38c88057e.jpg?auto=webp&width=360);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    animation: 0.3s ease-in 0s 1 normal forwards running animation-1ox1f7y;
    filter: blur(20px);
    transform: scale(1.1);
  }
  .css-18n14td::after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .css-11uwtq5 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    animation: 0.3s ease-in 0s 1 normal forwards running animation-nq0y8k;
    object-fit: contain;
    transform: translate(-50%, -50%);
  }
  /*! CSS Used from: Embedded */
  *,
  :after,
  :before {
    box-sizing: inherit;
    font-family: inherit;
    outline-color: #e21937;
  }
  a,
  div,
  h1,
  img,
  span {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }
  a {
    text-decoration: none;
  }
  button {
    border-radius: 0;
  }
  /*! CSS Used from: Embedded */
  .jss29 {
    padding: 4px;
    transform: scale(1);
    background: rgba(255, 255, 255, 0.3);
    transition: 250ms all ease;
    margin-bottom: 8px;
  }
  .jss29:hover {
    transform: scale(1.2);
    background: rgba(255, 255, 255, 1);
  }
  /*! CSS Used from: Embedded */
  div,
  span,
  h1,
  a,
  img {
    margin: 0px;
    padding: 0px;
    border: 0px;
    font-size: 100%;
    vertical-align: baseline;
  }
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    outline-color: rgb(226, 25, 55);
  }
  button {
    padding: 0px;
    border: 0px;
    margin: 0px;
    background: transparent;
    font-size: inherit;
    font-family: inherit;
  }
  a {
    color: rgb(34, 34, 34);
  }
`;
