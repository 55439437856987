import styled from 'styled-components';

export const TopHeader = styled.div`
  width: 100%;
  background: rgb(226, 25, 55);
  padding: 15px 20px;
  @media (max-width: 768px) {
    padding: 15px 0;
  }
  p {
    padding: 0 30px;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;
