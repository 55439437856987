import styled from 'styled-components';

export const ConcertItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 4px 16px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  background-clip: padding-box;
  transition: 0.3s ease-in-out;
  padding: 10px 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }

  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 85px;
    @media (max-width: 768px) {
      width: 80px;
    }
    p:first-child {
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
    }
    p:nth-child(2) {
      font-size: 28px;
      font-weight: bold;
      line-height: 32px;
    }
    p:last-child {
      padding: 1px;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
    }
  }
  .content {
    padding: 10px 15px;
    border-left: 1px solid #e0e0e0;
    @media (max-width: 768px) {
      width: calc(100% - 90px);
    }
    h2 {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.3;
      margin: 0;
      margin-bottom: 2px;
    }
    h5 {
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      line-height: 1.4;
      margin-bottom: 2px;
    }
    p {
      color: #6e6e6e;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.6;
      margin-bottom: 5px;
    }
    span {
      background: #d6e7f9;
      color: #212121;
      padding: 2px 8px;
      font-size: 11px;
      margin-top: 8px;
      font-weight: bold;
      margin-right: 4px;
    }
  }
  button {
    margin-left: auto;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 20px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(226, 25, 55);
    box-shadow: none !important;
    margin-right: 6px;
    @media (max-width: 768px) {
      margin: 0 auto;
      margin-bottom: 8px;
      margin-top: 8px;
    }
    &:hover {
      text-decoration: none;
      background-color: rgb(167, 22, 45);
    }
  }
`;
