import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TopHeader from '../components/ConcertComponents/TopHeader';
import TopBanner from '../components/ConcertComponents/TopBanner';
import Main from '../components/ConcertComponents/Main';

const ConcertTickets = () => {
  return (
    <div
      style={{
        background: 'rgb(243, 243, 243)',
        fontFamily: 'Montserrat, sans-serif',
      }}>
      <Navbar />
      <TopHeader />
      <TopBanner />
      <Main />
      <Footer />
    </div>
  );
};

export default ConcertTickets;
