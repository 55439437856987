export const hamburgTickets = [
  {
    zone: 'Front Floor',
    row: 'Standing',
    tickets_left: 5,
    price: 119.98,
  },
  {
    zone: 'Back Floor',
    row: 'Standing',
    tickets_left: 3,
    price: 129.98,
  },
  {
    zone: '16C',
    row: 4,
    tickets_left: 3,
    price: 98.98,
  },
  {
    zone: '15C',
    row: 7,
    tickets_left: 6,
    price: 88.99,
  },
  {
    zone: '12C',
    row: 5,
    tickets_left: 4,
    price: 118.98,
  },
  {
    zone: '14A',
    row: 6,
    tickets_left: 5,
    price: 119.98,
  },
  {
    zone: '14B',
    row: 5,
    tickets_left: 6,
    price: 99.98,
  },
  {
    zone: '2A',
    row: 11,
    tickets_left: 2,
    price: 139.98,
  },
  {
    zone: '7C',
    row: 9,
    tickets_left: 3,
    price: 98.98,
  },
  {
    zone: '8C',
    row: 2,
    tickets_left: 4,
    price: 98.98,
  },
  {
    zone: '8B',
    row: 8,
    tickets_left: 3,
    price: 109.98,
  },
  {
    zone: '8A',
    row: 2,
    tickets_left: 7,
    price: 119.98,
  },
  {
    zone: '111',
    row: 6,
    tickets_left: 3,
    price: 88.98,
  },
  {
    zone: '9B',
    row: 14,
    tickets_left: 5,
    price: 119.98,
  },
];
