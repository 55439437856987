import Stadium from '../Stadium';
import * as S from './styles';
import { tickets } from '../../../tickets';
import { ticketsUk } from '../../../ticketsuk';
import { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import ConfirmTicket from '../ConfirmTicket';
import { concerts } from '../../../concerts';
import { useLocation } from 'react-router-dom';
import { ticketsUk2 } from '../../../ticketsuk2';
import { norwaytickets } from '../../../norwaytickets';
import { mantickets } from '../../../manchesterTickets';
import { hamburgTickets } from '../../../hamburgTickets';
const BuyComponent = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [selectedFilter, setSelectedFilter] = useState();
  const [hovered, setHovered] = useState();
  const [confirm, setConfirm] = useState();
  const [stadium, setStadium] = useState({});
  const location = useLocation();
  const [ticketsList, setTicketsList] = useState([]);

  useEffect(() => {
    if (
      stadium?.country &&
      stadium?.country.toLowerCase().includes('london') &&
      stadium?.date.includes('7')
    ) {
      setTicketsList([...ticketsUk]);
    } else if (
      stadium?.country &&
      stadium?.country.toLowerCase().includes('london') &&
      stadium?.date.includes('8')
    ) {
      setTicketsList([...ticketsUk2]);
    } else if (stadium?.country && stadium.country.toLowerCase() === 'dublin') {
      setTicketsList([...norwaytickets]);
    } else if (
      stadium?.country &&
      stadium.country.toLowerCase() === 'manchester'
    ) {
      setTicketsList([...mantickets]);
    } else if (
      stadium?.country &&
      stadium.country.toLowerCase() === 'hamburg'
    ) {
      setTicketsList([...hamburgTickets]);
    } else {
      setTicketsList([...tickets]);
    }
  }, [stadium]);

  useEffect(() => {
    var params = new URLSearchParams(location.search);
    for (let p of params) {
      if (p[0] === 'date') {
        setStadium(concerts.filter((elem) => elem.date === p[1])[0]);
      }
    }
    const items = document.querySelectorAll('.active_block:not(.grey_out)');

    for (const item of items) {
      item.addEventListener('click', function (event) {
        setSelectedItem(event.target.dataset.name);
        item.classList.remove('active_color');
        item.classList.add('active_color');
      });
    }
  }, [ticketsList]);

  useEffect(() => {
    const items = document.querySelectorAll('.active_block:not(.grey_out)');
    if (selectedItem) {
      for (const item of items) {
        if (
          item?.target?.dataset?.name
            .toString()
            .toLowerCase()
            .slice(0, selectedItem.length) ===
          selectedItem.toString().toLowerCase()
        ) {
          item.classList.add('active_color');
        } else {
          item.classList.remove('active_color');
        }
      }
    }
  }, [selectedItem, ticketsList]);

  const makeHovered = (selectedI) => {
    const items = document.querySelectorAll('.active_block:not(.grey_out)');
    setHovered(selectedI);
    if (selectedI) {
      for (const item of items) {
        if (
          item?.dataset?.name
            .toString()
            .toLowerCase()
            .slice(0, selectedI.length) === selectedI.toString().toLowerCase()
        ) {
          item.classList.add('active_color');
        } else {
          item.classList.remove('active_color');
        }
      }
    }
  };

  const removeSelected = () => {
    const items = document.querySelectorAll('.active_block:not(.grey_out)');

    for (const item of items) {
      item.classList.remove('active_color');
    }
  };

  return (
    <S.BuyComponent>
      <S.Aside>
        {!confirm ? (
          <>
            <div className='filters'>
              <div className='sort_by'>
                <p>Sort By Price:</p>
                <Dropdown
                  options={[
                    { label: 'Low to High', value: 'low-high' },
                    { label: 'High to Low', value: 'high_low' },
                  ]}
                  onChange={(o) => setSelectedFilter(o)}
                  value={selectedFilter}
                  placeholder='Select'
                />
              </div>

              <div
                className='clear_filters'
                onClick={() => {
                  setSelectedItem();
                  removeSelected();
                  setSelectedFilter();
                }}>
                <p>
                  <img src='/images/close.png' />
                  Clear Filters
                </p>
              </div>
            </div>
            {ticketsList &&
              ticketsList
                .filter((elem) => {
                  if (selectedItem) {
                    return (
                      elem.zone
                        .toString()
                        .toLowerCase()
                        .slice(0, selectedItem.length) ===
                      selectedItem.toString().toLowerCase()
                    );
                  } else {
                    return elem;
                  }
                })
                .sort((a, b) => {
                  if (selectedFilter?.value === 'low-high') {
                    return a.price - b.price;
                  } else if (selectedFilter?.value === 'high_low') {
                    return b.price - a.price;
                  } else {
                    return a.price === b.price;
                  }
                })
                .map((elem, i) => (
                  <S.Ticket
                    key={i}
                    onClick={() => makeHovered(elem.zone)}
                    onMouseEnter={() => makeHovered(elem.zone)}
                    active={elem.zone === hovered}>
                    <div>
                      <h2>
                        {elem?.zone.toString().includes('Front')
                          ? `Front Floor,`
                          : elem?.zone.toString().includes('Back')
                          ? 'Back Floor,'
                          : elem.zone >= 400
                          ? 'Upper Tier,'
                          : 'Lower Tier,'}{' '}
                        Block: {elem.zone}{' '}
                        {elem.row !== '' && `- Row: ${elem.row}`}
                      </h2>
                      <p>{elem.tickets_left} Tickets Left </p>
                    </div>
                    <div className='price'>
                      <button
                        onClick={() => {
                          setConfirm(elem);
                        }}>
                        €{elem.price}
                      </button>
                      <p>E-ticket</p>
                    </div>
                  </S.Ticket>
                ))}
          </>
        ) : (
          <ConfirmTicket
            data={{ ...confirm, ...stadium }}
            close={() => setConfirm()}
          />
        )}
      </S.Aside>
      <S.Stadium>
        <Stadium isLondon={stadium} />
      </S.Stadium>
    </S.BuyComponent>
  );
};

export default BuyComponent;
