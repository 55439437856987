import * as S from './styles';

const TopHeader = () => {
  return (
    <S.TopHeader>
      <p>
        TicketMasters is a secondary ticket marketplace. All tickets are
        guaranteed and secure. Prices are set by sellers and may be above or
        below face value.
      </p>
    </S.TopHeader>
  );
};

export default TopHeader;
