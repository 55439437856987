import styled from 'styled-components';

export const Main = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  max-width: 1256px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-top: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Aside = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
