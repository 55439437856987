const Manchester = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 698.26 636.08'
      className='manchester'>
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.cls-1{fill:#aaa;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px;}.cls-2{font-size:18px;}.cls-2,.cls-4,.cls-5,.cls-6{fill:#fff;}.cls-2,.cls-4,.cls-6{font-family:ArialMT, Arial;}.cls-3{letter-spacing:-0.02em;}.cls-4{font-size:14px;}.cls-6{font-size:16px;}.cls-7{fill:#464646;}',
          }}
        />
      </defs>
      <g id='Standing'>
        <g
          id='_0East'
          data-name='East'
          className='has_listing active_block default_color'>
          <rect
            id='_0East-2'
            data-name='East'
            className='cls-1 svg_shape'
            x='159.88'
            y='190.62'
            width='310.12'
            height='130.32'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0East_text' data-name='East'>
            <text
              className='cls-2 text'
              transform='translate(295.04 252.21)'
              data-name='East'>
              East
            </text>
          </g>
        </g>
        <g
          id='_0West'
          data-name='West'
          className='has_listing active_block default_color'>
          <rect
            id='_0West-2'
            data-name='West'
            className='cls-1 svg_shape'
            x='159.88'
            y='320.62'
            width='310.12'
            height='130.32'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0West_text' data-name='West'>
            <text
              className='cls-2 text'
              transform='translate(292.71 392.21)'
              data-name='West'>
              <tspan className='cls-3 text' data-name='West'>
                West
              </tspan>
            </text>
          </g>
        </g>
      </g>
      <g id='Level_100'>
        <g id='_101' data-name={101}>
          <path
            id='_101-2'
            data-name={101}
            className='cls-1 svg_shape'
            d='M199.18,245.72c12.29-2.1,28.86-3.48,47-4.39l-5.37-63.93a341.09,341.09,0,0,0-49.77,8.42Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_101_text'
            data-name='101_text'
            className='cls-4 text'
            transform='matrix(1, -0.06, 0.06, 1, 149.27, 156.58)'>
            101
          </text>
        </g>
        <g
          id='_102'
          data-name={102}
          className='has_listing active_block default_color'>
          <path
            id='_102-2'
            data-name={102}
            className='cls-1 svg_shape'
            d='M246.19,241.33c15.18-.76,31.46-1.19,47.26-1.43l-3.23-65.34c-16,.35-32.81,1.16-49.4,2.84Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_102_text'
            data-name={102}
            className='cls-4 text'
            transform='translate(197.78 150.45) rotate(-0.23)'>
            102
          </text>
        </g>
        <g id='_104' data-name={104}>
          <path
            id='_104-2'
            data-name={104}
            className='cls-1 svg_shape'
            d='M293.45,239.9c17.74-.27,34.88-.31,49.17-.31L341,174.53c-15.1-.18-32.48-.37-50.8,0Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_104_text'
            data-name='104_text'
            className='cls-4 text'
            transform='translate(246.16 149.67) rotate(-0.23)'>
            104
          </text>
        </g>
        <g
          id='_105'
          data-name={105}
          className='has_listing active_block default_color'>
          <polygon
            id='_105-2'
            data-name={105}
            className='cls-1 svg_shape'
            points='282.44 113.19 332.39 113.19 331.47 178.24 284.05 178.26 282.44 113.19'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_105_text'
            data-name={105}
            className='cls-4 text'
            transform='translate(296.28 149.67) rotate(-0.23)'>
            105
          </text>
        </g>
        <g id='_106' data-name={106}>
          <path
            id='_106-2'
            data-name={106}
            className='cls-1 svg_shape'
            d='M440.49,174.84c-17.48-.43-34.33-.41-49.53-.32l-.92,65c14.08,0,30.3.12,46.91.45Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_106_text'
            data-name='106_text'
            className='cls-4 text'
            transform='translate(345.12 149.12) rotate(2.49)'>
            106
          </text>
        </g>
        <g
          id='_107'
          data-name={107}
          className='has_listing active_block default_color'>
          <path
            id='_107-2'
            data-name={107}
            className='cls-1 svg_shape'
            d='M437,240c16.22.33,32.81.89,48.17,1.82l5-64.28c-16.54-1.51-33.36-2.31-49.65-2.71Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_107_text'
            data-name={107}
            className='cls-4 text'
            transform='translate(393.71 149.7) rotate(3.49)'>
            107
          </text>
        </g>
        <g
          id='_109'
          data-name={109}
          className='has_listing active_block default_color'>
          <path
            id='_109-2'
            data-name={109}
            className='cls-1 svg_shape'
            d='M485.12,241.83c18,1.09,34.31,2.7,46.33,5.06l7.77-62.1a402,402,0,0,0-49.08-7.24Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_109_text'
            data-name={109}
            className='cls-4 text'
            transform='translate(441.77 153.6) rotate(5)'>
            109
          </text>
        </g>
        <g id='_110' data-name={110}>
          <path
            id='_110-2'
            data-name={110}
            className='cls-1 svg_shape'
            d='M531.45,246.89c6.61,1.3,11.92,2.83,15.51,4.62.29.15.58.31.87.49l27.42-56.07a202,202,0,0,0-36-11.14Z'
            transform='translate(-58.57 -61.33)'
          />
          <path
            id='_110_text'
            data-name='110_text'
            className='cls-5 text'
            d='M543.9,213.31l-1.19-.32,2-7.57a6.25,6.25,0,0,1-1.35.52,8.28,8.28,0,0,1-1.42.27l.31-1.15a7.36,7.36,0,0,0,2.05-.66,4.66,4.66,0,0,0,1.41-1l.76.2Zm6.51,1.75-1.19-.31,2-7.58a6.25,6.25,0,0,1-1.35.52,8.31,8.31,0,0,1-1.41.28l.31-1.15a7.71,7.71,0,0,0,2.05-.67,4.56,4.56,0,0,0,1.4-1l.77.21Zm4.33-3.95a9.47,9.47,0,0,1,1.09-2.67,3.66,3.66,0,0,1,1.49-1.33,2.81,2.81,0,0,1,1.91-.09,3,3,0,0,1,1.28.68,2.72,2.72,0,0,1,.72,1.16,5.27,5.27,0,0,1,.22,1.61,10,10,0,0,1-.4,2.34,9.4,9.4,0,0,1-1.09,2.66,3.57,3.57,0,0,1-1.48,1.33,2.78,2.78,0,0,1-1.92.1,2.72,2.72,0,0,1-1.92-1.6A6.6,6.6,0,0,1,554.74,211.11Zm1.22.33a6.27,6.27,0,0,0-.3,3.32,1.66,1.66,0,0,0,2.76.74,6.35,6.35,0,0,0,1.41-3,6.13,6.13,0,0,0,.29-3.32A1.7,1.7,0,0,0,559,208a1.56,1.56,0,0,0-1.5.34A6.44,6.44,0,0,0,556,211.44Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g
          id='_111'
          data-name={111}
          className='has_listing active_block default_color'>
          <path
            id='_111-2'
            data-name={111}
            className='cls-1 svg_shape'
            d='M547.83,252c3,1.92,5.68,5.7,7.94,10.83l60.6-19.54c-6.81-19.13-16.67-34.75-30.91-42.45q-4.86-2.62-10.21-4.91Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_111_text'
            data-name={111}
            className='cls-5 text'
            d='M575,230.88l-.87-.87,5.57-5.52a6.4,6.4,0,0,1-1.42-.23,8.28,8.28,0,0,1-1.37-.48l.85-.83a7.33,7.33,0,0,0,2.1.45,4.55,4.55,0,0,0,1.73-.17l.55.57Zm4.74,4.79-.86-.87,5.57-5.52a6.31,6.31,0,0,1-1.42-.23,8.33,8.33,0,0,1-1.37-.47l.85-.84a7.33,7.33,0,0,0,2.1.45,4.49,4.49,0,0,0,1.72-.16l.56.56Zm4.75,4.8-.86-.88,5.57-5.52a6.47,6.47,0,0,1-1.43-.23,8.19,8.19,0,0,1-1.36-.47l.85-.84a7.35,7.35,0,0,0,2.1.46,4.7,4.7,0,0,0,1.72-.17l.56.56Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g id='_113' data-name={113}>
          <path
            id='_113-2'
            data-name={113}
            className='cls-1 svg_shape'
            d='M555.77,262.83c3.66,8.27,6.37,20.07,8.36,33.23l62.09-11.8c-2.22-14.34-5.38-28.42-9.85-41Z'
            transform='translate(-58.57 -61.33)'
          />
          <path
            id='_113_text'
            data-name='113_text'
            className='cls-5 text'
            d='M585.73,278.81l-1.22.17-1.07-7.76a5.88,5.88,0,0,1-1,1,7.88,7.88,0,0,1-1.19.81l-.17-1.18a7.24,7.24,0,0,0,1.63-1.41,4.55,4.55,0,0,0,.9-1.48l.79-.1Zm6.69-.92-1.22.17-1.08-7.77a6.17,6.17,0,0,1-1,1,7.92,7.92,0,0,1-1.2.81l-.16-1.18a7.18,7.18,0,0,0,1.62-1.41,4.55,4.55,0,0,0,.9-1.48l.79-.11Zm2.76-3.05,1.2-.33a2.67,2.67,0,0,0,.92,1.39,1.76,1.76,0,0,0,1.29.29,2,2,0,0,0,1.37-.8,2,2,0,0,0,.39-1.55,1.88,1.88,0,0,0-.74-1.31,1.91,1.91,0,0,0-1.47-.36,3.88,3.88,0,0,0-.84.26v-1.09h.2a2.69,2.69,0,0,0,1.34-.6,1.4,1.4,0,0,0,.45-1.34,1.46,1.46,0,0,0-.61-1.05,1.65,1.65,0,0,0-1.23-.28,1.69,1.69,0,0,0-1.12.61,2.26,2.26,0,0,0-.43,1.43l-1.24-.05a3.1,3.1,0,0,1,.75-2,3,3,0,0,1,1.88-.95,3.32,3.32,0,0,1,1.54.14A2.56,2.56,0,0,1,600,268a2.43,2.43,0,0,1,.54,1.23,2,2,0,0,1-.19,1.22,2.39,2.39,0,0,1-.9,1,2.31,2.31,0,0,1,1.48.66,2.55,2.55,0,0,1,.71,1.53A3,3,0,0,1,601,276a3.4,3.4,0,0,1-2.27,1.23,3.14,3.14,0,0,1-2.28-.48A3.07,3.07,0,0,1,595.18,274.84Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g id='_114' data-name={114}>
          <path
            id='_114-2'
            data-name={114}
            className='cls-1 svg_shape'
            d='M564.13,296.06c2.07,13.64,3.38,28.76,4.18,43l62.52-4.6a449.78,449.78,0,0,0-4.61-50.17Z'
            transform='translate(-58.57 -61.33)'
          />
          <path
            id='_114_text'
            data-name='114_text'
            className='cls-5 text'
            d='M593.48,320.36l-1.23.11-.7-7.81a5.93,5.93,0,0,1-1.09.95,8.85,8.85,0,0,1-1.23.75l-.11-1.18a7.35,7.35,0,0,0,1.69-1.34,4.52,4.52,0,0,0,1-1.43l.79-.07Zm6.72-.61-1.23.11-.7-7.81a6.28,6.28,0,0,1-1.09.95,7.26,7.26,0,0,1-1.23.75l-.11-1.18a7.35,7.35,0,0,0,1.69-1.34,4.52,4.52,0,0,0,1-1.43l.79-.07Zm7.06-.64-.21-2.39-4.33.39-.1-1.12,4-6.88,1-.09.58,6.47,1.35-.12.1,1.12-1.35.12.22,2.39ZM607,315.6l-.41-4.5-2.72,4.78Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g
          id='_115'
          data-name={115}
          className='has_listing active_block default_color'>
          <path
            id='_115-2'
            data-name={115}
            className='cls-1 svg_shape'
            d='M568.31,339c.94,16.71,1.19,32.17,1.18,42.49l61.76-.09q0-1.46,0-2.79c.12-10.41.28-26.19-.45-44.21Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_115_text'
            data-name={115}
            className='cls-5 text'
            d='M595.25,364.44l-1.23,0-.12-7.84a6,6,0,0,1-1.15.87,8.65,8.65,0,0,1-1.29.65V357a7.31,7.31,0,0,0,1.78-1.2,4.65,4.65,0,0,0,1.08-1.36h.79Zm6.74-.1-1.22,0-.12-7.84a5.93,5.93,0,0,1-1.15.86,7.92,7.92,0,0,1-1.29.66l0-1.19a7.19,7.19,0,0,0,1.79-1.2,4.6,4.6,0,0,0,1.07-1.36h.8Zm3.12-2.67,1.29-.13a2.3,2.3,0,0,0,.68,1.41,1.87,1.87,0,0,0,1.27.46,2,2,0,0,0,1.5-.69,2.56,2.56,0,0,0,.59-1.79,2.28,2.28,0,0,0-.62-1.65,2.07,2.07,0,0,0-1.56-.59,2.17,2.17,0,0,0-1.07.29,2.1,2.1,0,0,0-.74.71l-1.16-.14.9-5.16,5-.07,0,1.18-4,.05-.5,2.71a3.25,3.25,0,0,1,1.88-.66,3,3,0,0,1,2.23.87,3.19,3.19,0,0,1,.94,2.33,3.65,3.65,0,0,1-.76,2.36,3.19,3.19,0,0,1-2.61,1.26,3.3,3.3,0,0,1-2.25-.73A3,3,0,0,1,605.11,361.67Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g id='_116' data-name={116}>
          <path
            id='_116-2'
            data-name={116}
            className='cls-1 svg_shape'
            d='M569.49,381.52c0,1.9,0,3.63,0,5.16-.1,8.46-.09,22.63-.87,38.66l61.23,4.25c1.19-19.42,1.32-36.68,1.43-48.16Z'
            transform='translate(-58.57 -61.33)'
          />
          <path
            id='_116_text'
            data-name='116_text'
            className='cls-5 text'
            d='M593.75,409.22l-1.23-.06.38-7.83a6.39,6.39,0,0,1-1.2.79,8.14,8.14,0,0,1-1.33.57l.06-1.18a7.72,7.72,0,0,0,1.86-1.09,5,5,0,0,0,1.16-1.29l.79,0Zm6.74.33-1.23-.06.38-7.83a6.93,6.93,0,0,1-1.2.79,8.14,8.14,0,0,1-1.33.57l.06-1.19a7.4,7.4,0,0,0,1.86-1.09,4.56,4.56,0,0,0,1.15-1.28l.8,0Zm9.89-7.1-1.23,0a2.28,2.28,0,0,0-.41-1.07,1.58,1.58,0,0,0-1.2-.58,1.72,1.72,0,0,0-1,.27,2.82,2.82,0,0,0-1,1.2,6.24,6.24,0,0,0-.46,2.29,2.84,2.84,0,0,1,1.13-.95,2.92,2.92,0,0,1,1.36-.26,2.8,2.8,0,0,1,2.05,1,3.24,3.24,0,0,1,.75,2.38,3.85,3.85,0,0,1-.49,1.74,2.95,2.95,0,0,1-2.81,1.53,3.11,3.11,0,0,1-2.45-1.25,5.72,5.72,0,0,1-.79-3.77,6.67,6.67,0,0,1,1.28-4.17,3.09,3.09,0,0,1,2.57-1,2.89,2.89,0,0,1,1.91.75A2.84,2.84,0,0,1,610.38,402.45Zm-5.23,4.07a2.9,2.9,0,0,0,.21,1.23,2.08,2.08,0,0,0,.72.92,1.81,1.81,0,0,0,1,.35,1.72,1.72,0,0,0,1.37-.56,2.47,2.47,0,0,0,.64-1.68,2.32,2.32,0,0,0-.47-1.66,1.83,1.83,0,0,0-1.37-.67,1.93,1.93,0,0,0-1.44.53A2.19,2.19,0,0,0,605.15,406.52Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g id='_117' data-name={117}>
          <path
            id='_117-2'
            data-name={117}
            className='cls-1 svg_shape'
            d='M568.59,425.34a366.43,366.43,0,0,1-4.21,42.05l60.39,9a458.66,458.66,0,0,0,5.05-46.84Z'
            transform='translate(-58.57 -61.33)'
          />
          <path
            id='_117_text'
            data-name='117_text'
            className='cls-5 text'
            d='M591.37,455.05l-1.23-.11.69-7.81a6,6,0,0,1-1.24.75,8.42,8.42,0,0,1-1.34.52l.1-1.19a7.47,7.47,0,0,0,1.9-1,4.81,4.81,0,0,0,1.21-1.24l.79.07Zm6.72.59-1.23-.1.69-7.82a6,6,0,0,1-1.24.75A8.42,8.42,0,0,1,595,449l.1-1.19a7.47,7.47,0,0,0,1.9-1,4.51,4.51,0,0,0,1.21-1.24l.79.07Zm4-8.39.1-1.18,6.47.57-.09.95a13.71,13.71,0,0,0-2.12,2.54,15.61,15.61,0,0,0-1.75,3.33,13,13,0,0,0-.72,2.7l-1.26-.11a12.35,12.35,0,0,1,.72-2.81,15,15,0,0,1,1.55-3.1,13.63,13.63,0,0,1,2-2.46Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g
          id='_118'
          data-name={118}
          className='has_listing active_block default_color'>
          <path
            id='_118-2'
            data-name={118}
            className='cls-1 svg_shape'
            d='M564.38,467.39c-2,12-4.88,23.34-8.94,32.43L614.35,518c4.65-12.67,8-26.94,10.42-41.53Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_118_text'
            data-name={118}
            className='cls-5 text'
            d='M583,494.8l-1.21-.2,1.28-7.74a6.44,6.44,0,0,1-1.29.65,8.65,8.65,0,0,1-1.38.42l.19-1.18a7.24,7.24,0,0,0,2-.86,4.69,4.69,0,0,0,1.3-1.15l.78.13Zm6.66,1.1-1.22-.2,1.29-7.73a6.45,6.45,0,0,1-1.29.64,8.65,8.65,0,0,1-1.38.42l.19-1.17a7.5,7.5,0,0,0,2-.87,4.54,4.54,0,0,0,1.3-1.14l.78.13Zm5.86-4.53a2.25,2.25,0,0,1-1-1,2,2,0,0,1-.17-1.28,2.43,2.43,0,0,1,1.08-1.69,3.3,3.3,0,0,1,4.14.71,2.43,2.43,0,0,1,.49,2,2,2,0,0,1-.56,1.13,2.35,2.35,0,0,1-1.23.61,2.57,2.57,0,0,1,1.45,2.84,2.86,2.86,0,0,1-1.25,2,3.32,3.32,0,0,1-2.51.48,3.27,3.27,0,0,1-2.21-1.26,2.87,2.87,0,0,1-.54-2.31A2.68,2.68,0,0,1,594,492,2.49,2.49,0,0,1,595.55,491.37Zm-1,2.4a2.35,2.35,0,0,0,.08,1.05,1.78,1.78,0,0,0,.61.87,2.2,2.2,0,0,0,1,.44,2,2,0,0,0,1.53-.32,1.91,1.91,0,0,0,.8-1.33,2,2,0,0,0-.34-1.55,2,2,0,0,0-1.36-.81,1.91,1.91,0,0,0-1.52.33A2,2,0,0,0,594.5,493.77Zm1.15-4.5a1.55,1.55,0,0,0,.26,1.22,1.63,1.63,0,0,0,1.1.64,1.58,1.58,0,0,0,1.23-.25,1.48,1.48,0,0,0,.62-1,1.51,1.51,0,0,0-.27-1.2,1.74,1.74,0,0,0-2.3-.39A1.46,1.46,0,0,0,595.65,489.27Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g
          id='_119'
          data-name={119}
          className='has_listing active_block default_color'>
          <path
            id='_119-2'
            data-name={119}
            className='cls-1 svg_shape'
            d='M555.44,499.82A51.33,51.33,0,0,1,548,512.09L574,563.72c2.21-.83,4.38-1.71,6.51-2.62,15.65-6.68,26.44-22.84,33.89-43.14Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <path
            id='_119_text'
            data-name={119}
            className='cls-5 text'
            d='M580.59,539.41l-.85.89-5.64-5.45a6.87,6.87,0,0,1-.2,1.43,9,9,0,0,1-.44,1.38l-.86-.83a7.79,7.79,0,0,0,.41-2.12,4.76,4.76,0,0,0-.21-1.71l.55-.57Zm4.69-4.85-.86.88L578.78,530a6.33,6.33,0,0,1-.2,1.43,7.63,7.63,0,0,1-.44,1.37l-.86-.83a7.3,7.3,0,0,0,.41-2.11,4.65,4.65,0,0,0-.2-1.72l.55-.57Zm.65-4,.74-.93a2,2,0,0,0,1.27.43,1.58,1.58,0,0,0,1-.52,1.79,1.79,0,0,0,.51-.9,2.16,2.16,0,0,0,0-1,4.24,4.24,0,0,0-.53-1.15,6.32,6.32,0,0,0-.95-1.17,1.92,1.92,0,0,0-.19-.17,2.85,2.85,0,0,1,0,1.39,2.74,2.74,0,0,1-.7,1.25,2.79,2.79,0,0,1-2.11.88,3.26,3.26,0,0,1-2.31-1,3.38,3.38,0,0,1-1.14-2.35,3,3,0,0,1,.89-2.26,3.21,3.21,0,0,1,3.56-.77,7.41,7.41,0,0,1,2.32,1.66,9,9,0,0,1,1.91,2.42,3.68,3.68,0,0,1,.39,2.1,3.19,3.19,0,0,1-.92,1.8,2.64,2.64,0,0,1-3.74.3Zm.31-6.7a2.4,2.4,0,0,0-1.6-.76,1.76,1.76,0,0,0-1.39.54,1.9,1.9,0,0,0-.51,1.5,2.48,2.48,0,0,0,.83,1.64,2.16,2.16,0,0,0,1.52.67,2,2,0,0,0,1.42-.61,1.82,1.82,0,0,0,.54-1.43A2.34,2.34,0,0,0,586.24,523.85Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g id='_121' data-name={121}>
          <path
            id='_121-2'
            data-name={121}
            className='cls-1 svg_shape'
            d='M548,512.09a25.37,25.37,0,0,1-9.56,7.26c-2,.82-4.06,1.59-6.17,2.33l7.05,52A238.23,238.23,0,0,0,574,563.73Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_121_text'
            data-name='121_text'
            className='cls-4 text'
            transform='matrix(0.97, -0.22, 0.22, 0.97, 482.06, 494.58)'>
            121
          </text>
        </g>
        <g id='_122' data-name={122}>
          <path
            id='_122-2'
            data-name={122}
            className='cls-1 svg_shape'
            d='M532.29,521.68c-13.8,4.78-30.11,7.78-47.37,9.63l4.48,49.58a422,422,0,0,0,49.94-7.23Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_122_text'
            data-name='122_text'
            className='cls-4 text'
            transform='matrix(0.99, -0.11, 0.11, 0.99, 443.95, 499.03)'>
            122
          </text>
        </g>
        <g id='_123' data-name={123}>
          <path
            id='_123-2'
            data-name={123}
            className='cls-1 svg_shape'
            d='M484.92,531.31c-15.22,1.64-31.17,2.37-46.8,2.64l2.5,49.59c16-.4,32.49-1.19,48.78-2.65Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_123_text'
            data-name='123_text'
            className='cls-4 text'
            transform='translate(394.69 502.89) rotate(-1.98)'>
            123
          </text>
        </g>
        <g id='_125' data-name={125}>
          <path
            id='_125-2'
            data-name={125}
            className='cls-1 svg_shape'
            d='M438.12,534c-17.46.3-34.52,0-49.66-.25l1.62,50.17c15.65.09,32.8.11,50.54-.33Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_125_text'
            data-name='125_text'
            className='cls-4 text'
            transform='translate(344.61 502.89) rotate(-1.98)'>
            125
          </text>
        </g>
        <g id='_126' data-name={126}>
          <polygon
            id='_126-2'
            data-name={126}
            className='cls-1 svg_shape'
            points='282.68 483.1 330.25 483.63 331.51 522.53 281.88 522.28 282.68 483.1'
          />
          <text
            id='_126_text'
            data-name='126_text'
            className='cls-4 text'
            transform='matrix(1, -0.01, 0.01, 1, 294.69, 509.15)'>
            126
          </text>
        </g>
        <g
          id='_127'
          data-name={127}
          className='has_listing active_block default_color'>
          <path
            id='_127-2'
            data-name={127}
            className='cls-1 svg_shape'
            d='M291.11,582.67c17.51.62,34.39.85,49.34.94l1-50.43c-14-.2-30.55-.6-47.71-1.25Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_127_text'
            data-name={127}
            className='cls-4 text'
            transform='translate(246.12 501.74) rotate(1.82)'>
            127
          </text>
        </g>
        <g id='_128' data-name={128}>
          <path
            id='_128-2'
            data-name={128}
            className='cls-1 svg_shape'
            d='M293.78,531.93c-16-.61-32.52-1.44-47.84-2.55l-4.81,50.32c16.52,1.47,33.53,2.39,50,3Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_128_text'
            data-name='128_text'
            className='cls-4 text'
            transform='translate(197.41 499.13) rotate(4.58)'>
            128
          </text>
        </g>
        <g id='_131' data-name={131}>
          <path
            id='_131-2'
            data-name={131}
            className='cls-1 svg_shape'
            d='M198.1,524c-7.83-1.47-13.65-3.17-16.64-5.1a13,13,0,0,1-1.72-1.33l-23.35,45.2c9.65,4.19,21.61,7.53,35,10.21Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_131_text'
            data-name='131_text'
            className='cls-4 text'
            transform='translate(107.77 490.01) rotate(13.02)'>
            131
          </text>
        </g>
        <g id='_130' data-name={130}>
          <path
            id='_130-2'
            data-name={130}
            className='cls-1 svg_shape'
            d='M245.94,529.38c-18.73-1.36-35.7-3.13-47.84-5.43l-6.71,49a469.05,469.05,0,0,0,49.74,6.77Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_130_text'
            data-name='130_text'
            className='cls-4 text'
            transform='translate(147.75 494.94) rotate(6.9)'>
            130
          </text>
        </g>
        <g id='_132' data-name={132}>
          <path
            id='_132-2'
            data-name={132}
            className='cls-1 svg_shape'
            d='M179.74,517.52c-3.89-3.5-7.07-9.93-9.66-18.16l-54.31,18.69c7.14,17.22,17.34,31.68,31.94,40.3a78.88,78.88,0,0,0,8.68,4.37Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_132_text'
            data-name='132_text'
            className='cls-4 text'
            transform='translate(79.3 461.34) rotate(45.62)'>
            132
          </text>
        </g>
        <g id='_134' data-name={134}>
          <path
            id='_134-2'
            data-name={134}
            className='cls-1 svg_shape'
            d='M170.08,499.36c-2.86-9.07-5-20.34-6.61-32.3l-59,9.68c2.45,14.44,6,28.62,11.29,41.31Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_134_text'
            data-name='134_text'
            className='cls-4 text'
            transform='matrix(0.98, -0.21, 0.21, 0.98, 69.92, 436.99)'>
            134
          </text>
        </g>
        <g id='_135' data-name={135}>
          <path
            id='_135-2'
            data-name={135}
            className='cls-1 svg_shape'
            d='M136.31,471.52c-1.85-13.74-3.1-31.1-3.81-44.37l-32.67,2.08a382.19,382.19,0,0,0,4.65,47.51Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_135_text'
            data-name='135_text'
            className='cls-4 text'
            transform='matrix(1, -0.08, 0.08, 1, 47.45, 395.32)'>
            135
          </text>
        </g>
        <g id='_136' data-name={136}>
          <path
            id='_136-2'
            data-name={136}
            className='cls-1 svg_shape'
            d='M132.5,427.15c-.82-15.51-1.23-36.3-1.29-44.43,0-1.59,0,2,0,0l-31.9-.15c0,11.2-.26,27.86.52,46.66Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_136_text'
            data-name='136_text'
            className='cls-4 text'
            transform='translate(45 349.01) rotate(0.18)'>
            136
          </text>
        </g>
        <g id='_137' data-name={137}>
          <path
            id='_137-2'
            data-name={137}
            className='cls-1 svg_shape'
            d='M131.21,382.72c.06-10.32-.8-28.39.28-45l-31.32-3c-.89,18.26-1,34.68-.87,46,0,.58,0,1.19,0,1.81Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_137_text'
            data-name='137_text'
            className='cls-4 text'
            transform='translate(44 303.99) rotate(0.18)'>
            137
          </text>
        </g>
        <g id='_138' data-name={138}>
          <path
            id='_138-2'
            data-name={138}
            className='cls-1 svg_shape'
            d='M131.49,337.69c1-14.78,2.63-32.54,4.82-46.73l-31.65-5.18c-2.37,16.3-3.73,33.3-4.49,49Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_138_text'
            data-name='138_text'
            className='cls-4 text'
            transform='translate(45.96 254.15) rotate(6.5)'>
            138
          </text>
        </g>
        <g id='_139' data-name={139}>
          <path
            id='_139-2'
            data-name={139}
            className='cls-1 svg_shape'
            d='M164.84,295.64c1.92-12.51,4.41-23.76,7.58-32l-58.18-20c-4.29,12.33-7.37,26.89-9.58,42Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_139_text'
            data-name='139_text'
            className='cls-4 text'
            transform='translate(67.57 213.34) rotate(9.97)'>
            139
          </text>
        </g>
        <g id='_140' data-name={140}>
          <path
            id='_140-2'
            data-name={140}
            className='cls-1 svg_shape'
            d='M172.42,263.68c2.3-5.94,5-10.28,8-12.33a16.38,16.38,0,0,1,2.46-1.34l-25.18-52.48a105,105,0,0,0-27.53,18.32c-6.63,6.28-11.86,16.06-16,27.88Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_140_text'
            data-name='140_text'
            className='cls-4 text'
            transform='translate(86.82 183.28) rotate(-49.01)'>
            140
          </text>
        </g>
        <g id='_142' data-name={142}>
          <path
            id='_142-2'
            data-name={142}
            className='cls-1 svg_shape'
            d='M182.92,250c3.72-1.69,9.3-3.1,16.26-4.29l-8.13-59.9a185,185,0,0,0-33.31,11.71Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_142_text'
            data-name='142_text'
            className='cls-4 text'
            transform='translate(110.08 154.62) rotate(-15.82)'>
            142
          </text>
        </g>
      </g>
      <g id='Level_200'>
        <g id='_201' data-name={201}>
          <path
            id='_201-2'
            data-name={201}
            className='cls-1 svg_shape'
            d='M191.06,185.87a340.86,340.86,0,0,1,49.77-8.42l-4.62-49.24A416.29,416.29,0,0,0,185,140.87Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_201_text'
            data-name='201_text'
            className='cls-6 text'
            transform='translate(144.1 113.6) rotate(-9.82)'>
            201
          </text>
        </g>
        <g id='_202' data-name={202}>
          <path
            id='_202-2'
            data-name={202}
            className='cls-1 svg_shape'
            d='M240.83,177.45c16.59-1.69,33.35-2.51,49.39-2.87l-2.75-53.49c-16.62,1.63-34,3.92-51.26,7.12Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_202_text'
            data-name='202_text'
            className='cls-6 text'
            transform='translate(193.07 106.67) rotate(-2.94)'>
            202
          </text>
        </g>
        <g id='_204' data-name={204}>
          <path
            id='_204-2'
            data-name={204}
            className='cls-1 svg_shape'
            d='M290.22,174.58c18.33-.41,35.71-.22,50.82-.05l-.57-56.76c-16,.49-34,1.47-53,3.32Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_204_text'
            data-name='204_text'
            className='cls-6 text'
            transform='translate(244.18 103.83) rotate(-1)'>
            204
          </text>
        </g>
        <g id='_205' data-name={205}>
          <polygon
            id='_205-2'
            data-name={205}
            className='cls-1 svg_shape'
            points='281.88 56.44 332.39 56.19 332.39 113.19 282.44 113.19 281.88 56.44'
          />
          <text
            id='_205_text'
            data-name='205_text'
            className='cls-6 text'
            transform='translate(294.85 103.31) rotate(-0.21)'>
            205
          </text>
        </g>
        <g id='_206' data-name={206}>
          <path
            id='_206-2'
            data-name={206}
            className='cls-1 svg_shape'
            d='M443.65,120c-18.81-1.5-36.71-2.16-52.67-2.43v57c15.16-.08,32-.08,49.51.38Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_206_text'
            data-name='206_text'
            className='cls-6 text'
            transform='translate(345.71 102.77) rotate(2.1)'>
            206
          </text>
        </g>
        <g id='_207' data-name={207}>
          <path
            id='_207-2'
            data-name={207}
            className='cls-1 svg_shape'
            d='M440.49,174.91c16.28.43,33.11,1.25,49.64,2.77l4.33-51.52c-17.17-2.92-34.34-4.89-50.81-6.21Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_207_text'
            data-name='207_text'
            className='cls-6 text'
            transform='matrix(1, 0.06, -0.06, 1, 395.03, 104.24)'>
            207
          </text>
        </g>
        <g id='_209' data-name={209}>
          <path
            id='_209-2'
            data-name={209}
            className='cls-1 svg_shape'
            d='M490.13,177.68a408.63,408.63,0,0,1,49.07,7.21l5.94-46.84a395.71,395.71,0,0,0-50.68-11.89Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_209_text'
            data-name='209_text'
            className='cls-6 text'
            transform='translate(444.21 108.29) rotate(7.73)'>
            209
          </text>
        </g>
        <g id='_210' data-name={210}>
          <path
            id='_210-2'
            data-name={210}
            className='cls-1 svg_shape'
            d='M539.2,184.89a202.48,202.48,0,0,1,36,11.05l18.23-37.56a250.29,250.29,0,0,0-48.34-20.33Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_210_text'
            data-name='210_text'
            className='cls-6 text'
            transform='translate(490.34 114.36) rotate(16.85)'>
            210
          </text>
        </g>
        <g id='_211' data-name={211}>
          <path
            id='_211-2'
            data-name={211}
            className='cls-1 svg_shape'
            d='M575.25,195.94q5.38,2.3,10.25,4.91A51.17,51.17,0,0,1,600.89,214l30.54-26.45c-1.28-1.5-2.59-2.91-3.93-4.21a164.17,164.17,0,0,0-34-25Z'
            transform='translate(-58.57 -61.33)'
          />
          <path
            id='_211_text'
            data-name='211_text'
            className='cls-5 text'
            d='M596.35,185.36l-.8,1.09L589.42,182a2.63,2.63,0,0,1,.71-.7,5.44,5.44,0,0,1,1.64-.69,15.23,15.23,0,0,1,2.51-.32,16.35,16.35,0,0,0,3.47-.48,2.93,2.93,0,0,0,1.52-1,1.86,1.86,0,0,0,.35-1.49,2.45,2.45,0,0,0-2.6-1.84,2.33,2.33,0,0,0-1.49,1l-1.08-1a3.48,3.48,0,0,1,2.35-1.34,3.79,3.79,0,0,1,2.6.84,3.61,3.61,0,0,1,1.58,2.27,3,3,0,0,1-.56,2.4,3.27,3.27,0,0,1-1,.93,5.38,5.38,0,0,1-1.57.58,23.24,23.24,0,0,1-2.94.36q-1.65.12-2.16.21a4.45,4.45,0,0,0-.93.27Zm4.71,5.09-1.14-.83,5.26-7.25a7.45,7.45,0,0,1-1.64,0,9,9,0,0,1-1.63-.28l.8-1.1a8.58,8.58,0,0,0,2.46.12,5.46,5.46,0,0,0,1.91-.5l.73.53ZM607.3,195l-1.14-.82,5.27-7.26a6.92,6.92,0,0,1-1.65,0,9.94,9.94,0,0,1-1.63-.28l.8-1.1a8.6,8.6,0,0,0,2.46.12,5.19,5.19,0,0,0,1.91-.51l.74.54Z'
            transform='translate(-58.57 -61.33)'
          />
        </g>
        <g id='_212' data-name={212}>
          <path
            id='_212-2'
            data-name={212}
            className='cls-1 svg_shape'
            d='M600.89,214c6.42,7.93,11.5,18,15.52,29.26l37.47-12.51c-6.19-17.63-13.65-32.83-22.45-43.2Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_212_text'
            data-name='212_text'
            className='cls-6 text'
            transform='matrix(0.46, 0.89, -0.89, 0.46, 559.72, 146.92)'>
            212
          </text>
        </g>
        <g id='_213' data-name={213}>
          <path
            id='_213-2'
            data-name={213}
            className='cls-1 svg_shape'
            d='M616.36,243.26c4.48,12.57,7.64,26.65,9.86,41l40.17-7.38a371.4,371.4,0,0,0-12.56-46.13Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_213_text'
            data-name='213_text'
            className='cls-6 text'
            transform='matrix(0.97, -0.23, 0.23, 0.97, 570.24, 205.91)'>
            213
          </text>
        </g>
        <g id='_214' data-name={214}>
          <path
            id='_214-2'
            data-name={214}
            className='cls-1 svg_shape'
            d='M626.22,284.26a449.78,449.78,0,0,1,4.61,50.17L675,331c-1.91-17.24-4.75-35.89-8.59-54.13Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_214_text'
            data-name='214_text'
            className='cls-6 text'
            transform='translate(577.28 251.51) rotate(-5.92)'>
            214
          </text>
        </g>
        <g id='_215' data-name={215}>
          <path
            id='_215-2'
            data-name={215}
            className='cls-1 svg_shape'
            d='M630.83,334.43c.73,18,.57,33.8.45,44.22,0,.88,0,1.81,0,2.78l46.69-.27A435.75,435.75,0,0,0,675,331Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_215_text'
            data-name='215_text'
            className='cls-6 text'
            transform='translate(578.68 301.64) rotate(-0.72)'>
            215
          </text>
        </g>
        <g id='_216' data-name={216}>
          <path
            id='_216-2'
            data-name={216}
            className='cls-1 svg_shape'
            d='M631.25,381.43c-.11,11.47-.24,28.74-1.43,48.16l46.64,2.85c1.28-19.21,1.34-36.91,1.48-51.28Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_216_text'
            data-name='216_text'
            className='cls-6 text'
            transform='matrix(1, 0.03, -0.03, 1, 578.71, 348.21)'>
            216
          </text>
        </g>
        <g id='_217' data-name={217}>
          <path
            id='_217-2'
            data-name={217}
            className='cls-1 svg_shape'
            d='M629.82,429.59a458.66,458.66,0,0,1-5.05,46.84l45,6.73a369.42,369.42,0,0,0,6.66-50.72Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_217_text'
            data-name='217_text'
            className='cls-6 text'
            transform='translate(575.52 397.85) rotate(6.86)'>
            217
          </text>
        </g>
        <g id='_218' data-name={218}>
          <path
            id='_218-2'
            data-name={218}
            className='cls-1 svg_shape'
            d='M624.77,476.43C622.35,491,619,505.29,614.35,518l40.18,12.88a224.86,224.86,0,0,0,15.27-47.68Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_218_text'
            data-name='218_text'
            className='cls-6 text'
            transform='matrix(0.98, 0.2, -0.2, 0.98, 567.44, 443.42)'>
            218
          </text>
        </g>
        <g id='_219' data-name={219}>
          <path
            id='_219-2'
            data-name={219}
            className='cls-1 svg_shape'
            d='M614.35,518c-3.92,10.68-8.77,20.22-14.76,27.84l28.9,25a153.07,153.07,0,0,0,26-39.93Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_219_text'
            data-name='219_text'
            className='cls-6 text'
            transform='matrix(0.51, -0.86, 0.86, 0.51, 566.15, 496.48)'>
            219
          </text>
        </g>
        <g id='_220' data-name={220}>
          <path
            id='_220-2'
            data-name={220}
            className='cls-1 svg_shape'
            d='M599.59,545.8c-5.4,6.85-11.72,12.14-19.13,15.31q-3.19,1.36-6.51,2.61l17.48,36.13a158.65,158.65,0,0,0,32.77-24.59q2.2-2.21,4.29-4.49Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_220_text'
            data-name='220_text'
            className='cls-6 text'
            transform='translate(534.07 522.06) rotate(-30)'>
            220
          </text>
        </g>
        <g id='_221' data-name={221}>
          <path
            id='_221-2'
            data-name={221}
            className='cls-1 svg_shape'
            d='M574,563.72a235.82,235.82,0,0,1-34.63,9.87l5.49,46.91a262.31,262.31,0,0,0,46.62-20.65Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_221_text'
            data-name='221_text'
            className='cls-6 text'
            transform='translate(492.07 532.88) rotate(-10.71)'>
            221
          </text>
        </g>
        <g id='_222' data-name={222}>
          <path
            id='_222-2'
            data-name={222}
            className='cls-1 svg_shape'
            d='M539.32,573.59a430.32,430.32,0,0,1-49.93,7.18l4.91,53.15a418.87,418.87,0,0,0,50.51-13.42Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_222_text'
            data-name='222_text'
            className='cls-6 text'
            transform='translate(443.99 540.71) rotate(-7.69)'>
            222
          </text>
        </g>
        <g id='_223' data-name={223}>
          <path
            id='_223-2'
            data-name={223}
            className='cls-1 svg_shape'
            d='M489.39,580.77c-16.29,1.47-32.78,2.28-48.77,2.71l2.68,58.4c16.58-1.87,33.83-4.46,51-8Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_223_text'
            data-name='223_text'
            className='cls-6 text'
            transform='translate(395.54 545.85) rotate(-5.77)'>
            223
          </text>
        </g>
        <g id='_225' data-name={225}>
          <path
            id='_225-2'
            data-name={225}
            className='cls-1 svg_shape'
            d='M440.62,583.48c-17.78.47-34.95.47-50.54.39l.88,62c15.91-.61,33.7-1.82,52.34-3.94Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_225_text'
            data-name='225_text'
            className='cls-6 text'
            transform='translate(344.76 546.94) rotate(-3)'>
            225
          </text>
        </g>
        <g id='_226' data-name={226}>
          <polygon
            id='_226-2'
            data-name={226}
            className='cls-1 svg_shape'
            points='281.88 522.28 331.51 522.53 332.39 584.49 280.82 584.31 281.88 522.28'
          />
          <text
            id='_226_text'
            data-name='226_text'
            className='cls-6 text'
            transform='translate(294.73 546.56) rotate(-0.47)'>
            226
          </text>
        </g>
        <g id='_227' data-name={227}>
          <path
            id='_227-2'
            data-name={227}
            className='cls-1 svg_shape'
            d='M287.72,641.08c18.95,2.4,36.62,3.82,51.67,4.56l1.06-62c-14.95-.09-31.82-.33-49.34-1Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_227_text'
            data-name='227_text'
            className='cls-6 text'
            transform='matrix(1, 0.03, -0.03, 1, 244.41, 545.8)'>
            227
          </text>
        </g>
        <g
          id='_228'
          data-name={228}
          className='has_listing active_block default_color'>
          <path
            id='_228-2'
            data-name={228}
            className='cls-1 svg_shape'
            d='M291.11,582.66c-16.45-.59-33.46-1.52-50-3l-4.79,52.83c17.53,3.71,34.94,6.51,51.38,8.58Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_228_text'
            data-name={228}
            className='cls-6 text'
            transform='translate(192.6 541.72) rotate(4.44)'>
            228
          </text>
        </g>
        <g id='_230' data-name={230}>
          <path
            id='_230-2'
            data-name={230}
            className='cls-1 svg_shape'
            d='M241.13,579.67a471.75,471.75,0,0,1-49.74-6.76l-5.82,46a468.62,468.62,0,0,0,50.77,13.57Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_230_text'
            data-name='230_text'
            className='cls-6 text'
            transform='translate(142.15 535.17) rotate(7.46)'>
            230
          </text>
        </g>
        <g id='_231' data-name={231}>
          <path
            id='_231-2'
            data-name={231}
            className='cls-1 svg_shape'
            d='M191.39,572.91c-13.4-2.67-25.36-6-35-10.19l-18,36.18a295,295,0,0,0,47.22,20Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_231_text'
            data-name='231_text'
            className='cls-6 text'
            transform='translate(95.78 525.83) rotate(13.02)'>
            231
          </text>
        </g>
        <g id='_232' data-name={232}>
          <path
            id='_232-2'
            data-name={232}
            className='cls-1 svg_shape'
            d='M156.39,562.72a78.88,78.88,0,0,1-8.68-4.37,65,65,0,0,1-17.21-15l-29.38,28.93c10.44,10.13,23.1,19,37.23,26.59Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_232_text'
            data-name='232_text'
            className='cls-6 text'
            transform='translate(56.75 507.79) rotate(34.96)'>
            232
          </text>
        </g>
        <g id='_233' data-name={233}>
          <path
            id='_233-2'
            data-name={233}
            className='cls-1 svg_shape'
            d='M130.5,543.38a99.86,99.86,0,0,1-14.73-25.33L74.93,531.23c.13.39.25.78.38,1.16,5,15.17,14,28.4,25.81,39.92Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_233_text'
            data-name='233_text'
            className='cls-6 text'
            transform='translate(32.63 473.58) rotate(57.17)'>
            233
          </text>
        </g>
        <g id='_234' data-name={234}>
          <path
            id='_234-2'
            data-name={234}
            className='cls-1 svg_shape'
            d='M115.77,518.05c-5.25-12.69-8.84-26.87-11.29-41.31l-39,6.45c2.46,18.91,5.62,35.84,9.47,48Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_234_text'
            data-name='234_text'
            className='cls-6 text'
            transform='translate(18.25 448.81) rotate(-13.51)'>
            234
          </text>
        </g>
        <g id='_235' data-name={235}>
          <path
            id='_235-2'
            data-name={235}
            className='cls-1 svg_shape'
            d='M104.48,476.74a382.19,382.19,0,0,1-4.65-47.51l-39.17,2.49c1.09,17.48,2.69,35.21,4.8,51.47Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_235_text'
            data-name='235_text'
            className='cls-6 text'
            transform='translate(11.74 400.55) rotate(-4.93)'>
            235
          </text>
        </g>
        <g id='_236' data-name={236}>
          <path
            id='_236-2'
            data-name={236}
            className='cls-1 svg_shape'
            d='M99.83,429.23c-.78-18.8-.48-35.46-.52-46.66L59,382.72c0,.39,0,.78,0,1.16.1,14.7.67,31.16,1.7,47.84Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_236_text'
            data-name='236_text'
            className='cls-6 text'
            transform='translate(7.63 350.78) rotate(-1.28)'>
            236
          </text>
        </g>
        <g id='_237' data-name={237}>
          <path
            id='_237-2'
            data-name={237}
            className='cls-1 svg_shape'
            d='M99.31,382.57c0-.62,0-1.23,0-1.81-.09-11.35,0-27.77.87-46l-39-3.63c-1.67,19.72-2.32,37.8-2.26,51.62Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_237_text'
            data-name='237_text'
            className='cls-6 text'
            transform='matrix(1, 0.03, -0.03, 1, 7.66, 301.09)'>
            237
          </text>
        </g>
        <g id='_238' data-name={238}>
          <path
            id='_238-2'
            data-name={238}
            className='cls-1 svg_shape'
            d='M100.17,334.73c.76-15.65,2.12-32.65,4.49-49l-37.14-4.94c-2.9,16.89-4.95,34.12-6.31,50.26Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_238_text'
            data-name='238_text'
            className='cls-6 text'
            transform='matrix(0.99, 0.12, -0.12, 0.99, 10.83, 252.69)'>
            238
          </text>
        </g>
        <g id='_239' data-name={239}>
          <path
            id='_239-2'
            data-name={239}
            className='cls-1 svg_shape'
            d='M104.66,285.78c2.21-15.16,5.29-29.72,9.58-42L79.12,232.07a351.89,351.89,0,0,0-11.6,48.77Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_239_text'
            data-name='239_text'
            className='cls-6 text'
            transform='matrix(0.99, 0.12, -0.12, 0.99, 18.21, 203.28)'>
            239
          </text>
        </g>
        <g id='_240' data-name={240}>
          <path
            id='_240-2'
            data-name={240}
            className='cls-1 svg_shape'
            d='M114.24,243.73c4.11-11.82,9.34-21.6,16-27.88l-29.29-26.39c-8.91,10.06-16.08,25.07-21.8,42.61Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_240_text'
            data-name='240_text'
            className='cls-6 text'
            transform='matrix(0.46, -0.89, 0.89, 0.46, 43.47, 174.38)'>
            240
          </text>
        </g>
        <g id='_241' data-name={241}>
          <path
            id='_241-2'
            data-name={241}
            className='cls-1 svg_shape'
            d='M130.21,215.85a105,105,0,0,1,27.54-18.31L139.2,160.19c-14.11,7.8-26.67,17-37,27.83q-.66.71-1.32,1.44Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_241_text'
            data-name='241_text'
            className='cls-6 text'
            transform='matrix(0.83, -0.56, 0.56, 0.83, 65.43, 141.01)'>
            241
          </text>
        </g>
        <g id='_242' data-name={242}>
          <path
            id='_242-2'
            data-name={242}
            className='cls-1 svg_shape'
            d='M157.75,197.55a184.7,184.7,0,0,1,33.31-11.68l-6.09-45a258.12,258.12,0,0,0-45.77,19.32Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_242_text'
            data-name='242_text'
            className='cls-6 text'
            transform='translate(99.91 122.06) rotate(-17.4)'>
            242
          </text>
        </g>
      </g>
      <g id='Level_300'>
        <g id='_301' data-name={301}>
          <path
            id='_301-2'
            data-name={301}
            className='cls-1 svg_shape'
            d='M180.86,105.4a379.12,379.12,0,0,0-41.66,24.76L153,161.64q16.7-3.34,34-6.17Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_301_text'
            data-name='301_text'
            className='cls-6 text'
            transform='translate(94.23 83.13) rotate(-12.89)'>
            301
          </text>
        </g>
        <g id='_302' data-name={302}>
          <path
            id='_302-2'
            data-name={302}
            className='cls-1 svg_shape'
            d='M232.29,83.62a390.67,390.67,0,0,0-51.43,21.78l6.1,50.07q24.94-4.08,51-7Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_302_text'
            data-name='302_text'
            className='cls-6 text'
            transform='matrix(0.99, -0.15, 0.15, 0.99, 139.24, 67.76)'>
            302
          </text>
        </g>
        <g id='_303' data-name={303}>
          <path
            id='_303-2'
            data-name={303}
            className='cls-1 svg_shape'
            d='M284.92,69.52a403,403,0,0,0-52.63,14.1L238,148.44q24.85-2.82,50.61-4.54Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_303_text'
            data-name='303_text'
            className='cls-6 text'
            transform='translate(189.6 56.23) rotate(-4.61)'>
            303
          </text>
        </g>
        <g
          id='_304'
          data-name={304}
          className='has_listing active_block default_color'>
          <path
            id='_304-2'
            data-name={304}
            className='cls-1 svg_shape'
            d='M338.94,62.54a412.73,412.73,0,0,0-54,7l3.66,74.38q25.56-1.69,51.87-2.28Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_304_text'
            data-name={304}
            className='cls-6 text'
            transform='translate(241.66 44.14) rotate(-2.46)'>
            304
          </text>
        </g>
        <g id='_305' data-name={305}>
          <path
            id='_305-2'
            data-name={305}
            className='cls-1 svg_shape'
            d='M391.74,62.52q-13-.81-26.22-.81-13.41,0-26.58.83l1.51,79.08q12.68-.27,25.51-.27,12.57,0,25,.26Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_305_text'
            data-name='305_text'
            className='cls-6 text'
            transform='translate(294.39 41.7)'>
            305
          </text>
        </g>
        <g id='_306' data-name={306}>
          <path
            id='_306-2'
            data-name={306}
            className='cls-1 svg_shape'
            d='M446.12,69.51a414.6,414.6,0,0,0-54.38-7L391,141.61q26.09.55,51.48,2.23Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_306_text'
            data-name='306_text'
            className='cls-6 text'
            transform='translate(346.81 43.14) rotate(1.8)'>
            306
          </text>
        </g>
        <g
          id='_307'
          data-name={307}
          className='has_listing active_block default_color'>
          <path
            id='_307-2'
            data-name={307}
            className='cls-1 svg_shape'
            d='M497.55,83.21a403,403,0,0,0-51.43-13.7l-3.68,74.33q25.6,1.69,50.38,4.47Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_307_text'
            data-name={307}
            className='cls-6 text'
            transform='matrix(1, 0.07, -0.07, 1, 398.52, 51.39)'>
            307
          </text>
        </g>
        <g id='_308' data-name={308}>
          <path
            id='_308-2'
            data-name={308}
            className='cls-1 svg_shape'
            d='M549.09,104.85a390.74,390.74,0,0,0-51.54-21.64l-4.73,65.1q25.64,2.87,50.23,6.85Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_308_text'
            data-name='308_text'
            className='cls-6 text'
            transform='translate(447.91 63.89) rotate(7.91)'>
            308
          </text>
        </g>
        <g
          id='_309'
          data-name={309}
          className='has_listing active_block default_color'>
          <path
            id='_309-2'
            data-name={309}
            className='cls-1 svg_shape'
            d='M543.05,155.16q18.21,2.94,35.79,6.46l10.61-33.08a380.62,380.62,0,0,0-40.36-23.69Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_309_text'
            data-name={309}
            className='cls-6 text'
            transform='translate(493.04 78.53) rotate(12.74)'>
            309
          </text>
        </g>
        <g
          id='_313'
          data-name={313}
          className='has_listing active_block default_color'>
          <path
            id='_313-2'
            data-name={313}
            className='cls-1 svg_shape'
            d='M747.58,262.83c-3-19.1-6.59-37.48-10.86-55l-81.89,25.82q4.56,21.22,7.82,43.9Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_313_text'
            data-name={313}
            className='cls-6 text'
            transform='matrix(0.97, -0.23, 0.23, 0.97, 629.25, 193.02)'>
            313
          </text>
        </g>
        <g
          id='_314'
          data-name={314}
          className='has_listing active_block default_color'>
          <path
            id='_314-2'
            data-name={314}
            className='cls-1 svg_shape'
            d='M754.54,325.57c-1.5-21.6-3.84-42.57-7-62.74l-84.93,14.73c2.51,17.45,4.4,35.48,5.63,54Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_314_text'
            data-name={314}
            className='cls-6 text'
            transform='matrix(0.99, -0.14, 0.14, 0.99, 640.7, 244.63)'>
            314
          </text>
        </g>
        <g id='_315' data-name={315}>
          <path
            id='_315-2'
            data-name={315}
            className='cls-1 svg_shape'
            d='M756.46,381.52q0-28.54-1.92-55.95l-86.26,6q1.62,24.32,1.68,49.63Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_315_text'
            data-name='315_text'
            className='cls-6 text'
            transform='translate(643.23 299.68) rotate(-2.29)'>
            315
          </text>
        </g>
        <g id='_316' data-name={316}>
          <path
            id='_316-2'
            data-name={316}
            className='cls-1 svg_shape'
            d='M754.51,437.85c1.28-18.36,2-37.17,2-56.33l-86.5-.36v1.19c0,16.83-.55,33.39-1.6,49.59Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_316_text'
            data-name='316_text'
            className='cls-6 text'
            transform='translate(643.15 351.13) rotate(3.92)'>
            316
          </text>
        </g>
        <g id='_317' data-name={317}>
          <path
            id='_317-2'
            data-name={317}
            className='cls-1 svg_shape'
            d='M748.24,495.85c2.77-18.7,4.89-38.08,6.27-58l-86.15-5.91c-1.12,17.18-2.8,34-5,50.26Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_317_text'
            data-name='317_text'
            className='cls-6 text'
            transform='matrix(0.99, 0.12, -0.12, 0.99, 637, 404.04)'>
            317
          </text>
        </g>
        <g
          id='_318'
          data-name={318}
          className='has_listing active_block default_color'>
          <path
            id='_318-2'
            data-name={318}
            className='cls-1 svg_shape'
            d='M663.34,482.2Q660,506.88,655.06,530l81.18,27.22c4.8-19.42,8.83-39.93,12-61.32Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_318_text'
            data-name={318}
            className='cls-6 text'
            transform='translate(628.25 457.02) rotate(13.15)'>
            318
          </text>
        </g>
        <g id='_322' data-name={322}>
          <path
            id='_322-2'
            data-name={322}
            className='cls-1 svg_shape'
            d='M550,654.1A381,381,0,0,0,589.88,631l-10.27-31q-17.88,3.6-36.44,6.6Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_322_text'
            data-name='322_text'
            className='cls-6 text'
            transform='translate(494.76 570.4) rotate(-11.86)'>
            322
          </text>
        </g>
        <g
          id='_323'
          data-name={323}
          className='has_listing active_block default_color'>
          <path
            id='_323-2'
            data-name={323}
            className='cls-1 svg_shape'
            d='M543.17,606.52q-24.82,4-50.76,6.91l6.94,61.86A393.14,393.14,0,0,0,550,654.1Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_323_text'
            data-name={323}
            className='cls-6 text'
            transform='translate(449.32 584.45) rotate(-7.54)'>
            323
          </text>
        </g>
        <g
          id='_324'
          data-name={324}
          className='has_listing active_block default_color'>
          <path
            id='_324-2'
            data-name={324}
            className='cls-1 svg_shape'
            d='M492.41,613.43q-24.68,2.75-50.21,4.44L446,689.42a406.52,406.52,0,0,0,53.38-14.13Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_324_text'
            data-name={324}
            className='cls-6 text'
            transform='translate(398.88 595.1) rotate(-7.26)'>
            324
          </text>
        </g>
        <g id='_325' data-name={325}>
          <path
            id='_325-2'
            data-name={325}
            className='cls-1 svg_shape'
            d='M442.2,617.87q-25.43,1.67-51.61,2.22L392,696.24a417.94,417.94,0,0,0,54-6.82Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_325_text'
            data-name='325_text'
            className='cls-6 text'
            transform='matrix(1, -0.06, 0.06, 1, 348.22, 602.31)'>
            325
          </text>
        </g>
        <g
          id='_326'
          data-name={326}
          className='has_listing active_block default_color'>
          <path
            id='_326-2'
            data-name={326}
            className='cls-1 svg_shape'
            d='M390.59,620.09q-12.24.25-24.63.26-13.14,0-26.13-.29l-1.12,76.11q13.49.86,27.23.86,13.13,0,26-.79Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_326_text'
            data-name={326}
            className='cls-6 text'
            transform='matrix(1, 0, 0, 1, 293.41, 602.46)'>
            326
          </text>
        </g>
        <g id='_327' data-name={327}>
          <path
            id='_327-2'
            data-name={327}
            className='cls-1 svg_shape'
            d='M339.83,620.06q-25.72-.57-50.76-2.24l-3.85,71.46a418.72,418.72,0,0,0,53.49,6.89Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_327_text'
            data-name='327_text'
            className='cls-6 text'
            transform='matrix(1, 0.05, -0.05, 1, 242.02, 597.94)'>
            327
          </text>
        </g>
        <g
          id='_328'
          data-name={328}
          className='has_listing active_block default_color'>
          <path
            id='_328-2'
            data-name={328}
            className='cls-1 svg_shape'
            d='M289.07,617.82q-25.92-1.71-51-4.55l-5.83,61.93a405.82,405.82,0,0,0,53,14.08Z'
            transform='translate(-58.57 -61.33)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_328_text'
            data-name={328}
            className='cls-6 text'
            transform='translate(189.86 590.16) rotate(5.35)'>
            328
          </text>
        </g>
        <g id='_329' data-name={329}>
          <path
            id='_329-2'
            data-name={329}
            className='cls-1 svg_shape'
            d='M238.09,613.27q-26-2.94-50.92-7l-6.69,47.15a396.18,396.18,0,0,0,51.78,21.79Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_329_text'
            data-name='329_text'
            className='cls-6 text'
            transform='translate(136.78 576.99) rotate(7.46)'>
            329
          </text>
        </g>
        <g id='_330' data-name={330}>
          <path
            id='_330-2'
            data-name={330}
            className='cls-1 svg_shape'
            d='M187.17,606.26q-17.89-2.93-35.18-6.41l-12,29.8a381.29,381.29,0,0,0,40.44,23.76Z'
            transform='translate(-58.57 -61.33)'
          />
          <text
            id='_330_text'
            data-name='330_text'
            className='cls-6 text'
            transform='translate(91.53 563.27) rotate(13.02)'>
            330
          </text>
        </g>
      </g>
      <g id='Stage'>
        <polygon
          id='_0Stage'
          data-name='0Stage'
          className='cls-7 svg_shape'
          points='365.53 302.8 163.73 302.8 163.73 247.81 118.09 247.81 118.09 397.37 163.73 397.37 163.73 337.44 365.53 337.44 365.53 302.8'
        />
        <g id='_0Stage_text' data-name='0Stage_text'>
          <text
            className='cls-4 text'
            transform='translate(143.52 340.25) rotate(-90)'>
            Stage
          </text>
        </g>
      </g>
    </svg>
  );
};

export default Manchester;
