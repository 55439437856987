import styled from 'styled-components';

export const Products = styled.div`
  width: 100%;
  max-width: 90%;
  padding-top: 50px;
  padding-bottom: 90px;
  margin: 0 auto;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    picture {
      width: 100%;
      img {
        width: 100%;
      }
    }
    & > div {
      width: 31%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
      }
      h3,
      a {
        color: #fff !important;
        font-family: 'Druk Wide Web', futura-pt, HelveticaNeue-Light,
          'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
          'Lucida Grande', sans-serif;
        font-size: 22px;
        font-weight: 400;
        margin-top: -6px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          text-align: center;
        }
      }
      a {
        font-size: 16px;
        text-decoration: none;
        padding: 8px 45px;
        transition: 0.3s ease-in-out;
        &:hover {
          background: rgb(255, 255, 255);
          color: rgb(0, 0, 0) !important;
        }
      }
      p {
        font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif !important;
        margin: 16px 0;
        font-size: 18px;
        letter-spacing: 0.03em;
        font-weight: 400;
      }
    }
  }
`;
