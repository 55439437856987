import HomePage from './pages';
import Hero from './components/Hero';
import Routes from './Routes';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generate } from '@pdfme/generator';
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';
import { saveAs } from 'file-saver';
function App() {
  return (
    <Router>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme='light'
      />
      <Routes />
    </Router>
  );
}

export default App;
