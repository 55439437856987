import Loader from '../components/Loader';
import { useState, useEffect } from 'react';
import Stadium from '../components/SelectComponents/Stadium';
import BuyComponent from '../components/SelectComponents/BuyComponent';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TopBanners from '../components/SelectComponents/TopBanners';
import Checkout from '../components/Checkout';
const CheckOutPage = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';
    setTimeout(() => {
      setShowLoader(false);
      body.style.overflow = 'auto';
      window.scrollTo(0, 0);
    }, 3000);
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      <Navbar />
      <Checkout />
      <Footer />
    </>
  );
};

export default CheckOutPage;
