import { Router, Switch } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';
import Completion from './components/Completion';
import HomePage from './pages';
import Buy from './pages/buypage';
import CheckOutPage from './pages/checkoutPage';
import ConcertTickets from './pages/concert-tickets';

const Routes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={(props) => {
          return <HomePage {...props} />;
        }}
      />
      <Route
        path='/concert-tickets'
        exact
        render={(props) => {
          return <ConcertTickets {...props} />;
        }}
      />
      <Route
        path='/completion'
        exact
        render={(props) => {
          return <Completion {...props} />;
        }}
      />
      <Route
        path='/checkout/:id'
        exact
        render={(props) => {
          return <CheckOutPage {...props} />;
        }}
      />
      <Route
        path='/concert-tickets/buy/:id'
        exact
        render={(props) => {
          return <Buy {...props} />;
        }}
      />

      <Route
        path='/concert-tickets/:id'
        exact
        render={(props) => {
          return <ConcertTickets {...props} />;
        }}
      />
      <Route
        path='/.well-known/apple-developer-merchantid-domain-association'
        render={() => (
          <link
            rel='apple-developer-merchantid-domain-association.txt file'
            href='%PUBLIC_URL%/.well-known/apple-developer-merchantid-domain-association'
            download
          />
        )}
      />
      <Route path='*'>
        <Redirect to='/' />
      </Route>
    </Switch>
  );
};

export default Routes;
