import * as S from './styles';
import ConcertItem from '../ConcertItem';
import { concerts } from '../../../concerts';
const TicketsList = () => {
  return (
    <S.TicketsList>
      <h1>Events</h1>
      <div className='international'>
        <p>
          South America <span>(1)</span>
        </p>
      </div>
      <S.ItemsList>
        {concerts.map((elem, i) => (
          <ConcertItem data={elem} key={i} />
        ))}
      </S.ItemsList>
      <iframe
        width={560}
        height={315}
        src='https://www.youtube.com/embed/Du4vvsDHQ7Q'
        title='YouTube video player'
        frameBorder='{0}'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        className='iframe'
      />
      <p className='genres'>
        Genres <span>Pop</span> <i>|</i> <span>R&B/Soul</span>
      </p>
    </S.TicketsList>
  );
};

export default TicketsList;
