const SecondStadium = () => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 878 696'>
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.cls-1{fill:#999;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}.cls-2,.cls-3,.cls-6{isolation:isolate;}.cls-2{font-size:14px;}.cls-2,.cls-3,.cls-4,.cls-6{fill:#fff;font-family:Helvetica-Light, Helvetica;font-weight:300;}.cls-3{font-size:13px;}.cls-4{font-size:19px;}.cls-5{fill:#444;}.cls-6{font-size:26px;}',
          }}
        />
      </defs>
      <g id='Boxes'>
        <g id='_0M1' data-name='0M1'>
          <path
            id='_0M1-2'
            data-name='0M1'
            className='cls-1 svg_shape'
            d='M286.93,547l-19.76,15.9a285,285,0,0,0,23.5,18.39l20.81-16.65A296.3,296.3,0,0,1,286.93,547Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M1_text'
            data-name='0M1_text'
            className='cls-2 text'
            transform='translate(189.94 539.3)'>
            M1
          </text>
        </g>
        <g id='_0M2' data-name='0M2'>
          <path
            id='_0M2-2'
            data-name='0M2'
            className='cls-1 svg_shape'
            d='M311.48,564.62l-20.81,16.65a324.85,324.85,0,0,0,35,21.4l15.52-21.06A331,331,0,0,1,311.48,564.62Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M2_text'
            data-name='0M2_text'
            className='cls-2 text'
            transform='translate(218.94 557.3)'>
            M2
          </text>
        </g>
        <g id='_0M3' data-name='0M3'>
          <path
            id='_0M3-2'
            data-name='0M3'
            className='cls-1 svg_shape'
            d='M341.18,581.61l-15.52,21.06q13.15,7,27.32,13L366.09,593Q353.24,587.72,341.18,581.61Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M3_text'
            data-name='0M3_text'
            className='cls-2 text'
            transform='translate(247.94 573.3)'>
            M3
          </text>
        </g>
        <g id='_0M4' data-name='0M4'>
          <path
            id='_0M4-2'
            data-name='0M4'
            className='cls-1 svg_shape'
            d='M366.09,593,353,615.68q16.57,7.1,34.39,12.85L399.44,605Q382.21,599.72,366.09,593Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M4_text'
            data-name='0M4_text'
            className='cls-2 text'
            transform='translate(277.94 585.3)'>
            M4
          </text>
        </g>
        <g id='_0M5' data-name='0M5'>
          <path
            id='_0M5-2'
            data-name='0M5'
            className='cls-1 svg_shape'
            d='M399.44,605l-12.07,23.49q17.89,5.78,36.89,10.09l10-24.46Q416.4,610.31,399.44,605Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M5_text'
            data-name='0M5_text'
            className='cls-2 text'
            transform='translate(313.94 596.3)'>
            M5
          </text>
        </g>
        <g id='_0M6' data-name='0M6'>
          <path
            id='_0M6-2'
            data-name='0M6'
            className='cls-1 svg_shape'
            d='M434.29,614.16l-10,24.46Q441,642.4,458.48,645l5.73-25.47Q449,617.32,434.29,614.16Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M6_text'
            data-name='0M6_text'
            className='cls-2 text'
            transform='translate(346.94 604.3)'>
            M6
          </text>
        </g>
        <g id='_0M7' data-name='0M7'>
          <path
            id='_0M7-2'
            data-name='0M7'
            className='cls-1 svg_shape'
            d='M464.21,619.5,458.48,645q15.25,2.24,31,3.5l4-25.78Q478.61,621.57,464.21,619.5Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M7_text'
            data-name='0M7_text'
            className='cls-2 text'
            transform='translate(378.94 609.3)'>
            M7
          </text>
        </g>
        <g id='_0M8' data-name='0M8'>
          <path
            id='_0M8-2'
            data-name='0M8'
            className='cls-1 svg_shape'
            d='M528,624q-17.52,0-34.57-1.31l-4,25.78Q508.43,650,528,650h1V624Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M8_text'
            data-name='0M8_text'
            className='cls-2 text'
            transform='translate(411.94 611.3)'>
            M8
          </text>
        </g>
        <g id='_0M9' data-name='0M9'>
          <path
            id='_0M9-2'
            data-name='0M9'
            className='cls-1 svg_shape'
            d='M529,624v26q16.92,0,33.43-1.2l-2-25.94Q544.9,623.95,529,624Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M9_text'
            data-name='0M9_text'
            className='cls-2 text'
            transform='translate(447.94 611.3)'>
            M9
          </text>
        </g>
        <g id='_0M10' data-name='0M10'>
          <path
            id='_0M10-2'
            data-name='0M10'
            className='cls-1 svg_shape'
            d='M560.38,622.85l2,25.94q18-1.29,35.41-3.87l-3.31-25.82Q577.76,621.61,560.38,622.85Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M10_text'
            data-name='0M10_text'
            className='cls-2 text'
            transform='translate(475.94 610.3)'>
            M10
          </text>
        </g>
        <g id='_0M11' data-name='0M11'>
          <path
            id='_0M11-2'
            data-name='0M11'
            className='cls-1 svg_shape'
            d='M594.53,619.1l3.31,25.82q17.14-2.52,33.62-6.25l-6.15-25.3Q610.22,616.76,594.53,619.1Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M11_text'
            data-name='0M11_text'
            className='cls-2 text'
            transform='translate(510.92 603.98)'>
            M11
          </text>
        </g>
        <g id='_0M12' data-name='0M12'>
          <path
            id='_0M12-2'
            data-name='0M12'
            className='cls-1 svg_shape'
            d='M625.31,613.37l6.15,25.3q16.1-3.63,31.43-8.34l-7.55-24.9Q640.69,609.92,625.31,613.37Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M12_text'
            data-name='0M12_text'
            className='cls-2 text'
            transform='translate(541.94 597.3)'>
            M12
          </text>
        </g>
        <g id='_0M13' data-name='0M13'>
          <path
            id='_0M13-2'
            data-name='0M13'
            className='cls-1 svg_shape'
            d='M655.34,605.43l7.55,24.9q18.57-5.69,35.88-12.86l-10.22-23.86Q672.5,600.18,655.34,605.43Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M13_text'
            data-name='0M13_text'
            className='cls-2 text'
            transform='translate(573.94 587.3)'>
            M13
          </text>
        </g>
        <g id='_0M14' data-name='0M14'>
          <path
            id='_0M14-2'
            data-name='0M14'
            className='cls-1 svg_shape'
            d='M717.67,580.16q-14,7.26-29.12,13.45l10.22,23.86q16.1-6.65,31-14.48Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M14_text'
            data-name='0M14_text'
            className='cls-2 text'
            transform='translate(606.94 573.3)'>
            M14
          </text>
        </g>
        <g id='_0M15' data-name='0M15'>
          <path
            id='_0M15-2'
            data-name='0M15'
            className='cls-1 svg_shape'
            d='M765.32,581.29,747.63,562.6a325.59,325.59,0,0,1-30,17.56L729.72,603A327.07,327.07,0,0,0,765.32,581.29Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_0M15_text'
            data-name='0M15_text'
            className='cls-2 text'
            transform='translate(636.94 558.3)'>
            M15
          </text>
        </g>
      </g>
      <g id='Upper_Tier'>
        <g
          id='_201'
          data-name={201}
          className='has_listing active_block default_color'>
          <path
            id='_201-2'
            data-name={201}
            className='cls-1 svg_shape'
            d='M280.2,589.74,234,635.24a438.08,438.08,0,0,0,44.26,27.88l33.35-49.63A322.42,322.42,0,0,1,280.2,589.74Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_201_text'
            data-name={201}
            className='cls-2 text'
            transform='translate(173.94 602.3)'>
            201
          </text>
        </g>
        <g id='_202' data-name={202}>
          <path
            id='_202-2'
            data-name={202}
            className='cls-1 svg_shape'
            d='M311.65,613.49,278.3,663.12q18.54,10.21,38.44,18.91l27.31-49.49A345.9,345.9,0,0,1,311.65,613.49Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_202_text'
            data-name='202_text'
            className='cls-2 text'
            transform='translate(211.94 623.3)'>
            202
          </text>
        </g>
        <g id='_203' data-name={203}>
          <path
            id='_203-2'
            data-name={203}
            className='cls-1 svg_shape'
            d='M344.05,632.54,316.74,682q22,9.62,45.46,17.24l23.42-48.35A370.92,370.92,0,0,1,344.05,632.54Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_203_text'
            data-name='203_text'
            className='cls-2 text'
            transform='translate(251.94 642.3)'>
            203
          </text>
        </g>
        <g id='_204' data-name={204}>
          <path
            id='_204-2'
            data-name={204}
            className='cls-1 svg_shape'
            d='M385.62,650.92,362.2,699.27q19.67,6.39,40.25,11.26l16.07-49Q401.66,656.89,385.62,650.92Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_204_text'
            data-name='204_text'
            className='cls-2 text'
            transform='translate(291.94 656.3)'>
            204
          </text>
        </g>
        <g id='_205' data-name={205}>
          <path
            id='_205-2'
            data-name={205}
            className='cls-1 svg_shape'
            d='M418.52,661.51l-16.07,49q18.6,4.41,37.88,7.52L451.8,669.1Q434.82,666,418.52,661.51Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_205_text'
            data-name='205_text'
            className='cls-2 text'
            transform='translate(326.94 666.3)'>
            205
          </text>
        </g>
        <g id='_206' data-name={206}>
          <path
            id='_206-2'
            data-name={206}
            className='cls-1 svg_shape'
            d='M451.8,669.1l-11.47,48.95q20.9,3.36,42.48,5.12l3.67-49.2Q468.86,672.25,451.8,669.1Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_206_text'
            data-name='206_text'
            className='cls-2 text'
            transform='translate(364.94 673.3)'>
            206
          </text>
        </g>
        <g id='_207' data-name={207}>
          <path
            id='_207-2'
            data-name={207}
            className='cls-1 svg_shape'
            d='M486.48,674l-3.67,49.2Q503.14,724.82,524,725V676Q505,675.78,486.48,674Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_207_text'
            data-name='207_text'
            className='cls-2 text'
            transform='translate(403.94 676.3)'>
            207
          </text>
        </g>
        <g id='_208' data-name={208}>
          <path
            id='_208-2'
            data-name={208}
            className='cls-1 svg_shape'
            d='M528,676c-1.34,0-2.67,0-4,0v49l4,0q21,0,41.46-1.54l-2.31-49.28C554.3,675.37,541.24,676,528,676Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_208_text'
            data-name='208_text'
            className='cls-2 text'
            transform='translate(447.94 676.3)'>
            208
          </text>
        </g>
        <g id='_209' data-name={209}>
          <path
            id='_209-2'
            data-name={209}
            className='cls-1 svg_shape'
            d='M567.15,674.18l2.31,49.28q24-1.79,47.11-5.56l-7-49.84A413.63,413.63,0,0,1,567.15,674.18Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_209_text'
            data-name='209_text'
            className='cls-2 text'
            transform='translate(491.94 671.3)'>
            209
          </text>
        </g>
        <g id='_210' data-name={210}>
          <path
            id='_210-2'
            data-name={210}
            className='cls-1 svg_shape'
            d='M609.57,668.06l7,49.84q21.57-3.51,42.33-8.66l-14.56-49.68Q627.36,664.54,609.57,668.06Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_210_text'
            data-name='210_text'
            className='cls-2 text'
            transform='translate(532.94 664.3)'>
            210
          </text>
        </g>
        <g id='_211' data-name={211}>
          <path
            id='_211-2'
            data-name={211}
            className='cls-1 svg_shape'
            d='M644.34,659.56l14.56,49.68q21.51-5.34,42-12.34L680.61,647Q663,654.06,644.34,659.56Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_211_text'
            data-name='211_text'
            className='cls-2 text'
            transform='translate(572.01 653.92)'>
            211
          </text>
        </g>
        <g id='_212' data-name={212}>
          <path
            id='_212-2'
            data-name={212}
            className='cls-1 svg_shape'
            d='M680.61,647l20.32,49.95q21.18-7.21,41.17-16.12l-23.34-51.86A366.82,366.82,0,0,1,680.61,647Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_212_text'
            data-name='212_text'
            className='cls-2 text'
            transform='translate(611.94 640.3)'>
            212
          </text>
        </g>
        <g id='_213' data-name={213}>
          <path
            id='_213-2'
            data-name={213}
            className='cls-1 svg_shape'
            d='M718.76,628.92l23.34,51.86a464.46,464.46,0,0,0,43.18-21.93l-33.2-50.67A343,343,0,0,1,718.76,628.92Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_213_text'
            data-name='213_text'
            className='cls-2 text'
            transform='translate(650.94 620.3)'>
            213
          </text>
        </g>
        <g id='_214' data-name={214}>
          <path
            id='_214-2'
            data-name={214}
            className='cls-1 svg_shape'
            d='M752.08,608.18l33.2,50.67a433.41,433.41,0,0,0,41-26.78l-45.26-46.93A319.85,319.85,0,0,1,752.08,608.18Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_214_text'
            data-name='214_text'
            className='cls-2 text'
            transform='translate(687.94 598.3)'>
            214
          </text>
        </g>
        <g id='_215' data-name={215}>
          <path
            id='_215-2'
            data-name={215}
            className='cls-1 svg_shape'
            d='M781.06,585.14l45.26,46.93q14.52-10.7,27.77-22.41L802.39,564A295.68,295.68,0,0,1,781.06,585.14Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_215_text'
            data-name='215_text'
            className='cls-2 text'
            transform='translate(718.94 573.3)'>
            215
          </text>
        </g>
        <g id='_216' data-name={216}>
          <path
            id='_216-2'
            data-name={216}
            className='cls-1 svg_shape'
            d='M802.39,564l51.7,45.66q11.88-10.49,22.67-21.72l-58.9-42.44Q810.59,555,802.39,564Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_216_text'
            data-name='216_text'
            className='cls-2 text'
            transform='translate(739.94 552.3)'>
            216
          </text>
        </g>
        <g id='_217' data-name={217}>
          <path
            id='_217-2'
            data-name={217}
            className='cls-1 svg_shape'
            d='M817.86,545.5l58.9,42.44q11.43-11.9,21.56-24.57l-66.61-37.83Q825.29,535.77,817.86,545.5Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_217_text'
            data-name='217_text'
            className='cls-2 text'
            transform='translate(757.94 530.3)'>
            217
          </text>
        </g>
        <g id='_218' data-name={218}>
          <path
            id='_218-2'
            data-name={218}
            className='cls-1 svg_shape'
            d='M831.71,525.54l66.61,37.83q9.69-12.12,18.13-24.91L842,507.64Q837.22,516.76,831.71,525.54Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_218_text'
            data-name='218_text'
            className='cls-2 text'
            transform='translate(773.94 508.3)'>
            218
          </text>
        </g>
        <g id='_219' data-name={219}>
          <path
            id='_219-2'
            data-name={219}
            className='cls-1 svg_shape'
            d='M842,507.64l74.5,30.82a318.23,318.23,0,0,0,17.63-30.19l-81.71-23.59A238.79,238.79,0,0,1,842,507.64Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_219_text'
            data-name='219_text'
            className='cls-2 text'
            transform='translate(787.94 484.3)'>
            219
          </text>
        </g>
        <g id='_220' data-name={220}>
          <path
            id='_220-2'
            data-name={220}
            className='cls-1 svg_shape'
            d='M852.37,484.68l81.71,23.59a297.39,297.39,0,0,0,17.52-41.71L863,449.39A228.74,228.74,0,0,1,852.37,484.68Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_220_text'
            data-name='220_text'
            className='cls-2 text'
            transform='translate(800.94 452.3)'>
            220
          </text>
        </g>
        <g id='_221' data-name={221}>
          <path
            id='_221-2'
            data-name={221}
            className='cls-1 svg_shape'
            d='M867.11,422.19a221.89,221.89,0,0,1-4.11,27.2l88.6,17.17a283.39,283.39,0,0,0,10.27-40.76Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_221_text'
            data-name='221_text'
            className='cls-2 text'
            transform='translate(811.94 418.3)'>
            221
          </text>
        </g>
        <g id='_222' data-name={222}>
          <path
            id='_222-2'
            data-name={222}
            className='cls-1 svg_shape'
            d='M868,402.5c0,6.62-.31,13.19-.89,19.69l94.76,3.61a279.58,279.58,0,0,0,4.06-41.8H867.21C867.72,390.12,868,396.28,868,402.5Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_222_text'
            data-name='222_text'
            className='cls-2 text'
            transform='translate(816.94 377.3)'>
            222
          </text>
        </g>
        <g id='_223' data-name={223}>
          <path
            id='_223-2'
            data-name={223}
            className='cls-1 svg_shape'
            d='M867.21,384h98.72c0-2,.07-4,.07-6a278.65,278.65,0,0,0-4.2-48.26L861.1,347.4A223.72,223.72,0,0,1,867.21,384Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_223_text'
            data-name='223_text'
            className='cls-2 text'
            transform='translate(812.94 335.3)'>
            223
          </text>
        </g>
        <g id='_224' data-name={224}>
          <path
            id='_224-2'
            data-name={224}
            className='cls-1 svg_shape'
            d='M861.1,347.4l100.7-17.66a283.73,283.73,0,0,0-10-39.75l-99.37,30.43A229.72,229.72,0,0,1,861.1,347.4Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_224_text'
            data-name='224_text'
            className='cls-2 text'
            transform='translate(807.94 295.3)'>
            224
          </text>
        </g>
        <g id='_225' data-name={225}>
          <path
            id='_225-2'
            data-name={225}
            className='cls-1 svg_shape'
            d='M852.41,320.42,951.78,290a297.84,297.84,0,0,0-17.19-41.27l-93.4,47.15A238.8,238.8,0,0,1,852.41,320.42Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_225_text'
            data-name='225_text'
            className='cls-2 text'
            transform='translate(796.94 263.3)'>
            225
          </text>
        </g>
        <g id='_226' data-name={226}>
          <path
            id='_226-2'
            data-name={226}
            className='cls-1 svg_shape'
            d='M841.19,295.87l93.4-47.15a319.1,319.1,0,0,0-19.69-33.51L832,280Q836.9,287.78,841.19,295.87Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_226_text'
            data-name='226_text'
            className='cls-2 text'
            transform='translate(784.94 232.3)'>
            226
          </text>
        </g>
        <g id='_227' data-name={227}>
          <path
            id='_227-2'
            data-name={227}
            className='cls-1 svg_shape'
            d='M832,280l82.88-64.74a344.37,344.37,0,0,0-27-35.07l-74.11,74.11A265.61,265.61,0,0,1,832,280Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_227_text'
            data-name='227_text'
            className='cls-2 text'
            transform='translate(765.94 205.3)'>
            227
          </text>
        </g>
        <g id='_228' data-name={228}>
          <path
            id='_228-2'
            data-name={228}
            className='cls-1 svg_shape'
            d='M813.75,254.25l74.11-74.11A377.21,377.21,0,0,0,851.41,144l-64.79,81A289.2,289.2,0,0,1,813.75,254.25Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_228_text'
            data-name='228_text'
            className='cls-2 text'
            transform='translate(737.94 172.3)'>
            228
          </text>
        </g>
        <g id='_229' data-name={229}>
          <path
            id='_229-2'
            data-name={229}
            className='cls-1 svg_shape'
            d='M786.62,225l64.79-81a414.61,414.61,0,0,0-40.78-31.09l-53.3,87.69A313.56,313.56,0,0,1,786.62,225Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_229_text'
            data-name='229_text'
            className='cls-2 text'
            transform='translate(702.94 144.3)'>
            229
          </text>
        </g>
        <g id='_230' data-name={230}>
          <path
            id='_230-2'
            data-name={230}
            className='cls-1 svg_shape'
            d='M757.33,200.59l53.3-87.69A444.53,444.53,0,0,0,772.33,90l-49.75,88.24A339.22,339.22,0,0,1,757.33,200.59Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_230_text'
            data-name='230_text'
            className='cls-2 text'
            transform='translate(663.94 120.3)'>
            230
          </text>
        </g>
        <g id='_231' data-name={231}>
          <path
            id='_231-2'
            data-name={231}
            className='cls-1 svg_shape'
            d='M722.58,178.21,772.33,90q-18.23-9.72-37.69-18L692.9,163.28Q708.25,170.15,722.58,178.21Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_231_text'
            data-name='231_text'
            className='cls-2 text'
            transform='translate(630.94 98.3)'>
            231
          </text>
        </g>
        <g id='_232' data-name={232}>
          <path
            id='_232-2'
            data-name={232}
            className='cls-1 svg_shape'
            d='M692.9,163.28,734.64,72Q716,64.07,696.37,57.57l-33.51,93.81Q678.3,156.75,692.9,163.28Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_232_text'
            data-name='232_text'
            className='cls-2 text'
            transform='translate(597.94 84.3)'>
            232
          </text>
        </g>
        <g id='_233' data-name={233}>
          <path
            id='_233-2'
            data-name={233}
            className='cls-1 svg_shape'
            d='M662.86,151.38l33.51-93.81Q676.95,51.15,656.6,46.2l-25.66,95.58Q647.28,145.94,662.86,151.38Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_233_text'
            data-name='233_text'
            className='cls-2 text'
            transform='translate(561.94 73.3)'>
            233
          </text>
        </g>
        <g id='_234' data-name={234}>
          <path
            id='_234-2'
            data-name={234}
            className='cls-1 svg_shape'
            d='M630.94,141.78,656.6,46.2q-20.61-5-42.05-8.42l-15.9,97.15Q615.11,137.72,630.94,141.78Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_234_text'
            data-name='234_text'
            className='cls-2 text'
            transform='translate(525.94 65.3)'>
            234
          </text>
        </g>
        <g id='_235' data-name={235}>
          <path
            id='_235-2'
            data-name={235}
            className='cls-1 svg_shape'
            d='M598.65,134.93l15.9-97.15q-20.62-3.27-41.91-5l-5.21,98.06Q583.27,132.31,598.65,134.93Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_235_text'
            data-name='235_text'
            className='cls-2 text'
            transform='translate(488.94 59.3)'>
            235
          </text>
        </g>
        <g id='_236' data-name={236}>
          <path
            id='_236-2'
            data-name={236}
            className='cls-1 svg_shape'
            d='M567.43,130.84l5.21-98.06Q550.62,31,528,31v98C541.34,129,554.49,129.64,567.43,130.84Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_236_text'
            data-name='236_text'
            className='cls-2 text'
            transform='translate(447.94 54.3)'>
            236
          </text>
        </g>
        <g id='_237' data-name={237}>
          <path
            id='_237-2'
            data-name={237}
            className='cls-1 svg_shape'
            d='M528,129V31q-19.54,0-38.66,1.34l5.3,98Q511.11,129,528,129Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_237_text'
            data-name='237_text'
            className='cls-2 text'
            transform='translate(409.94 54.3)'>
            237
          </text>
        </g>
        <g id='_238' data-name={238}>
          <path
            id='_238-2'
            data-name={238}
            className='cls-1 svg_shape'
            d='M494.64,130.31l-5.3-98q-21.16,1.45-41.71,4.5l15.89,97.09Q478.85,131.58,494.64,130.31Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_238_text'
            data-name='238_text'
            className='cls-2 text'
            transform='translate(373.94 58.3)'>
            238
          </text>
        </g>
        <g id='_239' data-name={239}>
          <path
            id='_239-2'
            data-name={239}
            className='cls-1 svg_shape'
            d='M463.52,133.93,447.63,36.84Q425.87,40,404.9,44.9l22.3,96.32Q445,136.8,463.52,133.93Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_239_text'
            data-name='239_text'
            className='cls-2 text'
            transform='translate(336.94 63.3)'>
            239
          </text>
        </g>
        <g id='_240' data-name={240}>
          <path
            id='_240-2'
            data-name={240}
            className='cls-1 svg_shape'
            d='M427.2,141.22,404.9,44.9q-22.61,5.24-44.15,12.3l31.77,94.4Q409.41,145.69,427.2,141.22Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_240_text'
            data-name='240_text'
            className='cls-2 text'
            transform='translate(297.94 72.3)'>
            240
          </text>
        </g>
        <g id='_241' data-name={241}>
          <path
            id='_241-2'
            data-name={241}
            className='cls-1 svg_shape'
            d='M392.52,151.6,360.75,57.2Q340.85,63.72,322,71.72L360.4,164.5Q376,157.39,392.52,151.6Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_241_text'
            data-name='241_text'
            className='cls-2 text'
            transform='translate(258.94 86.3)'>
            241
          </text>
        </g>
        <g id='_242' data-name={242}>
          <path
            id='_242-2'
            data-name={242}
            className='cls-1 svg_shape'
            d='M360.4,164.5,322,71.72A473.13,473.13,0,0,0,278.14,93l48.23,89.32A358.88,358.88,0,0,1,360.4,164.5Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_242_text'
            data-name='242_text'
            className='cls-2 text'
            transform='translate(221.94 100.3)'>
            242
          </text>
        </g>
        <g id='_243' data-name={243}>
          <path
            id='_243-2'
            data-name={243}
            className='cls-1 svg_shape'
            d='M326.37,182.28,278.14,93Q259,103.54,241.32,115.65l58,84.42Q312.28,190.64,326.37,182.28Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_243_text'
            data-name='243_text'
            className='cls-2 text'
            transform='translate(185.94 120.3)'>
            243
          </text>
        </g>
        <g
          id='_244'
          data-name={244}
          className='has_listing active_block default_color'>
          <path
            id='_244-2'
            data-name={244}
            className='cls-1 svg_shape'
            d='M299.36,200.07l-58-84.42A413.26,413.26,0,0,0,205.73,143l69.62,76.48Q286.75,209.3,299.36,200.07Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_244_text'
            data-name={244}
            className='cls-2 text'
            transform='translate(151.94 140.3)'>
            244
          </text>
        </g>
        <g
          id='_245'
          data-name={245}
          className='has_listing active_block default_color'>
          <path
            id='_245-2'
            data-name={245}
            className='cls-1 svg_shape'
            d='M275.35,219.49,205.73,143a379.28,379.28,0,0,0-32.06,31l79.42,67.59A297.49,297.49,0,0,1,275.35,219.49Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_245_text'
            data-name={245}
            className='cls-2 text'
            transform='translate(124.94 168.3)'>
            245
          </text>
        </g>
        <g
          id='_246'
          data-name={246}
          className='has_listing active_block default_color'>
          <path
            id='_246-2'
            data-name={246}
            className='cls-1 svg_shape'
            d='M253.09,241.57,173.67,174a351.26,351.26,0,0,0-26.29,32.21l90.53,53.61Q245.05,250.43,253.09,241.57Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_246_text'
            data-name={246}
            className='cls-2 text'
            transform='translate(99.94 192.3)'>
            246
          </text>
        </g>
        <g
          id='_247'
          data-name={247}
          className='has_listing active_block default_color'>
          <path
            id='_247-2'
            data-name={247}
            className='cls-1 svg_shape'
            d='M237.91,259.8l-90.53-53.61a322.35,322.35,0,0,0-23.08,37L220.88,285A261.35,261.35,0,0,1,237.91,259.8Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_247_text'
            data-name={247}
            className='cls-2 text'
            transform='translate(79.94 221.3)'>
            247
          </text>
        </g>
        <g id='_248' data-name={248}>
          <path
            id='_248-2'
            data-name={248}
            className='cls-1 svg_shape'
            d='M220.88,285,124.3,243.16a298.37,298.37,0,0,0-19.77,45.9l99.68,29.82A243.53,243.53,0,0,1,220.88,285Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_248_text'
            data-name='248_text'
            className='cls-2 text'
            transform='translate(60.94 257.3)'>
            248
          </text>
        </g>
        <g id='_249' data-name={249}>
          <path
            id='_249-2'
            data-name={249}
            className='cls-1 svg_shape'
            d='M204.21,318.88l-99.68-29.82a281.89,281.89,0,0,0-11.18,45.85l100.47,17A229.66,229.66,0,0,1,204.21,318.88Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_249_text'
            data-name='249_text'
            className='cls-2 text'
            transform='translate(48.94 297.3)'>
            249
          </text>
        </g>
        <g id='_250' data-name={250}>
          <path
            id='_250-2'
            data-name={250}
            className='cls-1 svg_shape'
            d='M193.82,351.94l-100.47-17A278.41,278.41,0,0,0,90,378c0,1.67,0,3.34,0,5h98.82A223,223,0,0,1,193.82,351.94Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_250_text'
            data-name='250_text'
            className='cls-2 text'
            transform='translate(42.94 339.3)'>
            250
          </text>
        </g>
        <g id='_251' data-name={251}>
          <path
            id='_251-2'
            data-name={251}
            className='cls-1 svg_shape'
            d='M188,402.5c0-6.56.31-13.06.87-19.5H90.05a278.44,278.44,0,0,0,3.44,39h95.38C188.31,415.56,188,409.06,188,402.5Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_251_text'
            data-name='251_text'
            className='cls-2 text'
            transform='translate(42.94 377.3)'>
            251
          </text>
        </g>
        <g id='_252' data-name={252}>
          <path
            id='_252-2'
            data-name={252}
            className='cls-1 svg_shape'
            d='M188.87,422H93.49a282.44,282.44,0,0,0,11.73,47L195,458.09A223.07,223.07,0,0,1,188.87,422Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_252_text'
            data-name='252_text'
            className='cls-2 text'
            transform='translate(47.94 418.3)'>
            252
          </text>
        </g>
        <g id='_253' data-name={253}>
          <path
            id='_253-2'
            data-name={253}
            className='cls-1 svg_shape'
            d='M195,458.09,105.22,469a299.69,299.69,0,0,0,21,47.36l81.59-21.71A232.55,232.55,0,0,1,195,458.09Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_253_text'
            data-name='253_text'
            className='cls-2 text'
            transform='translate(57.94 461.3)'>
            253
          </text>
        </g>
        <g
          id='_254'
          data-name={254}
          className='has_listing active_block default_color'>
          <path
            id='_254-2'
            data-name={254}
            className='cls-1 svg_shape'
            d='M207.8,494.65l-81.59,21.71a328,328,0,0,0,28.86,43.7l69.35-34.31A247.3,247.3,0,0,1,207.8,494.65Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_254_text'
            data-name={254}
            className='cls-2 text'
            transform='translate(77.94 501.3)'>
            254
          </text>
        </g>
        <g
          id='_255'
          data-name={255}
          className='has_listing active_block default_color'>
          <path
            id='_255-2'
            data-name={255}
            className='cls-1 svg_shape'
            d='M224.42,525.75l-69.35,34.31a362.77,362.77,0,0,0,36.66,40.28l59.37-39.11A268.4,268.4,0,0,1,224.42,525.75Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_255_text'
            data-name={255}
            className='cls-2 text'
            transform='translate(105.94 537.3)'>
            255
          </text>
        </g>
        <g id='_256' data-name={256}>
          <path
            id='_256-2'
            data-name={256}
            className='cls-1 svg_shape'
            d='M251.1,561.23l-59.37,39.11A402.31,402.31,0,0,0,234,635.24l46.16-45.5A297.27,297.27,0,0,1,251.1,561.23Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_256_text'
            data-name='256_text'
            className='cls-2 text'
            transform='translate(138.94 572.3)'>
            256
          </text>
        </g>
      </g>
      <g id='Lower_Tier'>
        <g
          id='_156'
          data-name={156}
          className='has_listing active_block default_color'>
          <path
            id='_156-2'
            data-name={156}
            className='cls-1 svg_shape'
            d='M336.22,487.78l-37.07,37.07a269.41,269.41,0,0,0,20.94,17.33l31.39-42.42Q343.47,494,336.22,487.78Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_156-3'
            data-name={156}
            className='cls-3 text'
            transform='translate(225.94 488.3)'>
            156
          </text>
        </g>
        <g
          id='_155'
          data-name={155}
          className='has_listing active_block default_color'>
          <path
            id='_155-2'
            data-name={155}
            className='cls-1 svg_shape'
            d='M318.26,470.18l-40.6,32.31a245.47,245.47,0,0,0,21.49,22.36l37.07-37.07A194.34,194.34,0,0,1,318.26,470.18Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_155_text'
            data-name={155}
            className='cls-3 text'
            transform='translate(207.94 470.3)'>
            155
          </text>
        </g>
        <g
          id='_154'
          data-name={154}
          className='has_listing active_block default_color'>
          <path
            id='_154-2'
            data-name={154}
            className='cls-1 svg_shape'
            d='M303.8,451.59l-41.4,30.63a223.77,223.77,0,0,0,15.26,20.27l40.6-32.31A169,169,0,0,1,303.8,451.59Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_154_text'
            data-name={154}
            className='cls-3 text'
            transform='translate(191.94 449.3)'>
            154
          </text>
        </g>
        <g
          id='_153'
          data-name={153}
          className='has_listing active_block default_color'>
          <path
            id='_153-2'
            data-name={153}
            className='cls-1 svg_shape'
            d='M293.45,433.63l-46.12,21.8a204.87,204.87,0,0,0,15.07,26.79l41.4-30.63A150.43,150.43,0,0,1,293.45,433.63Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_153_text'
            data-name={153}
            className='cls-3 text'
            transform='translate(176.94 429.3)'>
            153
          </text>
        </g>
        <g
          id='_152'
          data-name={152}
          className='has_listing active_block default_color'>
          <path
            id='_152-2'
            data-name={152}
            className='cls-1 svg_shape'
            d='M286.3,416l-48.63,14.26a188.33,188.33,0,0,0,9.66,25.21l46.12-21.8A137,137,0,0,1,286.3,416Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_152_text'
            data-name={152}
            className='cls-3 text'
            transform='translate(166.94 407.3)'>
            152
          </text>
        </g>
        <g
          id='_151'
          data-name={151}
          className='has_listing active_block default_color'>
          <path
            id='_151-2'
            data-name={151}
            className='cls-1 svg_shape'
            d='M281.7,397.07l-49.92,6.44a180.45,180.45,0,0,0,5.89,26.71L286.3,416A128.78,128.78,0,0,1,281.7,397.07Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_151_text'
            data-name={151}
            className='cls-3 text'
            transform='translate(159.94 386.3)'>
            151
          </text>
        </g>
        <g id='_150' data-name={150}>
          <path
            id='_150-2'
            data-name={150}
            className='cls-1 svg_shape'
            d='M280,377H230c0,.5,0,1,0,1.5a178.7,178.7,0,0,0,1.78,25l49.92-6.44A127.39,127.39,0,0,1,280,377Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_150_text'
            data-name='150_text'
            className='cls-3 text'
            transform='translate(155.94 363.3)'>
            150
          </text>
        </g>
        <g
          id='_149'
          data-name={149}
          className='has_listing active_block default_color'>
          <path
            id='_149-2'
            data-name={149}
            className='cls-1 svg_shape'
            d='M280,376.5a126.48,126.48,0,0,1,1.56-19.71l-49.22-7A178.26,178.26,0,0,0,230,377h50C280,376.83,280,376.67,280,376.5Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_149_text'
            data-name={149}
            className='cls-3 text'
            transform='translate(155.94 340.3)'>
            149
          </text>
        </g>
        <g
          id='_148'
          data-name={148}
          className='has_listing active_block default_color'>
          <path
            id='_148-2'
            data-name={148}
            className='cls-1 svg_shape'
            d='M285.91,338.29l-47.65-13.37a179.86,179.86,0,0,0-5.92,24.84l49.22,7A130.44,130.44,0,0,1,285.91,338.29Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_148_text'
            data-name={148}
            className='cls-3 text'
            transform='translate(159.94 317.3)'>
            148
          </text>
        </g>
        <g
          id='_147'
          data-name={147}
          className='has_listing active_block default_color'>
          <path
            id='_147-2'
            data-name={147}
            className='cls-1 svg_shape'
            d='M295,316.33l-45.63-18.94a189.63,189.63,0,0,0-11.08,27.53l47.65,13.37A137.28,137.28,0,0,1,295,316.33Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_147_text'
            data-name={147}
            className='cls-3 text'
            transform='translate(166.94 294.3)'>
            147
          </text>
        </g>
        <g id='_146' data-name={146}>
          <path
            id='_146-2'
            data-name={146}
            className='cls-1 svg_shape'
            d='M305.51,298.91l-42.27-25.37a208.16,208.16,0,0,0-13.9,23.85L295,316.33A152.58,152.58,0,0,1,305.51,298.91Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_146_text'
            data-name='146_text'
            className='cls-3 text'
            transform='translate(176.94 271.3)'>
            146
          </text>
        </g>
        <g id='_145' data-name={145}>
          <path
            id='_145-2'
            data-name={145}
            className='cls-1 svg_shape'
            d='M317.88,283.26l-36.43-33.13a225.51,225.51,0,0,0-18.21,23.41l42.27,25.37A172.52,172.52,0,0,1,317.88,283.26Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_145_text'
            data-name='145_text'
            className='cls-3 text'
            transform='translate(192.94 251.3)'>
            145
          </text>
        </g>
        <g id='_144' data-name={144}>
          <path
            id='_144-2'
            data-name={144}
            className='cls-1 svg_shape'
            d='M336.22,265.22l-35-35a247.48,247.48,0,0,0-19.8,19.88l36.43,33.13A194.44,194.44,0,0,1,336.22,265.22Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_144_text'
            data-name='144_text'
            className='cls-3 text'
            transform='translate(209.94 232.3)'>
            144{' '}
          </text>
        </g>
        <g id='_143' data-name={143}>
          <path
            id='_143-2'
            data-name={143}
            className='cls-1 svg_shape'
            d='M354.25,251.29,324.77,211.4a274,274,0,0,0-23.52,18.85l35,35A220.32,220.32,0,0,1,354.25,251.29Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_143_text'
            data-name='143_text'
            className='cls-3 text'
            transform='translate(227.94 214.3)'>
            143
          </text>
        </g>
        <g
          id='_142'
          data-name={142}
          className='has_listing active_block default_color'>
          <path
            id='_142-2'
            data-name={142}
            className='cls-1 svg_shape'
            d='M376.31,237.66,350,195.23a296.48,296.48,0,0,0-25.23,16.17l29.48,39.89A246.48,246.48,0,0,1,376.31,237.66Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_142_text'
            data-name={142}
            className='cls-3 text'
            transform='translate(250.94 197.3)'>
            142
          </text>
        </g>
        <g
          id='_141'
          data-name={141}
          className='has_listing active_block default_color'>
          <path
            id='_141-2'
            data-name={141}
            className='cls-1 svg_shape'
            d='M397.79,227.12,377.1,181.44Q363,187.77,350,195.23l26.31,42.43Q386.63,232,397.79,227.12Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_141_text'
            data-name={141}
            className='cls-3 text'
            transform='translate(274.94 184.3)'>
            141
          </text>
        </g>
        <g id='_140' data-name={140}>
          <path
            id='_140-2'
            data-name={140}
            className='cls-1 svg_shape'
            d='M423.27,217.39l-17.72-47.27q-14.67,5.08-28.45,11.32l20.69,45.68Q410.08,221.75,423.27,217.39Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_140_text'
            data-name='140_text'
            className='cls-3 text'
            transform='translate(300.94 172.3)'>
            140
          </text>
        </g>
        <g
          id='_139'
          data-name={139}
          className='has_listing active_block default_color'>
          <path
            id='_139-2'
            data-name={139}
            className='cls-1 svg_shape'
            d='M449,210.1l-12.89-49c-10.44,2.59-20.64,5.6-30.53,9l17.72,47.27Q435.77,213.25,449,210.1Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_139_text'
            data-name={139}
            className='cls-3 text'
            transform='translate(326.94 161.3)'>
            139
          </text>
        </g>
        <g
          id='_138'
          data-name={138}
          className='has_listing active_block default_color'>
          <path
            id='_138-2'
            data-name={138}
            className='cls-1 svg_shape'
            d='M473.94,205.19l-9.17-50q-14.62,2.43-28.69,5.93l12.89,49Q461.18,207.21,473.94,205.19Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_138_text'
            data-name={138}
            className='cls-3 text'
            transform='translate(354.94 154.3)'>
            138
          </text>
        </g>
        <g id='_137' data-name={137}>
          <path
            id='_137-2'
            data-name={137}
            className='cls-1 svg_shape'
            d='M500.43,202.09l-3.81-50.83q-16.2,1.31-31.85,3.91l9.17,50Q486.95,203.15,500.43,202.09Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_137_text'
            data-name='137_text'
            className='cls-3 text'
            transform='translate(382.94 151.3)'>
            137
          </text>
        </g>
        <g id='_136' data-name={136}>
          <path
            id='_136-2'
            data-name={136}
            className='cls-1 svg_shape'
            d='M528,201V150q-15.9,0-31.38,1.26l3.81,50.83C509.48,201.38,518.68,201,528,201Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_136_text'
            data-name='136_text'
            className='cls-3 text'
            transform='translate(412.94 148.3)'>
            136
          </text>
        </g>
        <g
          id='_135'
          data-name={135}
          className='has_listing active_block default_color'>
          <path
            id='_135-2'
            data-name={135}
            className='cls-1 svg_shape'
            d='M554.83,202,562,151.48c-11.15-1-22.47-1.48-34-1.48v51Q541.61,201,554.83,202Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_135_text'
            data-name={135}
            className='cls-3 text'
            transform='translate(443.94 148.3)'>
            135
          </text>
        </g>
        <g
          id='_134'
          data-name={134}
          className='has_listing active_block default_color'>
          <path
            id='_134-2'
            data-name={134}
            className='cls-1 svg_shape'
            d='M582.49,205.26l10.38-49.82q-15.2-2.58-30.92-4L554.83,202Q568.9,203.1,582.49,205.26Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_134_text'
            data-name={134}
            className='cls-3 text'
            transform='translate(472.94 151.3)'>
            134
          </text>
        </g>
        <g
          id='_133'
          data-name={133}
          className='has_listing active_block default_color'>
          <path
            id='_133-2'
            data-name={133}
            className='cls-1 svg_shape'
            d='M608.26,210.4l14.92-48.49q-14.82-3.82-30.31-6.47l-10.38,49.82Q595.65,207.35,608.26,210.4Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_133_text'
            data-name={133}
            className='cls-3 text'
            transform='translate(501.94 157.3)'>
            133
          </text>
        </g>
        <g
          id='_132'
          data-name={132}
          className='has_listing active_block default_color'>
          <path
            id='_132-2'
            data-name={132}
            className='cls-1 svg_shape'
            d='M634.68,218l19.8-46.47q-15.21-5.48-31.3-9.65L608.26,210.4Q621.83,213.69,634.68,218Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_132_text'
            data-name={132}
            className='cls-3 text'
            transform='translate(529.94 165.3)'>
            132
          </text>
        </g>
        <g id='_131' data-name={131}>
          <path
            id='_131-2'
            data-name={131}
            className='cls-1 svg_shape'
            d='M661,228.34,684,183.8q-14.26-6.74-29.55-12.24L634.68,218Q648.3,222.64,661,228.34Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_131_text'
            data-name='131_text'
            className='cls-3 text'
            transform='translate(558.94 175.3)'>
            131
          </text>
        </g>
        <g id='_130' data-name={130}>
          <path
            id='_130-2'
            data-name={130}
            className='cls-1 svg_shape'
            d='M682.49,239.22,710,197.56Q697.5,190.17,684,183.8L661,228.34Q672.15,233.38,682.49,239.22Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_130_text'
            data-name='130_text'
            className='cls-3 text'
            transform='translate(585.94 184.3)'>
            130
          </text>
        </g>
        <g id='_129' data-name={129}>
          <path
            id='_129-2'
            data-name={129}
            className='cls-1 svg_shape'
            d='M721.91,267.09l34.94-34.94A280.37,280.37,0,0,0,710,197.56l-27.48,41.66A229.35,229.35,0,0,1,721.91,267.09Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_129_text'
            data-name='129_text'
            className='cls-3 text'
            transform='translate(619.94 205.3)'>
            129
          </text>
        </g>
        <g id='_128' data-name={128}>
          <path
            id='_128-2'
            data-name={128}
            className='cls-1 svg_shape'
            d='M738.47,283.66l35.26-34.44q-7.93-8.85-16.88-17.07l-34.94,34.94A191.78,191.78,0,0,1,738.47,283.66Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_128_text'
            data-name='128_text'
            className='cls-3 text'
            transform='translate(647.94 231.3)'>
            128
          </text>
        </g>
        <g id='_127' data-name={127}>
          <path
            id='_127-2'
            data-name={127}
            className='cls-1 svg_shape'
            d='M753.41,303.24l41.73-26.08a224.48,224.48,0,0,0-21.41-27.94l-35.26,34.44A167.37,167.37,0,0,1,753.41,303.24Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_127_text'
            data-name='127_text'
            className='cls-3 text'
            transform='translate(667.94 251.3)'>
            127
          </text>
        </g>
        <g id='_126' data-name={126}>
          <path
            id='_126-2'
            data-name={126}
            className='cls-1 svg_shape'
            d='M763.12,320.54l44.42-21.36a202.9,202.9,0,0,0-12.4-22l-41.73,26.08A150.19,150.19,0,0,1,763.12,320.54Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_126_text'
            data-name='126_text'
            className='cls-3 text'
            transform='translate(680.94 274.3)'>
            126
          </text>
        </g>
        <g id='_125' data-name={125}>
          <path
            id='_125-2'
            data-name={125}
            className='cls-1 svg_shape'
            d='M770.59,339.94,818,325.88a187.49,187.49,0,0,0-10.5-26.7l-44.42,21.36A136.14,136.14,0,0,1,770.59,339.94Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_125_text'
            data-name='125_text'
            className='cls-3 text'
            transform='translate(690.94 295.3)'>
            125
          </text>
        </g>
        <g id='_124' data-name={124}>
          <path
            id='_124-2'
            data-name={124}
            className='cls-1 svg_shape'
            d='M774.59,357.78l49.33-6.26A178.45,178.45,0,0,0,818,325.88l-47.45,14.06A126.36,126.36,0,0,1,774.59,357.78Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_124_text'
            data-name='124_text'
            className='cls-3 text'
            transform='translate(697.94 318.3)'>
            124
          </text>
        </g>
        <g id='_123' data-name={123}>
          <path
            id='_123-2'
            data-name={123}
            className='cls-1 svg_shape'
            d='M776,376.5c0,.17,0,.33,0,.5h50a177.75,177.75,0,0,0-2.06-25.48l-49.33,6.26A125.13,125.13,0,0,1,776,376.5Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_123_text'
            data-name='123_text'
            className='cls-3 text'
            transform='translate(701.94 340.3)'>
            123
          </text>
        </g>
        <g id='_122' data-name={122}>
          <path
            id='_122-2'
            data-name={122}
            className='cls-1 svg_shape'
            d='M774.37,396.62l49.81,7.12A177.49,177.49,0,0,0,826,378.5c0-.5,0-1,0-1.5H776A126.47,126.47,0,0,1,774.37,396.62Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_122_text'
            data-name='122_text'
            className='cls-3 text'
            transform='translate(699.94 363.3)'>
            122
          </text>
        </g>
        <g id='_121' data-name={121}>
          <path
            id='_121-2'
            data-name={121}
            className='cls-1 svg_shape'
            d='M774.37,396.62a129.11,129.11,0,0,1-3.5,15.56L819.27,427a180.61,180.61,0,0,0,4.91-23.21Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_121_text'
            data-name='121_text'
            className='cls-3 text'
            transform='translate(696.94 384.3)'>
            121
          </text>
        </g>
        <g id='_120' data-name={120}>
          <path
            id='_120-2'
            data-name={120}
            className='cls-1 svg_shape'
            d='M764.1,430.32l46.88,20A187.73,187.73,0,0,0,819.27,427l-48.4-14.77A137.21,137.21,0,0,1,764.1,430.32Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_120_text'
            data-name='120_text'
            className='cls-3 text'
            transform='translate(690.94 403.3)'>
            120
          </text>
        </g>
        <g id='_119' data-name={119}>
          <path
            id='_119-2'
            data-name={119}
            className='cls-1 svg_shape'
            d='M751.46,453l46.67,22.28A199.27,199.27,0,0,0,811,450L763.86,430.5A149.1,149.1,0,0,1,751.46,453Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_119_text'
            data-name='119_text'
            className='cls-3 text'
            transform='translate(681.64 427.65)'>
            119
          </text>
        </g>
        <g id='_118' data-name={118}>
          <path
            id='_118-2'
            data-name={118}
            className='cls-1 svg_shape'
            d='M740.38,467.16,780,500.46a219,219,0,0,0,17.86-25L751.46,452.7A168.65,168.65,0,0,1,740.38,467.16Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_118_text'
            data-name='118_text'
            className='cls-3 text'
            transform='translate(667.64 449.65)'>
            118
          </text>
        </g>
        <g id='_117' data-name={117}>
          <path
            id='_117-2'
            data-name={117}
            className='cls-1 svg_shape'
            d='M718.8,488.61l38.47,35.85a243.15,243.15,0,0,0,22.76-24l-39.65-33.3A191.86,191.86,0,0,1,718.8,488.61Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_117_text'
            data-name='117_text'
            className='cls-3 text'
            transform='translate(648.64 470.65)'>
            117
          </text>
        </g>
        <g id='_116' data-name={116}>
          <path
            id='_116-2'
            data-name={116}
            className='cls-1 svg_shape'
            d='M699.77,503.08,730.68,546a271.39,271.39,0,0,0,26.59-21.53L718.8,488.61A222.17,222.17,0,0,1,699.77,503.08Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_116_text'
            data-name='116_text'
            className='cls-3 text'
            transform='translate(627.64 491.65)'>
            116
          </text>
        </g>
        <g id='_114' data-name={114}>
          <path
            id='_114-2'
            data-name={114}
            className='cls-1 svg_shape'
            d='M660.48,524.88l24.38,47.92a309.89,309.89,0,0,0,27.36-14.69l-27.91-45.36Q672.93,519.29,660.48,524.88Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_114_text'
            data-name='114_text'
            className='cls-3 text'
            transform='translate(585.34 517.85)'>
            114
          </text>
        </g>
        <g id='_113' data-name={113}>
          <path
            id='_113-2'
            data-name={113}
            className='cls-1 svg_shape'
            d='M636.33,534.41,656,584.89q14.9-5.45,28.85-12.09l-24.38-47.92Q648.83,530.1,636.33,534.41Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_113_text'
            data-name='113_text'
            className='cls-3 text'
            transform='translate(559.34 529.85)'>
            113
          </text>
        </g>
        <g id='_112' data-name={112}>
          <path
            id='_112-2'
            data-name={112}
            className='cls-1 svg_shape'
            d='M608.77,542.47,625,594.62q16-4.2,31.06-9.73l-19.68-50.48Q623,539,608.77,542.47Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_112_text'
            data-name='112_text'
            className='cls-3 text'
            transform='translate(532.34 540.85)'>
            112
          </text>
        </g>
        <g
          id='_111'
          data-name={111}
          className='has_listing active_block default_color'>
          <path
            id='_111-2'
            data-name={111}
            className='cls-1 svg_shape'
            d='M580.63,548l11.26,53.69q16.9-2.84,33.06-7.1l-16.18-52.15Q595,545.82,580.63,548Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_111_text'
            data-name={111}
            className='cls-3 text'
            transform='translate(502.34 550.85)'>
            111
          </text>
        </g>
        <g
          id='_110'
          data-name={110}
          className='has_listing active_block default_color'>
          <path
            id='_110-2'
            data-name={110}
            className='cls-1 svg_shape'
            d='M558.22,550.7l3.28,54.85q15.45-1.32,30.39-3.83L580.63,548Q569.61,549.71,558.22,550.7Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_110_text'
            data-name={110}
            className='cls-3 text'
            transform='translate(473.34 555.85)'>
            110
          </text>
        </g>
        <g
          id='_109'
          data-name={109}
          className='has_listing active_block default_color'>
          <path
            id='_109-2'
            data-name={109}
            className='cls-1 svg_shape'
            d='M528,552v55q17,0,33.5-1.45l-3.28-54.85Q543.35,552,528,552Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_109_text'
            data-name={109}
            className='cls-3 text'
            transform='translate(443.94 557.3)'>
            109
          </text>
        </g>
        <g
          id='_108'
          data-name={108}
          className='has_listing active_block default_color'>
          <path
            id='_108-2'
            data-name={108}
            className='cls-1 svg_shape'
            d='M514,551.72v55q7,.25,14,.26V552Q520.95,552,514,551.72Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_108_text'
            data-name={108}
            className='cls-3 text'
            transform='translate(436.84 562.11) rotate(-89.98)'>
            108
          </text>
        </g>
        <g id='_107' data-name={107}>
          <path
            id='_107-2'
            data-name={107}
            className='cls-1 svg_shape'
            d='M500.68,550.94l-3.54,54.85q8.37.66,16.86,1v-55Q507.29,551.45,500.68,550.94Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_107_text'
            data-name='107_text'
            className='cls-3 text'
            transform='matrix(0.06, -1, 1, 0.06, 421.22, 561.94)'>
            107
          </text>
        </g>
        <g id='_106' data-name={106}>
          <path
            id='_106-2'
            data-name={106}
            className='cls-1 svg_shape'
            d='M474.84,547.94l-12.53,53.48q17.07,2.94,34.83,4.37l3.54-54.85Q487.54,549.92,474.84,547.94Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_106_text'
            data-name='106_text'
            className='cls-2 text'
            transform='translate(382.94 553.3)'>
            106
          </text>
        </g>
        <g
          id='_105'
          data-name={105}
          className='has_listing active_block default_color'>
          <path
            id='_105-2'
            data-name={105}
            className='cls-1 svg_shape'
            d='M449.63,543.05l-17.58,51.84q14.79,3.86,30.26,6.53l12.53-53.48Q462,546,449.63,543.05Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_105_text'
            data-name={105}
            className='cls-2 text'
            transform='translate(352.94 547.3)'>
            105
          </text>
        </g>
        <g id='_104' data-name={104}>
          <path
            id='_104-2'
            data-name={104}
            className='cls-1 svg_shape'
            d='M420.21,534.6l-19.68,50.48q15.3,5.56,31.52,9.81l17.58-51.84C439.52,540.67,429.69,537.84,420.21,534.6Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_104_text'
            data-name='104_text'
            className='cls-2 text'
            transform='translate(324.94 539.3)'>
            104
          </text>
        </g>
        <g
          id='_103'
          data-name={103}
          className='has_listing active_block default_color'>
          <path
            id='_103-2'
            data-name={103}
            className='cls-1 svg_shape'
            d='M394.73,524.53l-24,48.06q14.4,6.87,29.83,12.49l19.68-50.48Q407,530.09,394.73,524.53Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_103_text'
            data-name={103}
            className='cls-2 text'
            transform='translate(294.94 529.3)'>
            103
          </text>
        </g>
        <g id='_102' data-name={102}>
          <path
            id='_102-2'
            data-name={102}
            className='cls-1 svg_shape'
            d='M371.5,512.64l-28.09,45.25q13.08,7.92,27.29,14.7l24-48.06Q382.63,519.06,371.5,512.64Z'
            transform='translate(-89 -30)'
          />
          <text
            id='_102_text'
            data-name='102_text'
            className='cls-2 text'
            transform='translate(267.94 518.3)'>
            102
          </text>
        </g>
      </g>
      <g id='Front_Floor'>
        <g
          id='_0East'
          data-name='Front Left'
          className='has_listing active_block default_color'>
          <path
            id='_0East-2'
            data-name='Front Left'
            className='cls-1 svg_shape'
            d='M709,316.65C677.76,258.85,601.11,218,511.5,218q-7.81,0-15.5.42V377.5H709Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0East_text' data-name='Front Left'>
            <text
              className='cls-4 text'
              transform='translate(483.35 278.93)'
              data-name='Front Left'>
              East
            </text>
          </g>
        </g>
        <g
          id='_0West'
          data-name='Front Right'
          className='has_listing active_block default_color'>
          <path
            id='_0West-2'
            data-name='Front Right'
            className='cls-1 svg_shape'
            d='M496,377.5V530.58q7.68.42,15.5.42c89.61,0,166.26-40.85,197.5-98.65V377.5Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_0West_text' data-name='Front Right'>
            <text
              className='cls-4 text'
              transform='translate(477.35 421.93)'
              data-name='Front Right'>
              West
            </text>
          </g>
        </g>
      </g>
      <g id='Rear_Floor'>
        <g
          id='_0RearFloorStanding'
          data-name='Back'
          className='has_listing active_block default_color'>
          <path
            id='_0RearFloorStanding-2'
            data-name='Back'
            className='cls-1 svg_shape'
            d='M299,374.5c0,82.59,86.88,150.24,197,156.08V218.42C385.88,224.26,299,291.91,299,374.5Z'
            transform='translate(-89 -30)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <text
            id='_0RearFloorStanding_text'
            data-name='Back'
            className='cls-4 text'
            transform='translate(231.04 338.77)'>
            Rear Floor{' '}
            <tspan x='8.46' y='22.8' className='text' data-name='Back'>
              Standing
            </tspan>
          </text>
        </g>
      </g>
      <g id='Stage'>
        <polygon
          id='_0Stage'
          data-name='0Stage'
          className='cls-5 svg_shape'
          points='620 265 620 328 341 328 341 364 620 364 620 425 656 425 656 265 620 265'
        />
        <text
          id='_0Stage_text'
          data-name='0Stage_text'
          className='cls-6 text'
          transform='translate(631.37 306.39) rotate(90)'>
          Stage
        </text>
      </g>
    </svg>
  );
};
export default SecondStadium;
