import * as S from './styles';
import Dropdown from 'react-dropdown';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const ConfirmTicket = ({ data, close }) => {
  const [numberTickets, setNumberTickets] = useState({ label: 1, value: 1 });
  const [numbers, setNumbers] = useState([]);
  const history = useHistory();
  useEffect(() => {
    setNumbers(Array.from({ length: data.tickets_left }, (_, i) => i + 1));
  }, []);

  const checkout = () => {
    sessionStorage.setItem(
      'data',
      JSON.stringify({ ...data, nr_tickets: numberTickets.value })
    );
    history.push(
      `/checkout/8127dhasmdaiJAOSQ98312?date=${data?.date.toString()}&ct=${
        data?.country
      }&id=${data.stadiumName}/?id=8127dhasmdaiJAOSQ98312?order_id=736327`
    );
  };

  return (
    <S.ConfirmTicket>
      <img src='/images/close.png' className='close' onClick={close} />
      <h1>Ticket Details</h1>
      <p className='e_ticket'>E-Ticket</p>
      <div className='number'>
        <p>Number Of Tickets</p>
        <Dropdown
          options={numbers.map((elem) => ({ label: elem, value: elem }))}
          onChange={(o) => setNumberTickets(o)}
          defaultValue={numberTickets}
          value={numberTickets}
          placeholder='Select'
        />
      </div>
      <p className='data'>
        Category:{' '}
        <span>
          {data?.zone.toString().includes('Front')
            ? `Front Floor`
            : data?.zone.toString().includes('Back')
            ? 'Back Floor'
            : data.zone >= 400
            ? 'Upper Tier'
            : 'Lower Tier'}
        </span>
      </p>
      <p className='data'>
        Block: <span>{data?.zone} Standing</span>
      </p>
      <div className='price'>
        <h4>Ticket Price:</h4>
        <p>€{data?.price}</p>
      </div>
      <div className='additional'>
        <h5>Additional Information</h5>
        <div>
          <img src='/images/guarantee_sheild.svg' />
          <p>
            TicketMasters Guarantee - Get your money back if the event is
            canceled and not rescheduled.
          </p>
        </div>
        <div>
          <img src='/images/guarantee_sheild.svg' />
          <p>
            You’ll be contacted by Customer Support with details on how to
            receive your Mobile Ticket
          </p>
        </div>
        <button onClick={() => checkout()}>Continue To Checkout</button>
      </div>
    </S.ConfirmTicket>
  );
};

export default ConfirmTicket;
