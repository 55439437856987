export const ticketsUk2 = [
  {
    zone: 'Front Right',
    row: '',
    tickets_left: 6,
    price: 99.98,
  },
  {
    zone: 'Front Left',
    row: '',
    tickets_left: 5,
    price: 109.98,
  },
  {
    zone: 'Back',
    row: '',
    tickets_left: 11,
    price: 99.98,
  },
  {
    zone: 132,
    row: 14,
    tickets_left: 5,
    price: 101.99,
  },
  {
    zone: 133,
    row: 5,
    tickets_left: 1,
    price: 89,
  },
  {
    zone: 134,
    row: 1,
    tickets_left: 4,
    price: 119.99,
  },
  {
    zone: 135,
    row: 1,
    tickets_left: 11,
    price: 89,
  },

  {
    zone: 138,
    row: 24,
    tickets_left: 2,
    price: 98.99,
  },
  {
    zone: 139,
    row: 13,
    tickets_left: 16,
    price: 117.99,
  },
  {
    zone: 141,
    row: 4,
    tickets_left: 4,
    price: 79,
  },
  {
    zone: 142,
    row: 19,
    tickets_left: 10,
    price: 89,
  },
  {
    zone: 147,
    row: 24,
    tickets_left: 23,
    price: 87.99,
  },
  {
    zone: 148,
    row: 11,
    tickets_left: 6,
    price: 98.99,
  },
  {
    zone: 149,
    row: 3,
    tickets_left: 1,
    price: 98.99,
  },
  {
    zone: 151,
    row: 8,
    tickets_left: 2,
    price: 99.99,
  },
  {
    zone: 152,
    row: '',
    tickets_left: 1,
    price: 89.99,
  },
  {
    zone: 153,
    row: '',
    tickets_left: 1,
    price: 109.99,
  },
  {
    zone: 154,
    row: '',
    tickets_left: 1,
    price: 89.99,
  },
  {
    zone: 155,
    row: '',
    tickets_left: 1,
    price: 101.99,
  },
  {
    zone: 156,
    row: 4,
    tickets_left: 5,
    price: 89.99,
  },

  {
    zone: 103,
    row: '',
    tickets_left: 2,
    price: 119.99,
  },
  {
    zone: 105,
    row: 14,
    tickets_left: 5,
    price: 101.99,
  },
  {
    zone: 108,
    row: 5,
    tickets_left: 1,
    price: 89,
  },
  {
    zone: 109,
    row: 1,
    tickets_left: 4,
    price: 119.99,
  },
  {
    zone: 110,
    row: 1,
    tickets_left: 11,
    price: 89,
  },

  {
    zone: 111,
    row: 24,
    tickets_left: 2,
    price: 98.99,
  },
  {
    zone: 201,
    row: 13,
    tickets_left: 16,
    price: 77.99,
  },
  {
    zone: 255,
    row: 4,
    tickets_left: 4,
    price: 79,
  },
  {
    zone: 254,
    row: 19,
    tickets_left: 10,
    price: 89,
  },
  {
    zone: 247,
    row: 24,
    tickets_left: 23,
    price: 87.99,
  },
  {
    zone: 246,
    row: 11,
    tickets_left: 6,
    price: 98.99,
  },
  {
    zone: 245,
    row: 3,
    tickets_left: 1,
    price: 98.99,
  },
  {
    zone: 244,
    row: 8,
    tickets_left: 2,
    price: 99.99,
  },
];
