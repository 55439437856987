export const mantickets = [
  {
    zone: 'East',
    row: 'Standing',
    tickets_left: 14,
    price: 79.98,
  },
  {
    zone: 'West',
    row: 'Standing',
    tickets_left: 5,
    price: 79.98,
  },
  {
    zone: '102',
    row: 4,
    tickets_left: 6,
    price: 88.98,
  },
  {
    zone: '105',
    row: 7,
    tickets_left: 5,
    price: 98.99,
  },
  {
    zone: '107',
    row: 5,
    tickets_left: 4,
    price: 98.98,
  },
  {
    zone: '115',
    row: 3,
    tickets_left: 8,
    price: 89.98,
  },
  {
    zone: '314',
    row: 7,
    tickets_left: 3,
    price: 109.98,
  },
  {
    zone: '313',
    row: 14,
    tickets_left: 2,
    price: 98.98,
  },
  {
    zone: '318',
    row: 9,
    tickets_left: 5,
    price: 69.98,
  },
  {
    zone: '309',
    row: 7,
    tickets_left: 4,
    price: 88.98,
  },
  {
    zone: '127',
    row: 3,
    tickets_left: 3,
    price: 119.98,
  },
  {
    zone: '109',
    row: 9,
    tickets_left: 2,
    price: 119.98,
  },
  {
    zone: '111',
    row: 6,
    tickets_left: 3,
    price: 88.98,
  },
  {
    zone: '304',
    row: 2,
    tickets_left: 3,
    price: 119.98,
  },
  {
    zone: '307',
    row: 4,
    tickets_left: 2,
    price: 119.98,
  },
  {
    zone: '118',
    row: 11,
    tickets_left: 2,
    price: 99.98,
  },
  {
    zone: '119',
    row: 1,
    tickets_left: 1,
    price: 119.98,
  },
  {
    zone: '328',
    row: 4,
    tickets_left: 2,
    price: 79.98,
  },
  {
    zone: '228',
    row: 2,
    tickets_left: 4,
    price: 139.98,
  },
  {
    zone: '326',
    row: 7,
    tickets_left: 1,
    price: 79.98,
  },
  {
    zone: '324',
    row: 8,
    tickets_left: 4,
    price: 89.98,
  },
  {
    zone: '323',
    row: 11,
    tickets_left: 2,
    price: 79.98,
  },
];
