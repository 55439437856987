import styled from 'styled-components';

export const ConfirmTicket = styled.div`
  .close {
    position: absolute;
    top: 20px;
    right: 16px;
    max-width: 15px;
    cursor: pointer;
  }
  & > h1 {
    color: #1565c0;
    font-size: 24px;
    padding: 16px 15px 0px 15px;
    font-weight: 500;
  }
  .e_ticket {
    color: #1565c0;
    padding: 0px 15px 8px 15px;
  }
  .number {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 15px;
    p {
      color: #212121;
      font-size: 16px;
      font-weight: 400;
      width: 160px;
    }
    & > div {
      width: calc(100% - 176px);
      max-width: 130px;
    }
  }
  .data {
    padding: 6px 15px;
    font-size: 18px;
    span {
      font-weight: 500;
      font-size: 20px;
      color: #1565c0;
    }
  }
  .price {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    h4 {
      font-size: 18px;
      font-weight: 500;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      color: #1565c0;
    }
  }
  .additional {
    padding: 0 15px 15px;
    margin-bottom: 100px;
    h5 {
      font-size: 16px;
      font-weight: 600;
      color: #666;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
      p {
        font-size: 12px;
      }
    }
  }
  button {
    width: calc(100% - 16px);
    height: 45px;
    background: #1565c0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
    outline: none;
    position: absolute;
    bottom: 6px;
    left: 8px;
    font-size: 17px;
    transition: 0.3s ease-in-out;
    font-weight: 600;
    &:hover {
      background: #0f498c;
      cursor: pointer;
    }
  }
`;
