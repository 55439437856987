import styled from 'styled-components';

export const TopBanners = styled.div`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 1px solid #c2c2c2;
  & > p {
    width: 100%;
    text-align: center;
    background: rgb(226, 25, 55);
    padding: 8px 30px;
    @media (max-width: 1000px) {
      font-size: 14px;
    }
    @media (max-width: 460px) {
      font-size: 12px;
      padding: 8px 10px;
    }
  }
  & > div {
    background: white;
    color: #212121;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    h1 {
      margin-bottom: 8px;
      @media (max-width: 1000px) {
        line-height: 30px;
        font-size: 28px;
        text-align: center;
      }
    }
    p {
      font-size: 18px;
      @media (max-width: 1000px) {
        font-size: 16px;
        text-align: center;
        margin-bottom: 12px;
      }
    }
    & > p {
      display: flex;
      align-items: flex-start;
      @media (max-width: 1000px) {
        display: block;
        br {
          display: none;
        }
      }
      span {
        margin-right: 5px;
        @media (max-width: 1000px) {
          margin-right: 2px;
        }
      }
      img {
        margin-right: 8px;
        @media (max-width: 1000px) {
          margin-bottom: -5px;
        }
      }
    }
  }
`;
