import * as S from './styles';
import { concerts } from '../../../concerts';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
const TopBanners = () => {
  const [place, setPlace] = useState({});
  const location = useLocation();
  useEffect(() => {
    var params = new URLSearchParams(location.search);
    for (let p of params) {
      if (p[0] === 'date') {
        setPlace(concerts.filter((elem) => elem.date === p[1])[0]);
      }
    }
  }, []);

  return (
    <S.TopBanners>
      <p> Resale Tickets! | Prices do not include booking fees, VAT, and delivery fees</p>
      <div>
        <div>
          <h1>The Weeknd Resale Tickets</h1>
          <p>
            {moment(`${place?.date}, 2024, 20:00`).format('LLLL')} at{' '}
            {place?.stadiumName}, {place?.country}, {place?.city}
          </p>
        </div>
        <p>
          <img src='/images/guarantee_sheild.svg' />
          <span>100%</span>BUYER GUARANTEE <br />
          FOR EVERY ORDER
        </p>
      </div>
    </S.TopBanners>
  );
};

export default TopBanners;
