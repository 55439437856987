import styled from 'styled-components';

export const FormPayment = styled.form`
  max-width: 100%;
  margin: 32px auto;
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 32px auto;
  }
  #submit {
    width: 100%;
    margin: 10px 0;
    height: 36px;
    border: none;
    outline: none;
    height: 45px;
    background: #1565c0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 17px;
    transition: 0.3s ease-in-out;
    font-weight: 500;
    &:hover {
      background: #0f498c;
      cursor: pointer;
    }
  }
`;

export const Checkout = styled.div`
  background: #f7f7f7;
  color: #212121;
  font-family: 'Montserrat', sans-serif;
  max-width: 1200px;
  margin: 0 auto 64px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .promo {
    gap: 16px;
    button {
      height: 37px !important;
      border: none !important;
      outline: none !important;
      background: #1565c0 !important;
      color: white !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      border-radius: 4px !important;
      font-size: 17px !important;
      transition: 0.3s ease-in-out !important;
      font-weight: 500 !important;
      box-sizing: border-box;
      padding: 0 8px !important;
      text-decoration: none !important;
      &:hover {
        background: #0f498c !important;
        cursor: pointer !important;
      }
    }
    input {
      height: 37px;
      font-size: 16px;
      font-weight: 400;
      padding: 0 12px;
      border: 1px solid #ccc;
      color: #333;
      width: 100%;
      &:placeholder-shown {
        font-weight: 400;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .logo {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 768px) {
      justify-content: center;
    }
    img {
      max-width: 200px;
    }
    h5 {
      font-size: 22px;
      letter-spacing: 0.6px;
      color: #6e6e6e;
      font-weight: 500;
    }
  }
  .blocks {
    display: flex;
    gap: 32px;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
`;

export const Container = styled.div`
  padding: 15px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 65%;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
  & > h1 {
    color: #1565c0;
    font-weight: 600;
    margin-bottom: 4px;
  }
  & > p {
    font-size: 20px;
    margin: 0;
    color: #022d5f;
    padding-bottom: 16px;
    border-bottom: 1px solid #f1f1f1;
  }
  .number_header {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 19px;
    color: #022d5f;
    margin-bottom: 16px;
    span {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid #022d5f;
      color: #022d5f;
      font-weight: 600;
      font-size: 20px;
      margin-right: 8px;
    }
  }
`;

export const Form = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  & > div {
    width: calc(50% - 9px);
    @media (max-width: 768px) {
      width: 100%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      label {
        margin-bottom: 4px;
      }
      input {
        height: 37px;
        font-size: 16px;
        font-weight: 400;
        padding: 0 12px;
        border: 1px solid #ccc;
        color: #333;
        &:placeholder-shown {
          font-weight: 400;
          font-size: 16px;
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
    .double {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      & > div {
        width: 48%;
        label {
          margin-bottom: 4px;
        }
        input {
          width: 100%;
          margin-top: 4px;
        }
      }
    }
  }
`;

export const DeliveryMethod = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const Payment = styled.div``;

export const Ticket = styled.div`
  width: 32%;
  height: fit-content;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    box-sizing: border-box;
  }
  .summary {
    display: flex;
    p {
      width: 50%;
      background: #022d5f;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-weight: 500;
      font-size: 20px;
    }
    img {
      width: 50%;
      height: 100px;
      object-fit: cover;
      object-position: top;
    }
  }
  .additional {
    margin: 20px 0;
    p:first-child {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 6px;
    }
    p:last-child {
      font-weight: 400;
      font-size: 12px;
    }
  }
  .guarantees {
    & > div {
      display: flex;
      gap: 8px;
      margin-bottom: 10px;
      p {
        font-size: 14px;
      }
    }
  }
  .content {
    padding: 15px;
    & > h4:first-child {
      font-size: 16px;
      font-weight: 500;
      color: #1c7be6;
      padding-bottom: 10px;
    }
    .total {
      border-bottom: 1px solid #e7e7e7;
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > p:first-child {
        color: #022d5f;
        font-size: 20px;
        font-weight: 500;
      }
      & > p:last-child {
        color: #212121;
        font-size: 18px;
        font-weight: 500;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .prices {
      border-bottom: 1px solid #e7e7e7;
      padding-top: 16px;
      padding-bottom: 16px;
      button {
        background: transparent;
        outline: none;
        border: none;
        color: #022d5f;
        font-size: 14px;
        padding: 0;
        margin: 8px 0;
        text-decoration: underline;
        cursor: pointer;
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > p:first-child {
          color: #022d5f;
          font-size: 18px;
          font-weight: 400;
        }
        & > p:last-child {
          color: #212121;
          font-size: 16px;
          font-weight: 400;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    .category {
      border-bottom: 1px solid #e7e7e7;
      padding-top: 10px;
      border-top: 1px solid #e7e7e7;
      padding-bottom: 10px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > p:first-child {
          color: #022d5f;
          font-size: 18px;
          font-weight: 400;
        }
        & > p:last-child {
          color: #212121;
          font-size: 16px;
          font-weight: 400;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
