import LondonStadium from './londonStadiun';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { concerts } from '../../../concerts';
import SecondStadium from './stadium2';
import NorwayStadium from './norwaystadium';
import Manchester from './manchester';
import Hamburg from './hamburg';
const Stadium = ({ isLondon }) => {
  const location = useLocation();
  const [place, setPlace] = useState({});
  useEffect(() => {
    var params = new URLSearchParams(location.search);
    for (let p of params) {
      if (p[0] === 'date') {
        setPlace(concerts.filter((elem) => elem.date === p[1])[0]);
      }
    }
  }, []);

  return (
    <S.Stadium
      className='event_bottom_right'
      isLondon={
        (place.stadiumName &&
          place.stadiumName.toLowerCase().includes('london')) ||
        (place.location && place.location.toLowerCase().includes('dublin')) ||
        (place.location &&
          place.location.toLowerCase().includes('manchester')) ||
        (place.location && place.location.toLowerCase().includes('hamburg'))
      }>
      <div id='sidebar' className='col_width2 sticky '>
        <div className='seat_map_section' id='seat-fix'>
          <input type='hidden' id='svg_blocks_color' defaultValue='#2c69bb' />
          {place.stadiumName &&
          place.stadiumName.toLowerCase().includes('london') &&
          place?.date.includes('7') ? (
            <LondonStadium />
          ) : place.stadiumName &&
            place.stadiumName.toLowerCase().includes('london') &&
            place?.date.includes('8') ? (
            <SecondStadium />
          ) : place.location &&
            place.location.toLowerCase().includes('dublin') &&
            place?.date.includes('28') ? (
            <NorwayStadium />
          ) : place.location &&
            place.location.toLowerCase().includes('hamburg') &&
            place?.date.includes('2') ? (
            <Hamburg />
          ) : place.location &&
            place.location.toLowerCase().includes('manchester') &&
            place?.date.includes('10') ? (
            <Manchester />
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 1168.39 842.73'>
              <defs>
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      '.cls-1{fill:#999;stroke:#fff;stroke-miterlimit:10;}.cls-2{font-size:14px;}.cls-2,.cls-3,.cls-4{fill:#fff;}.cls-2,.cls-3{font-family:Helvetica-Light, Helvetica;font-weight:300;}.cls-3{font-size:25px;}.cls-5{fill:#464646;}',
                  }}
                />
              </defs>
              <g id='Lower_Tier'>
                <g id='_12' data-name={12} className='grey_out'>
                  <path
                    id='_12-2'
                    data-name={12}
                    className='cls-1 svg_shape'
                    d='M334.76,647.9V623H301.41v15a157.58,157.58,0,0,0,10.43,56.54l27.83-13.1A116.13,116.13,0,0,1,334.76,647.9Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_12'
                    data-name='12'
                    className='cls-2 text'
                    transform='translate(197.05 517.91)'>
                    12
                  </text>
                </g>
                <g id='_16' data-name={16} className='grey_out'>
                  <path
                    id='_16-2'
                    data-name={16}
                    className='cls-1 svg_shape'
                    d='M334.76,471.1a116.44,116.44,0,0,1,3.09-26.72L310.16,429.1a157.83,157.83,0,0,0-8.75,51.95V496h33.35Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_16'
                    data-name='16'
                    className='cls-2 text'
                    transform='translate(198.05 332.86)'>
                    16
                  </text>
                </g>
                <g
                  id='_112'
                  data-name={112}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_112-2'
                    data-name={112}
                    className='cls-1 svg_shape'
                    d='M289.57,789.22l36.94-38.89V722.82a155.9,155.9,0,0,1-14.13-28.53l-63.46,29.77A258.41,258.41,0,0,0,289.57,789.22Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_112'
                    data-name='112'
                    className='cls-3 text'
                    transform='translate(158.21 605.17)'>
                    112
                  </text>
                </g>
                <g
                  id='_113'
                  data-name={113}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_113-2'
                    data-name={113}
                    className='cls-1 svg_shape'
                    d='M248.92,724.06l63.46-29.77a155.62,155.62,0,0,1-9.87-54.72V623H227.78A257.3,257.3,0,0,0,248.92,724.06Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_113'
                    data-name='113'
                    className='cls-3 text'
                    transform='translate(137.65 535.55)'>
                    113
                  </text>
                </g>
                <g
                  id='_114'
                  data-name={114}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_114-2'
                    data-name={114}
                    className='cls-1 svg_shape'
                    d='M227.76,504.29V621.54c0,.49,0,1,0,1.46h74.73V496h-74.6C227.82,498.75,227.76,501.52,227.76,504.29Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_114'
                    data-name='114'
                    className='cls-3 text'
                    transform='translate(132.29 429.91)'>
                    114
                  </text>
                </g>
                <g
                  id='_115'
                  data-name={115}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_115-2'
                    data-name={115}
                    className='cls-1 svg_shape'
                    d='M227.91,496h74.6V479.43a156.28,156.28,0,0,1,8.16-50l-59.38-33A257.17,257.17,0,0,0,227.91,496Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_115'
                    data-name='115'
                    className='cls-3 text'
                    transform='translate(135.63 324.16)'>
                    115
                  </text>
                </g>
                <g
                  id='_116'
                  data-name={116}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_116-2'
                    data-name={116}
                    className='cls-1 svg_shape'
                    d='M288.4,338a258.57,258.57,0,0,0-37.11,58.49l59.38,33a156,156,0,0,1,16.4-34.18l-.39-22.76Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_116'
                    data-name='116'
                    className='cls-3 text'
                    transform='translate(157.84 253.8)'>
                    116
                  </text>
                </g>
                <g
                  id='_125'
                  data-name={125}
                  className='has_listing active_block default_color'>
                  <path
                    id='_125-2'
                    data-name={125}
                    className='cls-1 svg_shape'
                    d='M1117.07,337.91l-43.89,36.42V392a155.63,155.63,0,0,1,17.88,35.54l64.81-27.49A258.6,258.6,0,0,0,1117.07,337.91Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_125'
                    data-name='125'
                    className='cls-3 text'
                    transform='translate(976.25 253.49)'>
                    125
                  </text>
                </g>
                <g
                  id='_126'
                  data-name={126}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_126-2'
                    data-name={126}
                    className='cls-1 svg_shape'
                    d='M1155.87,400.08l-64.81,27.49a156.21,156.21,0,0,1,8.81,51.86V496.5h77.77A257.25,257.25,0,0,0,1155.87,400.08Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_126'
                    data-name='126'
                    className='cls-3 text'
                    transform='translate(999.25 324.16)'>
                    126
                  </text>
                </g>
                <g
                  id='_127'
                  data-name={127}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_127-2'
                    data-name={127}
                    className='cls-1 svg_shape'
                    d='M1177.76,621.54V504.29c0-2.61,0-5.2-.12-7.79h-77.77V623.75h77.87C1177.74,623,1177.76,622.28,1177.76,621.54Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_127'
                    data-name='127'
                    className='cls-3 text'
                    transform='translate(1006.58 429.91)'>
                    127
                  </text>
                </g>
                <g
                  id='_128'
                  data-name={128}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_128-2'
                    data-name={128}
                    className='cls-1 svg_shape'
                    d='M1099.87,623.75v15.82a156.11,156.11,0,0,1-9,52.29l65.47,32.74a257.63,257.63,0,0,0,21.37-100.85Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_128'
                    data-name='128'
                    className='cls-3 text'
                    transform='translate(999.25 533.34)'>
                    128
                  </text>
                </g>
                <g
                  id='_129'
                  data-name={129}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_129-2'
                    data-name={129}
                    className='cls-1 svg_shape'
                    d='M1090.9,691.86a155.58,155.58,0,0,1-14.72,30.48V753.5l38.13,37.65a258.91,258.91,0,0,0,42.06-66.55Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_129'
                    data-name='129'
                    className='cls-3 text'
                    transform='translate(973.25 605.67)'>
                    129
                  </text>
                </g>
                <g id='_2' data-name={2} className='active_block'>
                  <path
                    id='_2-2'
                    data-name={2}
                    className='cls-1 svg_shape'
                    d='M955.06,763.87,959.94,795c23.56-2.69,29.4-1.59,48.65-13.48l-15.84-27.7C982.5,757.23,966.37,763.43,955.06,763.87Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_2'
                    data-name='2'
                    className='cls-2 text'
                    transform='translate(859.66 640)'>
                    2
                  </text>
                </g>
                <g id='_3' data-name={3} className='active_block'>
                  <path
                    id='_3-2'
                    data-name={3}
                    className='cls-1 svg_shape'
                    d='M950.45,764H856.18v32h85.64a159.45,159.45,0,0,0,18.12-1l-4.88-31.09C953.53,763.93,952,764,950.45,764Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_3'
                    data-name='3'
                    className='cls-2 text'
                    transform='translate(792.26 643)'>
                    3
                  </text>
                </g>
                <g id='_4' data-name={4}>
                  <rect
                    id='_4-2'
                    data-name={4}
                    className='cls-1 svg_shape'
                    x='673.17'
                    y='623.14'
                    width='70.83'
                    height='32.03'
                  />
                  <text
                    id='_4'
                    data-name='4'
                    className='cls-2 text'
                    transform='translate(704.9 642.52)'>
                    4
                  </text>
                </g>
                <g id='_5' data-name={5}>
                  <rect
                    id='_5-2'
                    data-name={5}
                    className='cls-1 svg_shape'
                    x='591.08'
                    y='623.14'
                    width='82.08'
                    height='32.03'
                  />
                  <text
                    id='_5'
                    data-name='5'
                    className='cls-2 text'
                    transform='translate(630.45 643)'>
                    5
                  </text>
                </g>
                <g id='_6' data-name={6}>
                  <rect
                    id='_6-2'
                    data-name={6}
                    className='cls-1 svg_shape'
                    x='505.83'
                    y='623.14'
                    width='84.75'
                    height='32.03'
                  />
                  <text
                    id='_6'
                    data-name='6'
                    className='cls-2 text'
                    transform='translate(542.18 642.52)'>
                    6
                  </text>
                </g>
                <g id='_7' data-name={7}>
                  <rect
                    id='_7-2'
                    data-name={7}
                    className='cls-1 svg_shape'
                    x='439.17'
                    y='623.14'
                    width='66.67'
                    height='32.03'
                  />
                  <text
                    id='_7'
                    data-name='7'
                    className='cls-2 text'
                    transform='translate(468.82 642.52)'>
                    7
                  </text>
                </g>
                <g id='_8' data-name={8} className='active_block'>
                  <path
                    id='_8-2'
                    data-name={8}
                    className='cls-1 svg_shape'
                    d='M454.23,764V795.9c1.74.06,3.48.1,5.23.1h91.89V764Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_8'
                    data-name='8'
                    className='cls-2 text'
                    transform='translate(387.17 643)'>
                    8
                  </text>
                </g>
                <g id='_10' data-name={10} className='grey_out'>
                  <path
                    id='_10-2'
                    data-name={10}
                    className='cls-1 svg_shape'
                    d='M450.82,764c-10.78,0-27.47-5.44-37.37-8.19L397.92,783.6c20.77,7.77,28.05,11.38,56.31,12.3V764Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_10'
                    data-name='10'
                    className='cls-2 text'
                    transform='translate(306.08 641)'>
                    10
                  </text>
                </g>
                <g id='_18' data-name={18} className='grey_out'>
                  <path
                    id='_18-2'
                    data-name={18}
                    className='cls-1 svg_shape'
                    d='M450.82,355h28.63V323h-20c-29.93,0-16.54-.82-46.15,6.79l15.14,27.41A116.15,116.15,0,0,1,450.82,355Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_18'
                    data-name='18'
                    className='cls-2 text'
                    transform='translate(324.6 202.26)'>
                    18
                  </text>
                </g>
                <g id='_19' data-name={19}>
                  <rect
                    id='_19-2'
                    data-name={19}
                    className='cls-1 svg_shape'
                    x='367.27'
                    y='182.17'
                    width='109.31'
                    height='32.03'
                  />
                  <text
                    id='_19'
                    data-name='19'
                    className='cls-2 text'
                    transform='translate(414.5 201.26)'>
                    19
                  </text>
                </g>
                <g id='_20' data-name={20}>
                  <rect
                    id='_20-2'
                    data-name={20}
                    className='cls-1 svg_shape'
                    x='476.58'
                    y='182.17'
                    width='114.5'
                    height='32.03'
                  />
                  <text
                    id='_20'
                    data-name='20'
                    className='cls-2 text'
                    transform='translate(526.23 201.26)'>
                    20
                  </text>
                </g>
                <g id='_21' data-name={21}>
                  <rect
                    id='_21-2'
                    data-name={21}
                    className='cls-1 svg_shape'
                    x='591.08'
                    y='182.17'
                    width='110.75'
                    height='32.03'
                  />
                  <text
                    id='_21'
                    data-name='21'
                    className='cls-2 text'
                    transform='translate(637.87 201.26)'>
                    21
                  </text>
                </g>
                <g id='_22' data-name={22}>
                  <rect
                    id='_22-2'
                    data-name={22}
                    className='cls-1 svg_shape'
                    x='701.83'
                    y='182.17'
                    width='103.67'
                    height='32.03'
                  />
                  <text
                    id='_22'
                    data-name='22'
                    className='cls-2 text'
                    transform='translate(746.7 201.26)'>
                    22
                  </text>
                </g>
                <g id='_23' data-name={23} className='active_block'>
                  <path
                    id='_23-2'
                    data-name={23}
                    className='cls-1 svg_shape'
                    d='M950.45,355c9.9,0,15.54.19,24.71,2.52L990,330c-19.37-5.88-18.73-7-48.13-7H917.68v32Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_23'
                    data-name='23'
                    className='cls-2 text'
                    transform='translate(833.39 201.26)'>
                    23
                  </text>
                </g>
                <g
                  id='_101'
                  data-name={101}
                  className='has_listing active_block default_color'>
                  <path
                    id='_101-2'
                    data-name={101}
                    className='cls-1 svg_shape'
                    d='M1051.6,775.19,1040.68,764c-5.52,3.4-26.53,15.3-32.48,18l37.38,65.11a259.56,259.56,0,0,0,45.35-32.32Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_101'
                    data-name='101'
                    className='cls-3 text'
                    transform='translate(916.46 676.01)'>
                    101
                  </text>
                </g>
                <g
                  id='_102'
                  data-name={102}
                  className='has_listing active_block default_color grey_out'>
                  <path
                    id='_102-2'
                    data-name={102}
                    className='cls-1 svg_shape'
                    d='M1008.2,782a155.7,155.7,0,0,1-48,13.14L972,874.61a257.25,257.25,0,0,0,73.56-27.53Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_102'
                    data-name='102'
                    className='cls-3 text'
                    transform='translate(865.12 696.72)'>
                    102
                  </text>
                </g>
                <g
                  id='_103'
                  data-name={103}
                  className='has_listing active_block default_color'>
                  <path
                    id='_103-2'
                    data-name={103}
                    className='cls-1 svg_shape'
                    d='M972,874.61l-11.84-79.5a159.5,159.5,0,0,1-16.74.89H856.18v84H919.3A259.37,259.37,0,0,0,972,874.61Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_103'
                    data-name='103'
                    className='cls-3 text'
                    transform='translate(778.46 707.24)'>
                    103
                  </text>
                </g>
                <g
                  id='_104'
                  data-name={104}
                  className='no_listing disabled_color active_block'>
                  <rect
                    id='_104-2'
                    data-name={104}
                    className='cls-1 svg_shape'
                    x='673.17'
                    y='655.17'
                    width='70.83'
                    height={84}
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_104'
                    data-name='104'
                    className='cls-3 text'
                    transform='translate(687.12 707.24)'>
                    104
                  </text>
                </g>
                <g
                  id='_105'
                  data-name={105}
                  className='no_listing disabled_color active_block'>
                  <rect
                    id='_105-2'
                    data-name={105}
                    className='cls-1 svg_shape'
                    x='590.58'
                    y='655.17'
                    width='82.58'
                    height={84}
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_105'
                    data-name='105'
                    className='cls-3 text'
                    transform='translate(607.79 707.24)'>
                    105
                  </text>
                </g>
                <g
                  id='_106'
                  data-name={106}
                  className='no_listing disabled_color active_block'>
                  <rect
                    id='_106-2'
                    data-name={106}
                    className='cls-1 svg_shape'
                    x='505.83'
                    y='655.17'
                    width='84.75'
                    height={84}
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_106'
                    data-name='106'
                    className='cls-3 text'
                    transform='translate(525.79 707.24)'>
                    106
                  </text>
                </g>
                <g
                  id='_107'
                  data-name={107}
                  className='no_listing disabled_color active_block'>
                  <rect
                    id='_107-2'
                    data-name={107}
                    className='cls-1 svg_shape'
                    x='439.17'
                    y='655.17'
                    width='66.67'
                    height={84}
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_107'
                    data-name='107'
                    className='cls-3 text'
                    transform='translate(450.12 707.24)'>
                    107
                  </text>
                </g>
                <g
                  id='_108'
                  data-name={108}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_108-2'
                    data-name={108}
                    className='cls-1 svg_shape'
                    d='M490.35,796H458.94l-1.61,0-5.54,81.73A260.72,260.72,0,0,0,486.22,880h65.13V796Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_108'
                    data-name='108'
                    className='cls-3 text'
                    transform='translate(367.27 707.24)'>
                    108
                  </text>
                </g>
                <g
                  id='_110'
                  data-name={110}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_110-2'
                    data-name={110}
                    className='cls-1 svg_shape'
                    d='M451.79,877.71,457.33,796a155.88,155.88,0,0,1-58.94-12.15l-39.21,62.82A256.82,256.82,0,0,0,451.79,877.71Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_110'
                    data-name='110'
                    className='cls-3 text'
                    transform='translate(284.65 698.17)'>
                    110
                  </text>
                </g>
                <g
                  id='_111'
                  data-name={111}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_111-2'
                    data-name={111}
                    className='cls-1 svg_shape'
                    d='M312.83,813.18a259.44,259.44,0,0,0,46.35,33.47l39.21-62.82c-7.46-3.14-30.82-16.52-37.64-20.72l-14.4,16.56Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_111'
                    data-name='111'
                    className='cls-3 text'
                    transform='translate(221.56 677.01)'>
                    111
                  </text>
                </g>
                <g
                  id='_117'
                  data-name={117}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_117-2'
                    data-name={117}
                    className='cls-1 svg_shape'
                    d='M315.3,310.48l31.22,34.12,13.24,15.13c11.05-6.74,40.92-26.09,53.55-29.94L383.67,267A258.75,258.75,0,0,0,315.3,310.48Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_117'
                    data-name='117'
                    className='cls-3 text'
                    transform='translate(229.51 179.49)'>
                    117
                  </text>
                </g>
                <g
                  id='_118'
                  data-name={118}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_118-2'
                    data-name={118}
                    className='cls-1 svg_shape'
                    d='M383.67,267l29.64,62.78A156.26,156.26,0,0,1,458.94,323h20.91V245.92A257,257,0,0,0,383.67,267Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_118'
                    data-name='118'
                    className='cls-3 text'
                    transform='translate(305.08 157.48)'>
                    118
                  </text>
                </g>
                <g
                  id='_119'
                  data-name={119}
                  className='has_listing active_block default_color'>
                  <path
                    id='_119-2'
                    data-name={119}
                    className='cls-1 svg_shape'
                    d='M486.22,245.83q-3.2,0-6.37.09V323H588.76V245.83Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_119'
                    data-name='119'
                    className='cls-3 text'
                    transform='translate(400.91 154.72)'>
                    119
                  </text>
                </g>
                <g
                  id='_120'
                  data-name={120}
                  className='has_listing active_block default_color'>
                  <rect
                    id='_120-2'
                    data-name={120}
                    className='cls-1 svg_shape'
                    x='476.58'
                    y={105}
                    width='114.5'
                    height='77.17'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_120'
                    data-name='120'
                    className='cls-3 text'
                    transform='translate(512 156.16)'>
                    120
                  </text>
                </g>
                <g
                  id='_121'
                  data-name={121}
                  className='no_listing disabled_color active_block grey_out'>
                  <rect
                    id='_121-2'
                    data-name={121}
                    className='cls-1 svg_shape'
                    x='591.08'
                    y={105}
                    width='111.25'
                    height='77.17'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_121'
                    data-name='121'
                    className='cls-3 text'
                    transform='translate(624.66 156.16)'>
                    121
                  </text>
                </g>
                <g
                  id='_122'
                  data-name={122}
                  className='no_listing disabled_color active_block'>
                  <rect
                    id='_122-2'
                    data-name={122}
                    className='cls-1 svg_shape'
                    x='702.33'
                    y={105}
                    width='103.17'
                    height='77.17'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_122'
                    data-name='122'
                    className='cls-3 text'
                    transform='translate(731.33 155.16)'>
                    122
                  </text>
                </g>
                <g
                  id='_123'
                  data-name={123}
                  className='has_listing active_block default_color'>
                  <path
                    id='_123-2'
                    data-name={123}
                    className='cls-1 svg_shape'
                    d='M919.3,245.83h-1.62V323h25.76A156.32,156.32,0,0,1,990,330l32.66-62.7A257.44,257.44,0,0,0,919.3,245.83Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_123'
                    data-name='123'
                    className='cls-3 text'
                    transform='translate(829.39 155.16)'>
                    123
                  </text>
                </g>
                <g
                  id='_124'
                  data-name={124}
                  className='has_listing active_block default_color'>
                  <path
                    id='_124-2'
                    data-name={124}
                    className='cls-1 svg_shape'
                    d='M1088.43,308.87a258.85,258.85,0,0,0-65.82-41.54L990,330c11.7,3.64,43.15,23.57,53.5,29.7l16.9-20.23Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_124'
                    data-name='124'
                    className='cls-3 text'
                    transform='translate(902.06 177.49)'>
                    124
                  </text>
                </g>
                <g id='_14' data-name={14}>
                  <rect
                    id='_14-2'
                    data-name={14}
                    className='cls-1 svg_shape'
                    x='189.23'
                    y='392.28'
                    width='33.34'
                    height={50}
                  />
                  <text
                    id='_14'
                    data-name='14'
                    className='cls-2 text'
                    transform='translate(197.05 424.25)'>
                    14
                  </text>
                </g>
                <g id='_15' data-name={15}>
                  <rect
                    id='_15-2'
                    data-name={15}
                    className='cls-1 svg_shape'
                    x='189.23'
                    y='355.17'
                    width='33.34'
                    height='37.11'
                  />
                  <text
                    id='_15'
                    data-name='15'
                    className='cls-2 text'
                    transform='translate(197.05 378.25)'>
                    15
                  </text>
                </g>
                <g id='_13' data-name={13}>
                  <rect
                    id='_13-2'
                    data-name={13}
                    className='cls-1 svg_shape'
                    x='189.23'
                    y='442.28'
                    width='33.34'
                    height='39.89'
                  />
                  <text
                    id='_13'
                    data-name='13'
                    className='cls-2 text'
                    transform='translate(197.05 467.25)'>
                    13
                  </text>
                </g>
                <g id='_29' data-name={29} className='active_block'>
                  <path
                    id='_29-2'
                    data-name={29}
                    className='cls-1 svg_shape'
                    d='M1066.33,647.9V623h33.34v15a157.58,157.58,0,0,1-10.43,56.54l-27.83-13.1A116.13,116.13,0,0,0,1066.33,647.9Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_29'
                    data-name='29'
                    className='cls-2 text'
                    transform='translate(962.11 517.91)'>
                    29
                  </text>
                </g>
                <g id='_25' data-name={25} className='active_block'>
                  <path
                    id='_25-2'
                    data-name={25}
                    className='cls-1 svg_shape'
                    d='M1066.33,471.1a116.44,116.44,0,0,0-3.1-26.72l27.69-15.28a157.83,157.83,0,0,1,8.75,51.95V496h-33.34Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <text
                    id='_25'
                    data-name='25'
                    className='cls-2 text'
                    transform='translate(962.11 332.86)'>
                    25
                  </text>
                </g>
                <g id='_27' data-name={27}>
                  <rect
                    id='_27-2'
                    data-name={27}
                    className='cls-1 svg_shape'
                    x='954.15'
                    y='392.28'
                    width='33.34'
                    height={50}
                  />
                  <text
                    id='_27'
                    data-name='27'
                    className='cls-2 text'
                    transform='translate(963.11 424.25)'>
                    27
                  </text>
                </g>
                <g id='_26' data-name={26}>
                  <rect
                    id='_26-2'
                    data-name={26}
                    className='cls-1 svg_shape'
                    x='954.15'
                    y='355.17'
                    width='33.34'
                    height='37.11'
                  />
                  <text
                    id='_26'
                    data-name='26'
                    className='cls-2 text'
                    transform='translate(963.11 378.25)'>
                    26
                  </text>
                </g>
                <g id='_28' data-name={28}>
                  <rect
                    id='_28-2'
                    data-name={28}
                    className='cls-1 svg_shape'
                    x='954.15'
                    y='442.28'
                    width='33.34'
                    height='39.89'
                  />
                  <text
                    id='_28'
                    data-name='28'
                    className='cls-2 text'
                    transform='translate(963.11 467.25)'>
                    28
                  </text>
                </g>
                <g id='_17' data-name={17} className='grey_out'>
                  <path
                    id='_17-2'
                    data-name={17}
                    className='cls-1 svg_shape'
                    d='M413.31,329.79a274.84,274.84,0,0,0-28,14.4c-6.12,3.68-25.55,15.54-25.55,15.54l22.51,26.45s11.35-8.59,21.73-15.85c9.92-6.93,24.45-13.13,24.45-13.13Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <g id='_17' data-name='17'>
                    <text
                      className='cls-2 text'
                      transform='translate(273.42 222.07)'>
                      17
                    </text>
                  </g>
                </g>
                <g id='_11' data-name={11} className='grey_out'>
                  <path
                    id='_11-2'
                    data-name={11}
                    className='cls-1 svg_shape'
                    d='M398.31,783.18a273.61,273.61,0,0,1-28-14.39c-6.12-3.69-9.56-5.68-9.56-5.68L379,735.91s-.34-.53,10,6.73c9.92,6.94,24.45,13.14,24.45,13.14Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <g id='_11' data-name='11'>
                    <text
                      className='cls-2 text'
                      transform='translate(266.06 623.56)'>
                      11
                    </text>
                  </g>
                </g>
                <g id='_1' data-name={1} className='active_block'>
                  <path
                    id='_1-2'
                    data-name={1}
                    className='cls-1 svg_shape'
                    d='M1008.19,781.72s14.47-7,27.7-14.93c6.12-3.69,5.36-2.8,5.36-2.8l-16.72-27.77s-1.49.69-7.33,4.42a251.15,251.15,0,0,1-24.45,13.14Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <g id='_1' data-name='1'>
                    <text
                      className='cls-2 text'
                      data-name={1}
                      transform='translate(901.21 622.56)'>
                      1
                    </text>
                  </g>
                </g>
                <g id='_24' data-name={24} className='active_block'>
                  <path
                    id='_24-2'
                    data-name={24}
                    className='cls-1 svg_shape'
                    d='M989.89,329.79a274.84,274.84,0,0,1,28,14.4c6.12,3.68,25.56,15.54,25.56,15.54l-22.51,26.45s-11.35-8.59-21.74-15.85c-9.92-6.93-24.44-13.13-24.44-13.13Z'
                    transform='translate(-112.18 -140.83)'
                  />
                  <g id='_24' data-name='24'>
                    <text
                      className='cls-2 text'
                      transform='translate(888.85 222.07)'
                      data-name={24}>
                      24
                    </text>
                  </g>
                </g>
              </g>
              <g id='Upper_Tier'>
                <g
                  id='_403'
                  data-name={403}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_403-2'
                    data-name={403}
                    className='cls-1 svg_shape'
                    d='M999.83,867.19a257.64,257.64,0,0,1-53.45,11.4l14.35,101.13a367.13,367.13,0,0,0,67.91-15.82Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_403'
                    data-name='403'
                    className='cls-3 text'
                    transform='translate(854.66 801.99)'>
                    403
                  </text>
                </g>
                <g
                  id='_404'
                  data-name={404}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_404-2'
                    data-name={404}
                    className='cls-1 svg_shape'
                    d='M960.73,979.72,946.38,878.59A260.44,260.44,0,0,1,919.3,880H814V983.07H911A371.49,371.49,0,0,0,960.73,979.72Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_404'
                    data-name='404'
                    className='cls-3 text'
                    transform='translate(748.33 801.66)'>
                    404
                  </text>
                </g>
                <g
                  id='_405'
                  data-name={405}
                  className='no_listing disabled_color active_block grey_out'>
                  <rect
                    id='_405-2'
                    data-name={405}
                    className='cls-1 svg_shape'
                    x='590.58'
                    y='739.17'
                    width='111.25'
                    height='103.07'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_405'
                    data-name='405'
                    className='cls-3 text'
                    transform='translate(623.33 801.66)'>
                    405
                  </text>
                </g>
                <g id='_406' data-name={406} className='no_listing grey_out'>
                  <rect
                    id='_406-2'
                    data-name={406}
                    className='cls-1 svg_shape'
                    x='476.83'
                    y='739.17'
                    width='113.75'
                    height='103.07'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_406'
                    data-name='406'
                    className='cls-3 text'
                    transform='translate(512.85 801.66)'>
                    406
                  </text>
                </g>
                <g
                  id='_407'
                  data-name={407}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_407-2'
                    data-name={407}
                    className='cls-1 svg_shape'
                    d='M486.22,880a262,262,0,0,1-34.4-2.28l-7.43,103.47a373,373,0,0,0,37.41,1.88H589V880Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_407'
                    data-name='407'
                    className='cls-3 text'
                    transform='translate(386.33 801.66)'>
                    407
                  </text>
                </g>
                <g
                  id='_408'
                  data-name={408}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_408-2'
                    data-name={408}
                    className='cls-1 svg_shape'
                    d='M444.39,981.19l7.43-103.47A258,258,0,0,1,408.21,868l-35.63,98.63A367.52,367.52,0,0,0,444.39,981.19Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_408'
                    data-name='408'
                    className='cls-3 text'
                    transform='translate(285.65 801.66)'>
                    408
                  </text>
                </g>
                <g
                  id='_418'
                  data-name={418}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_418-2'
                    data-name={418}
                    className='cls-1 svg_shape'
                    d='M374.11,157.3l35.54,100.09a256.74,256.74,0,0,1,46.86-9.86L444,143.27A367.4,367.4,0,0,0,374.11,157.3Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_418'
                    data-name='418'
                    className='cls-3 text'
                    transform='translate(285.65 59.49)'>
                    418
                  </text>
                </g>
                <g
                  id='_419'
                  data-name={419}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_419-2'
                    data-name={419}
                    className='cls-1 svg_shape'
                    d='M481.8,141.33A372.22,372.22,0,0,0,444,143.27l12.54,104.26a260.21,260.21,0,0,1,29.71-1.7H588.76V141.33Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_419'
                    data-name='419'
                    className='cls-3 text'
                    transform='translate(391.46 59.49)'>
                    419
                  </text>
                </g>
                <g
                  id='_420'
                  data-name={420}
                  className='no_listing disabled_color active_block'>
                  <rect
                    id='_420-2'
                    data-name={420}
                    className='cls-1 svg_shape'
                    x='476.58'
                    y='0.5'
                    width='114.5'
                    height='104.5'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_420'
                    data-name='420'
                    className='cls-3 text'
                    transform='translate(512 59.49)'>
                    420
                  </text>
                </g>
                <g
                  id='_421'
                  data-name={421}
                  className='no_listing disabled_color active_block grey_out'>
                  <rect
                    id='_421-2'
                    data-name={421}
                    className='cls-1 svg_shape'
                    x='591.08'
                    y='0.5'
                    width='111.25'
                    height='104.5'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_421'
                    data-name='421'
                    className='cls-3 text'
                    transform='translate(624.66 59.49)'>
                    421
                  </text>
                </g>
                <g
                  id='_422'
                  data-name={422}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_422-2'
                    data-name={422}
                    className='cls-1 svg_shape'
                    d='M911,141.33H814.51v104.5H919.3a261,261,0,0,1,29.63,1.7l7.14-103.46A373.13,373.13,0,0,0,911,141.33Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_422'
                    data-name='422'
                    className='cls-3 text'
                    transform='translate(748.33 59.49)'>
                    422
                  </text>
                </g>
                <g
                  id='_423'
                  data-name={423}
                  className='has_listing active_block default_color grey_out'>
                  <path
                    id='_423-2'
                    data-name={423}
                    className='cls-1 svg_shape'
                    d='M956.07,144.07l-7.14,103.46A257.52,257.52,0,0,1,994.55,257l32.83-96.89A367.14,367.14,0,0,0,956.07,144.07Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_423'
                    data-name='423'
                    className='cls-3 text'
                    transform='translate(852.66 59.49)'>
                    423
                  </text>
                </g>
                <g
                  id='_401'
                  data-name={401}
                  className='has_listing active_block default_color'>
                  <path
                    id='_401-2'
                    data-name={401}
                    className='cls-1 svg_shape'
                    d='M1217.66,819.37l-82.14-56.24a259.76,259.76,0,0,1-69,70.84l54.68,83.39A371.19,371.19,0,0,0,1217.66,819.37Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_401'
                    data-name='401'
                    className='cls-3 text'
                    transform='translate(1014.33 705.82)'>
                    401
                  </text>
                </g>
                <g
                  id='_402'
                  data-name={402}
                  className='has_listing active_block default_color'>
                  <path
                    id='_402-2'
                    data-name={402}
                    className='cls-1 svg_shape'
                    d='M1066.52,834a257.57,257.57,0,0,1-66.69,33.22l28.81,96.71a367.91,367.91,0,0,0,92.56-46.54Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_402'
                    data-name='402'
                    className='cls-3 text'
                    transform='translate(923.66 766.4)'>
                    402
                  </text>
                </g>
                <g
                  id='_409'
                  data-name={409}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_409-2'
                    data-name={409}
                    className='cls-1 svg_shape'
                    d='M372.58,966.62,408.21,868a257.91,257.91,0,0,1-69-33.89l-56.92,90.4A367.79,367.79,0,0,0,372.58,966.62Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_409'
                    data-name='409'
                    className='cls-3 text'
                    transform='translate(211.65 775.99)'>
                    409
                  </text>
                </g>
                <g
                  id='_410'
                  data-name={410}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_410-2'
                    data-name={410}
                    className='cls-1 svg_shape'
                    d='M282.27,924.5l56.92-90.4A260.09,260.09,0,0,1,268.58,761l-88.82,65.17A371.15,371.15,0,0,0,282.27,924.5Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_410'
                    data-name='410'
                    className='cls-3 text'
                    transform='translate(130.98 705.82)'>
                    410
                  </text>
                </g>
                <g
                  id='_411'
                  data-name={411}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_411-2'
                    data-name={411}
                    className='cls-1 svg_shape'
                    d='M179.76,826.14,268.58,761a257.3,257.3,0,0,1-29.7-64.25L131,729.16A367.76,367.76,0,0,0,179.76,826.14Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <path
                    id='_411'
                    data-name='411'
                    className='cls-4 text'
                    d='M186,764v-3.6h-7.32v-3L186.39,746h2.88v11.35h2.22v3h-2.22V764Zm0-6.62v-6.11l-4.11,6.11Zm16,6.62h-3.43V751a11.85,11.85,0,0,1-4.44,2.6v-3.11a9.78,9.78,0,0,0,2.92-1.67,6.33,6.33,0,0,0,2.16-2.86h2.79Zm12.52,0H211V751a11.81,11.81,0,0,1-4.43,2.6v-3.11a9.9,9.9,0,0,0,2.92-1.67,6.33,6.33,0,0,0,2.16-2.86h2.78Z'
                    transform='translate(-112.18 -140.83)'
                  />
                </g>
                <g
                  id='_412'
                  data-name={412}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_412-2'
                    data-name={412}
                    className='cls-1 svg_shape'
                    d='M131,729.16l107.84-32.44A258.19,258.19,0,0,1,227.78,623h-115A368.07,368.07,0,0,0,131,729.16Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_412'
                    data-name='412'
                    className='cls-3 text'
                    transform='translate(42.98 537.25)'>
                    412
                  </text>
                </g>
                <g
                  id='_413'
                  data-name={413}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_413-2'
                    data-name={413}
                    className='cls-1 svg_shape'
                    d='M112.68,510.45V614c0,3,0,6,.12,9h115c0-.49,0-1,0-1.46V504.29c0-2.77.06-5.54.15-8.29H113Q112.68,503.19,112.68,510.45Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_413'
                    data-name='413'
                    className='cls-3 text'
                    transform='translate(41.98 430.25)'>
                    413
                  </text>
                </g>
                <g
                  id='_414'
                  data-name={414}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_414-2'
                    data-name={414}
                    className='cls-1 svg_shape'
                    d='M113,496h115a258.1,258.1,0,0,1,11.66-69.1L131.92,392.54A368,368,0,0,0,113,496Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_414'
                    data-name='414'
                    className='cls-3 text'
                    transform='translate(42.98 322.89)'>
                    414
                  </text>
                </g>
                <g
                  id='_415'
                  data-name={415}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_415-2'
                    data-name={415}
                    className='cls-1 svg_shape'
                    d='M131.92,392.54,239.57,426.9a258,258,0,0,1,46.25-85.81l-83.71-71.52A368.8,368.8,0,0,0,131.92,392.54Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_415'
                    data-name='415'
                    className='cls-3 text'
                    transform='translate(72.65 234.89)'>
                    415
                  </text>
                </g>
                <g
                  id='_416'
                  data-name={416}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_416-2'
                    data-name={416}
                    className='cls-1 svg_shape'
                    d='M202.11,269.57l83.71,71.52a260.4,260.4,0,0,1,52.11-48.48l-56.4-92.27A371.14,371.14,0,0,0,202.11,269.57Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_416'
                    data-name='416'
                    className='cls-3 text'
                    transform='translate(137.65 140.89)'>
                    416
                  </text>
                </g>
                <g
                  id='_417'
                  data-name={417}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_417-2'
                    data-name={417}
                    className='cls-1 svg_shape'
                    d='M281.53,200.34l56.4,92.27a257.37,257.37,0,0,1,71.72-35.22L374.11,157.3A368,368,0,0,0,281.53,200.34Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_417'
                    data-name='417'
                    className='cls-3 text'
                    transform='translate(212.89 88.23)'>
                    417
                  </text>
                </g>
                <g
                  id='_424'
                  data-name={424}
                  className='has_listing active_block default_color'>
                  <path
                    id='_424-2'
                    data-name={424}
                    className='cls-1 svg_shape'
                    d='M1027.38,160.1,994.55,257a257.34,257.34,0,0,1,68.07,32.23l57.57-82.85A368.05,368.05,0,0,0,1027.38,160.1Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_424'
                    data-name='424'
                    className='cls-3 text'
                    transform='translate(923.33 89.49)'>
                    424
                  </text>
                </g>
                <g
                  id='_425'
                  data-name={425}
                  className='has_listing active_block default_color'>
                  <path
                    id='_425-2'
                    data-name={425}
                    className='cls-1 svg_shape'
                    d='M1120.19,206.37l-57.57,82.85a259.59,259.59,0,0,1,68.83,67.44l83.56-55.5A371.32,371.32,0,0,0,1120.19,206.37Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_425'
                    data-name='425'
                    className='cls-3 text'
                    transform='translate(1012.66 149.83)'>
                    425
                  </text>
                </g>
                <g
                  id='_426'
                  data-name={426}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_426-2'
                    data-name={426}
                    className='cls-1 svg_shape'
                    d='M1215,301.16l-83.56,55.5A257.36,257.36,0,0,1,1166,427.07l95.87-31.3A367.7,367.7,0,0,0,1215,301.16Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_426'
                    data-name='426'
                    className='cls-3 text'
                    transform='translate(1072.13 234.89)'>
                    426
                  </text>
                </g>
                <g
                  id='_427'
                  data-name={427}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_427-2'
                    data-name={427}
                    className='cls-1 svg_shape'
                    d='M1261.88,395.77,1166,427.07a258,258,0,0,1,11.63,69.43h102.17A368.09,368.09,0,0,0,1261.88,395.77Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_427'
                    data-name='427'
                    className='cls-3 text'
                    transform='translate(1094.33 322.95)'>
                    427
                  </text>
                </g>
                <g
                  id='_428'
                  data-name={428}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_428-2'
                    data-name={428}
                    className='cls-1 svg_shape'
                    d='M1280.07,614V510.45q0-7-.26-13.95H1177.64c.07,2.59.12,5.18.12,7.79V621.54c0,.74,0,1.47,0,2.21H1280C1280,620.49,1280.07,617.23,1280.07,614Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_428'
                    data-name='428'
                    className='cls-3 text'
                    transform='translate(1094.33 429.91)'>
                    428
                  </text>
                </g>
                <g
                  id='_429'
                  data-name={429}
                  className='no_listing disabled_color active_block grey_out'>
                  <path
                    id='_429-2'
                    data-name={429}
                    className='cls-1 svg_shape'
                    d='M1177.74,623.75a258.78,258.78,0,0,1-10.88,72.2l95.34,31.78a368.69,368.69,0,0,0,17.75-104Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_429'
                    data-name='429'
                    className='cls-3 text'
                    transform='translate(1094.33 537.25)'>
                    429
                  </text>
                </g>
                <g
                  id='_430'
                  data-name={430}
                  className='no_listing disabled_color active_block'>
                  <path
                    id='_430-2'
                    data-name={430}
                    className='cls-1 svg_shape'
                    d='M1166.86,696a257.28,257.28,0,0,1-31.34,67.18l82.14,56.24a367.73,367.73,0,0,0,44.54-91.64Z'
                    transform='translate(-112.18 -140.83)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <text
                    id='_430'
                    data-name='430'
                    className='cls-3 text'
                    transform='translate(1068 623.14)'>
                    430
                  </text>
                </g>
              </g>
              <g id='Stage'>
                <polygon
                  id='_0Stage'
                  data-name='0Stage'
                  className='cls-5 svg_shape'
                  points='640.1 385.86 351.27 385.86 351.27 298.24 291.58 298.24 291.58 531.23 351.27 531.23 351.27 444.58 640.1 444.58 640.1 385.86'
                />
                <g id='_0Stage' data-name='0Stage'>
                  <text
                    className='cls-3 text'
                    transform='translate(327.64 445.9) rotate(-90)'>
                    Stage
                  </text>
                </g>
              </g>
              <g id='Field'>
                <g
                  id='_0FrontLeft'
                  data-name='Front Left'
                  className='has_listing active_block default_color'>
                  <rect
                    id='_0FrontLeft-2'
                    data-name='Front Left'
                    className='cls-1 svg_shape'
                    x='381.84'
                    y='453.13'
                    width='267.96'
                    height='105.49'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <g id='_0FrontLeft' data-name='Front Left'>
                    <text
                      className='cls-3 text'
                      transform='translate(454.97 515.49)'
                      data-name='Front Left'>
                      Front Left
                    </text>
                  </g>
                </g>
                <g
                  id='_0FrontRight'
                  data-name='Front Right'
                  className='has_listing active_block default_color'>
                  <rect
                    id='_0FrontRight-2'
                    data-name='Front Right'
                    className='cls-1 svg_shape'
                    x='383.51'
                    y='270.67'
                    width='266.29'
                    height='105.75'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <g id='_0FrontRight' data-name='Front Right'>
                    <text
                      className='cls-3 text'
                      transform='translate(448.97 332.49)'
                      data-name='Front Right'>
                      Front Right
                    </text>
                  </g>
                </g>
                <g
                  id='_0Back'
                  data-name='Back'
                  className='has_listing active_block default_color'>
                  <rect
                    id='_0Back-2'
                    data-name='Back'
                    className='cls-1 svg_shape'
                    x='761.98'
                    y='411.5'
                    width='232.71'
                    height='287.95'
                    transform='translate(1644.49 970.12) rotate(180)'
                    style={{ fill: 'rgb(44, 105, 187)' }}
                  />
                  <g id='_0Back' data-name='Back'>
                    <text
                      className='cls-3 text'
                      transform='translate(737.07 418.43)'
                      data-name='Back'>
                      Back
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          )}
        </div>
      </div>
    </S.Stadium>
  );
};

export default Stadium;
