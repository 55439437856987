import * as S from './styles';

const Hero = () => {
  return (
    <>
    <S.Partners>
          <img src='/images/support-logos-new.png' />
      </S.Partners>
      <S.Hero>
        <div>
          
        </div>
        <div>
         
        </div>
      </S.Hero>
      
    </>
  );
};

export default Hero;
