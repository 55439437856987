import styled, { css } from 'styled-components';

export const menuSlidesAnimation = css`
  @keyframes mobile-menu-slides {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  -webkit-animation: mobile-menu-slides 0.5s 1; /* Safari 4+ */
  -moz-animation: mobile-menu-slides 0.5s 1; /* Fx 5+ */
  -o-animation: mobile-menu-slides 0.5s 1; /* Opera 12+ */
  animation: mobile-menu-slides 0.5s 1; /* IE 10+, Fx 29+ */
`;

export const Navbar = styled.div`
  /*! CSS Used from: https://www.theweeknd.com/sites/g/files/aaj15616/f/css/css_JSinkK16crV4-9AZX3BBYEjCvCkJmGhoBb--bNeP8Wc.css ; media=all */
  @media all {
    .element-invisible {
      position: absolute !important;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      overflow: hidden;
      height: 1px;
    }
    .clearfix {
      *zoom: 1;
    }
    .clearfix:after {
      content: '';
      display: table;
      clear: both;
    }
    .menu {
      border: none;
      list-style: none;
      text-align: left;
    }
    .menu .leaf {
      list-style-image: url(https://www.theweeknd.com/sites/all/themes/omega/omega/images/misc/menu-leaf.png?1382488163);
      list-style-type: square;
    }
    .active {
      color: #000;
    }
    aside,
    header,
    nav,
    section {
      display: block;
    }
    a {
      background: transparent;
    }
    a:active,
    a:hover {
      outline: 0;
    }
    h1 {
      font-size: 2em;
      margin: 0.67em 0;
    }
    img {
      border: 0;
    }
    *,
    *:after,
    *:before {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      *behavior: url(https://www.theweeknd.com/sites/all/themes/iga-artist-themes/suzuki/behaviors/box-sizing/boxsizing.php);
    }
    img {
      max-width: 100%;
    }
  }
  /*! CSS Used from: https://cache.umusic.com/_sites/_halo/theweeknd5/css/theme-theweeknd12-v3b.css ; media=all */
  @media all {
    body #page:after,
    body .l-main {
      animation-name: fadeIn;
      animation-iteration-count: 1s;
      animation-delay: 0;
      animation-timing-function: ease;
      animation-fill-mode: both;
    }
    .menu-block-asf_common_mm_oc {
      overflow: hidden;
    }
    body .l-main {
      animation-duration: 0.3s;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    body #page:after {
      animation-duration: 1s;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .active {
      font-weight: inherit;
      color: inherit;
    }
    a img,
    img {
      border: 0;
    }
    #page:before {
      visibility: hidden;
      height: 0;
      width: 0;
      display: inline-block;
      float: left;
      content: 'no-parallax';
    }
    .node.node--event .field[class*='-tickets'] a:before {
      content: '\e800';
    }
    .icon-mail:before {
      content: '\\e86b';
      font-family: fontello;
      font-weight: 400;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
    .icon-login:before {
      content: '\e87f';
    }
    aside,
    header,
    nav,
    section {
      display: block;
    }
    a:active,
    a:hover {
      outline: 0;
    }
    img {
      max-width: 100%;
      height: auto;
      -ms-interpolation-mode: bicubic;
      display: inline-block;
      vertical-align: middle;
    }
    *,
    ::after,
    ::before {
      box-sizing: inherit;
    }
    div,
    h1,
    h2,
    h3,
    li,
    p,
    ul {
      margin: 0;
      padding: 0;
    }
    ul {
      margin-left: 1.25rem;
    }
    p {
      font-size: inherit;
      line-height: 1.6;
      margin-bottom: 1rem;
      text-rendering: optimizeLegibility;
    }
    h1,
    h2,
    h3 {
      font-style: normal;
      text-rendering: optimizeLegibility;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
    ul {
      margin-bottom: 1rem;
    }
    h1 {
      font-size: 2.8125rem;
    }
    h2 {
      font-size: 2.34375rem;
    }
    h3 {
      font-size: 1.95313rem;
    }
    a {
      background-color: transparent;
      color: #111;
      text-decoration: none;
      line-height: inherit;
      cursor: pointer;
    }
    a:focus,
    a:hover {
      color: #0f0f0f;
    }
    ul {
      line-height: 1.6;
      list-style-position: outside;
    }
    li {
      font-size: inherit;
    }
    ul {
      list-style-type: disc;
    }
    .button {
      display: inline-block;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      -webkit-appearance: none;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      vertical-align: middle;
      border-radius: 0;
      padding: 0.75em 1em 0.6em;
      margin: 0 0 1rem;
      font-size: 0.9rem;
      border: 1px solid #fff;
      color: #fff;
    }
    .button,
    .button:focus,
    .button:hover {
      background-color: transparent;
    }
    .button:focus,
    .button:hover {
      border-color: gray;
      color: gray;
    }
    .menu {
      display: -ms-flexbox;
    }
    .menu {
      -ms-flex-wrap: nowrap;
    }
    .menu a {
      margin-bottom: 0;
    }
    .menu {
      margin: 0;
      list-style-type: none;
      width: 100%;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      flex-wrap: nowrap;
    }
    .menu > li > a {
      padding: 0 0.5rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
    }
    .menu > li {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .menu .active > a {
      background: 0 0;
    }
    .menu-block-asf_common_mm_oc {
      position: relative;
    }
    &.off-canvas-wrap {
      position: relative;
      width: 100%;
    }
    &.off-canvas-wrap::after,
    &.off-canvas-wrap::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    &.off-canvas-wrap::after {
      clear: both;
    }
    .inner-wrap {
      min-height: 100%;
      -ms-transform: transformX(0);
      transform: transformX(0);
      transition: transform 0.3s ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      z-index: 1;
    }
    .exit-off-canvas {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 0 0;
      cursor: pointer;
      transition: background 0.3s ease;
    }
    aside[class*='-off-canvas'] {
      position: fixed;
      transform: translateZ(0);
      z-index: 10;
      min-height: 100%;
      left: auto;
      right: auto;
      transition: transform 0.3s ease;
    }
    aside[class*='-off-canvas'][class*='left'] {
      -ms-transform: translateX(-100vw);
      transform: translateX(-100vw);
      top: 47px;
      left: 0;
      width: 100vw;
    }
    .top-bar {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0.5rem;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .top-bar,
    .top-bar ul {
      background-color: transparent;
    }
    .clearfix::after,
    .clearfix::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    .clearfix::after {
      clear: both;
    }
    *,
    :after,
    :before {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
    aside,
    header,
    nav,
    section {
      display: block;
    }
    .view-content {
      width: 100%;
    }
    .l-header a,
    .l-region--off-canvas .links > li a {
      font-family: HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue',
        Helvetica, Arial, 'Lucida Grande', sans-serif;
    }
    .l-header-top {
      position: relative;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      perspective: 1000;
      transition: 0.2s transform ease-in-out;
      will-change: margin;
      transform: translate3d(0, 0, 0);
    }
    .l-header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      font-size: 0.9375rem;
      width: 100%;
    }
    .l-header a {
      color: #000;
      text-transform: uppercase;
      font-weight: 400;
      cursor: pointer;
      font-size: 13px;
      transition: 0.2s color ease-in-out;
      position: relative;
      letter-spacing: 0.1em;
      line-height: 40px;
      height: 40px;
    }
    .l-header a:hover {
      color: #888;
    }
    .l-header .l-branding {
      width: 100%;
      text-align: center;
    }
    .l-header .l-branding .site-name {
      display: inline-block;
    }
    .sticky {
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
    }
    .sticky {
      background: #f2f2f2;
    }
    .l-breadcrumb,
    .l-feed-icons-wrapper {
      display: none;
    }
    .icon,
    .node.node--event .field[class*='-tickets'] a:before,
    [class*='icon-'] {
      font-family: fontello;
      position: relative;
      padding: 0 0.28125rem;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      display: inline-block;
      text-indent: 0;
      color: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .icon:before {
      font-family: fontello;
      font-weight: 400;
    }
    .site-name {
      margin: 0;
      height: 100%;
    }
    .site-name > a {
      float: left;
      text-indent: -9999em;
      white-space: nowrap;
      overflow: hidden;
      width: 300px;
      max-height: 55px;
      line-height: 28px;
      max-width: 250px;
      margin: 0 auto;
      background: url('/images/logo.svg') center center/contain no-repeat;
      background-size: 50%;
      position: relative;
      top: 2px;
      height: 100%;
    }
    .site-name > a:after,
    .site-name > a:before {
      float: left;
      text-indent: 0;
    }
    a.menu-icon,
    a.menu-icon > span {
      position: relative;
      margin: 0;
      cursor: pointer;
    }
    a.menu-icon {
      display: inline-block;
      height: 25px;
      width: auto;
      outline: 0;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
    }
    a.menu-icon:after {
      display: none;
    }
    .no-touch a.menu-icon {
      -webkit-transition: 0.2s opacity ease;
      -transition: 0.2s opacity ease;
      transition: 0.2s opacity ease;
    }
    a.menu-icon > span {
      background: #0d0d0d;
      border-radius: 2px;
      height: 2px;
      width: 20px;
      display: block;
      text-indent: -9999em;
      -webkit-transition: 0.1s transform ease;
      -transition: 0.1s transform ease;
      transition: 0.1s transform ease;
    }
    a.menu-icon > span:nth-child(1) {
      top: 0;
    }
    a.menu-icon > span:nth-child(2) {
      top: 4px;
    }
    a.menu-icon > span:nth-child(3) {
      top: 8px;
    }
    .no-touch a.menu-icon:hover {
      opacity: 0.85;
      filter: alpha(opacity=85);
    }
    .l-navigation.top-bar {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0.5625rem;
    }
    .l-navigation.top-bar:after {
      content: '';
      height: 1px;
      width: 100%;
      background: #111;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    ul.links,
    ul.menu {
      margin: 0 auto;
    }
    .menu .leaf {
      list-style-type: none;
      list-style-image: none;
    }
    ul.left,
    ul.right {
      list-style-type: none;
      display: inline-block;
      margin: 0 auto;
      position: absolute;
      z-index: 2;
      top: 14px;
    }
    ul.left a {
      vertical-align: text-top;
    }
    ul.left {
      left: 1.40625rem;
    }
    ul.right {
      right: 0.78125rem;
    }
    .l-navigation {
      padding: 0;
      position: relative;
    }
    .l-region--navigation {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: auto;
    }
    .l-region--navigation > .block {
      display: inline-block;
    }
    .l-region--navigation a {
      font-weight: 400;
    }
    .block--menu-block-asf-common-mm-nav {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding-left: 1.125rem;
    }
    .block--menu-block-asf-common-mm-nav
      .menu-block-wrapper
      > .menu
      > li
      > a:before {
      content: '';
      height: 1px;
      bottom: 8px;
      left: 0;
      width: 0;
      background-color: #000;
      transition: 0.2s width ease-in-out;
      position: absolute;
    }
    .block--menu-block-asf-common-mm-nav
      .menu-block-wrapper
      > .menu
      > li
      > a.active,
    .block--menu-block-asf-common-mm-nav
      .menu-block-wrapper
      > .menu
      > li
      > a:hover {
      color: #000;
    }
    .block--menu-block-asf-common-mm-nav
      .menu-block-wrapper
      > .menu
      > li
      > a.active:before,
    .block--menu-block-asf-common-mm-nav
      .menu-block-wrapper
      > .menu
      > li
      > a:hover:before {
      width: 100%;
    }
    .block--menu-block-asf-common-mm-nav .menu-block-asf_common_mm_nav > .menu {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
    .block--menu-block-asf-common-mm-nav
      .menu-block-asf_common_mm_nav
      > .menu
      > li {
      padding: 0 0.225rem;
    }
    .block--menu-block-asf-common-mm-nav
      .menu-block-asf_common_mm_nav
      > .menu
      > li:not(.site-name) {
      display: none;
    }
    .block--menu-block-asf-common-mm-nav
      .menu-block-asf_common_mm_nav
      > .menu
      > li:not(.site-name)
      > a {
      position: relative;
      padding: 0;
    }
    .l-region--off-canvas {
      background: #f2f2f2;
      -webkit-transform-style: preserve-3d;
      width: 100%;
      width: 100vw;
      min-height: 568px;
      min-height: 100vh;
      margin-top: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-line-pack: center;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center;
      align-content: center;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .l-region--off-canvas a {
      color: #000;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    .l-region--off-canvas li {
      list-style-type: none;
    }
    .l-region--off-canvas .block__title {
      display: none;
    }
    .l-region--off-canvas .block {
      width: 100%;
    }
    .l-region--off-canvas div[class*='menu'] {
      width: 100%;
      text-align: center;
    }
    .l-region--off-canvas .menu-name-main-menu a {
      width: 100%;
      display: inline-block;
      height: 60px;
      line-height: 60px;
    }
    .l-region--off-canvas
      .menu-name-main-menu
      .user--menu-link-login
      .icon:before,
    .role-anonymous-user .l-region--off-canvas .links > li.bolero-user-menu {
      display: none;
    }
    .l-region--off-canvas .menu-name-main-menu .user--menu-link-login {
      width: 100%;
      border-right: none !important;
      height: 15vh;
    }
    .l-region--off-canvas .links {
      width: 100%;
      list-style-type: none;
      text-align: center;
      display: block;
    }
    .l-region--off-canvas .links > .leaf:not(.site-name) {
      display: inline-block;
    }
    .l-region--off-canvas .links a {
      font-weight: 400;
      margin: 0 5px;
    }
    .l-region--off-canvas .links {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
    .l-region--off-canvas .links > li {
      width: 50%;
      border: 1px solid #000;
      border-width: 0 1px 1px 0;
      height: calc(22vh - 6px);
    }
    .l-region--off-canvas .links > li.last {
      width: 100%;
      height: calc(16vh - 4px);
    }
    .l-region--off-canvas .links > li.bolero-user-menu,
    .l-region--off-canvas .links > li.last,
    .l-region--off-canvas .links > li.menu-mlid-2226,
    .l-region--off-canvas .links > li.menu-mlid-35756,
    .l-region--off-canvas .links > li.menu-mlid-445 {
      border-right: none;
    }
    .l-region--off-canvas .links > li.bolero-user-menu {
      width: 100%;
      height: 15vh;
    }
    .l-region--off-canvas .links > li a {
      font-size: 13px;
      letter-spacing: 0.1em;
      height: 100%;
      text-transform: uppercase;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .node.ds-3col-equal:last-child {
      border-bottom: none;
    }
    .block__title {
      font-family: 'Druk Wide Web', sans-serif;
    }
    .block__title {
      display: block;
      position: relative;
      font-size: calc(1.01852vw + 12.74px);
      font-weight: 700;
      margin: 0 auto;
      clear: both;
      text-align: center;
      width: 100%;
      max-width: 1280px;
    }
    .l-content > h1 {
      display: none;
    }
    h1,
    h2,
    h3 {
      font-family: 'Druk Web', futura-pt, HelveticaNeue-Bold,
        'Helvetica Neue Bold', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      line-height: 1.2em;
      color: #000;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0;
    }
    h1:after,
    h2:after,
    h3:after {
      content: '';
    }
    h1:after::after,
    h1:after::before,
    h2:after::after,
    h2:after::before,
    h3:after::after,
    h3:after::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    h1:after::after,
    h2:after::after,
    h3:after::after {
      clear: both;
    }
    .view {
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      width: 100%;
    }
    .view.not-contained {
      padding: 0;
      max-width: 100%;
    }
    .views-row {
      display: inline-block;
    }
    .view-bolero {
      display: block;
    }
    .l-main .view-bolero {
      overflow: visible;
    }
    .view-bolero .views-row {
      position: relative;
      margin: 0.5rem;
    }
    .view-bolero > .view-content:not(.slick-slider) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .view-bolero > .view-content:not(.slick-slider) .views-row {
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
    }
    .node > div {
      position: relative;
    }
    &.has-no-sidebars .l-content {
      clear: both;
    }
    .l-help {
      overflow: hidden;
      display: block;
      clear: both;
    }
    .l-page-wrapper {
      padding-top: 47px;
    }
    .l-columns.inner-wrap {
      width: 100%;
      margin: 0 auto;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: stretch;
      align-items: stretch;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
    .l-content {
      width: 100%;
      margin: 0 auto;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .l-content > .block,
    .l-content > .view,
    .l-region--content-header > .block {
      width: 100%;
      padding: 0;
    }
    .l-content > .block::after,
    .l-content > .block::before,
    .l-content > .view::after,
    .l-content > .view::before,
    .l-region--content-header > .block::after,
    .l-region--content-header > .block::before {
      content: ' ';
      display: table;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-order: 1;
      order: 1;
    }
    .l-content > .block::after,
    .l-content > .view::after,
    .l-region--content-header > .block::after {
      clear: both;
    }
    .not-front .l-header + .l-help {
      padding-top: 47px;
    }
    .node.ds-3col-equal {
      width: 100%;
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .node.ds-3col-equal > div:not(.contextual-links-wrapper) {
      width: 33%;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .node {
      padding: 0;
      position: relative;
      max-width: 100%;
      display: block;
    }
    .node .field--type-image img {
      transition: all 0.2s ease-in-out;
      width: 100%;
      height: auto;
    }
    .node .field--type-image {
      transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      overflow: hidden;
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transition: 0.2s opacity ease-in-out;
      -transition: 0.2s opacity ease-in-out;
      transition: 0.2s opacity ease-in-out;
      backface-visibility: hidden;
      perspective: 1000;
    }
    .node .field--type-image img {
      transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      width: 100%;
      height: auto;
    }
    .node .field--type-image a {
      display: block;
      width: 100%;
    }
    .field--name-body {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: inherit;
      word-break: break-word;
    }
    &.l-page {
      padding-bottom: 0 !important;
      height: auto;
    }
    .l-page-wrapper {
      background-color: transparent;
      margin: 0 auto;
    }
    .view-events {
      margin-bottom: 10vh;
    }
    .view-events .view-content {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .page-tour:not(.page-node) .l-header + .l-help {
      padding: 0;
    }
    .node.node--event .field[class*='-tickets'] a {
      font-size: 10px;
      margin: 0;
      letter-spacing: 0;
      padding: 0 0.675rem;
      line-height: 22px;
      height: 26px;
      font-weight: 500;
      display: inline-block;
      border: 2px solid #000;
      color: #000;
      font-family: 'Druk Wide Web', sans-serif;
    }
    .node.node--event.ds-3col-equal {
      font-family: 'Druk Web', futura-pt, HelveticaNeue-Bold,
        'Helvetica Neue Bold', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      text-transform: uppercase;
    }
    .node.node--event .field[class*='-tickets'] a:before {
      padding: 0 5px 0 0;
      display: none;
    }
    .node.node--event .field[class*='-tickets'] a:hover {
      opacity: 0.8;
    }
    .node.node--event.ds-3col-equal {
      padding: 15px 0 20px;
      -ms-flex-align: center;
      align-items: center;
      color: #000;
      margin: 2px 0;
      font-size: 12px;
      border-bottom: 1px solid #e2e2e2;
      letter-spacing: 0.03em;
    }
    .node.node--event.ds-3col-equal:last-child {
      border: none;
    }
    .node.node--event.ds-3col-equal > div.group-left {
      padding: 0;
      text-align: left;
      color: #212121 !important;
    }
    .node.node--event.ds-3col-equal > div.group-middle {
      letter-spacing: 0;
    }
    .node.node--event.ds-3col-equal > div.group-right > .field {
      display: inline-block;
      float: right;
      margin: 0 3px;
    }
    .node--event .field--name-field-event-date span {
      background: 0 0;
      color: #000;
      font-weight: 400;
      line-height: 1.2;
      padding: 0 8px;
      font-family: HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue',
        Helvetica, Arial, 'Lucida Grande', sans-serif;
      width: 100%;
      text-align: center;
      display: block;
    }
    .node--event .field--name-asf-events-city {
      font-weight: 400;
      font-family: 'Druk Wide Web', sans-serif;
      font-size: 12px;
    }
    .node--event .field--name-asf-events-city .field__item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .node--event .field--name-asf-events-venue-title {
      font-family: HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue',
        Helvetica, Arial, 'Lucida Grande', sans-serif;
      font-weight: 400;
      color: #212121 !important;
    }
    body:not(.navbar-administration) .l-page-wrapper {
      padding-top: 0 !important;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_header {
      height: calc(100vh);
      position: relative;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_header:after {
      width: 100%;
      height: 100%;
      background: url(//cache.umusic.com/_sites/_halo/theweeknd/images/tour-image-desktop.jpg)
        center/cover no-repeat #000;
      top: 0;
      left: 0;
      position: absolute;
      content: '';
      z-index: -1;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_header:before {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(18, 15, 6, 0) 69.39%,
        rgba(18, 15, 6, 0.08) 80.57%,
        rgba(0, 0, 0, 0.66) 87.47%,
        #000 99.13%
      );
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      transition: all 0.2s ease-in;
      opacity: 1;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_header
      .tour-logo {
      position: absolute;
      z-index: 3;
      top: 80px;
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      max-width: 315px;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_header
      .title {
      width: 100%;
      padding: 0;
      margin-bottom: 0;
      text-align: center;
      position: absolute;
      bottom: 30px;
      left: 0;
      z-index: 2;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_header
      .sponsors {
      position: absolute;
      z-index: 3;
      bottom: 60px;
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      text-align: center;
      width: 95%;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_header
      .sponsors
      img {
      width: 100%;
      max-width: 250px;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_content {
      background-color: #000;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_content
      .l-column {
      max-width: 900px;
      width: 95%;
      margin: 0 auto;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_content
      .sponsors {
      width: 95%;
      padding: 30px 0;
      text-align: center;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_content
      .sponsors
      img {
      max-width: 350px;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_copy {
      padding-bottom: 1px;
      background: #000;
      color: #fff;
      position: relative;
      z-index: 2;
      padding-top: 40px;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_copy
      .copies {
      max-width: 900px;
      margin: 0 auto;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_copy
      p {
      font: 400 1.40625rem 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
      line-height: 24px;
      text-align: center;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_copy
      p:first-child {
      font-family: 'Druk Wide Web', futura-pt, HelveticaNeue-Light,
        'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      text-transform: uppercase;
      line-height: 1.4;
      font-size: 24px;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_message {
      background-color: #000;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_message
      p {
      color: #fff;
      font: 400 1.40625rem 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_message
      p:last-child {
      margin-bottom: 0;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_message
      a {
      color: #fff;
      font: 700 1.40625rem 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
    }
    .page-tour.phase-3:not(.page-node)
      .l-page
      .block--block-116
      .tour-block_message
      a:hover {
      text-decoration: underline;
    }
    .page-tour.phase-3:not(.page-node) .l-columns {
      width: 100%;
      background: #000;
    }
    .page-tour.phase-3:not(.page-node) .l-content {
      width: 100%;
      max-width: 90%;
      padding-top: 50px;
      padding-bottom: 90px;
    }
    .page-tour.phase-3:not(.page-node) .l-content .view-events {
      max-width: 1100px;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
    .page-tour.phase-3:not(.page-node) .l-content .node--event {
      color: #fff;
      border-bottom: 0;
    }
    .page-tour.phase-3:not(.page-node) .l-content .node--event .group-left {
      width: 120px;
    }
    .page-tour.phase-3:not(.page-node) .l-content .node--event .group-middle {
      width: calc(100% - 300px);
      letter-spacing: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
    }
    .page-tour.phase-3:not(.page-node) .l-content .node--event .group-right {
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field--name-field-event-date
      span {
      background: #fff;
      font-weight: 700;
      font-size: 1.09375rem;
      text-transform: uppercase;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field--name-asf-events-venue-title {
      color: #fff !important;
      font-family: 'Druk Wide Web', futura-pt, HelveticaNeue-Light,
        'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      font-size: 1.71875rem;
      text-transform: uppercase;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field--name-asf-events-city {
      font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
      font-size: 1.40625rem;
      text-transform: uppercase;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field--type-text-with-summary {
      width: 100%;
      padding: 0;
      font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field--type-text-with-summary
      p {
      margin-bottom: 0;
      font-size: 18px;
      letter-spacing: 0.03em;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field--name-asf-events-tickets
      a {
      padding-top: 1px;
      background: #000;
      color: #fff;
      font-family: 'Druk Wide Web', futura-pt, HelveticaNeue-Light,
        'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      font-size: 0.9375rem;
      border: 0;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field--name-asf-events-tickets
      a:hover {
      background: #fff;
      color: #000;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .node--event
      .field.field--name-field-on-sale-info {
      font-size: 12px;
      letter-spacing: 0;
      font-family: 'Druk Wide Web', sans-serif;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product {
      position: relative;
      text-align: center;
      color: #fff;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-field-clickthru-url {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-field-clickthru-url
      a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-field-external-product-status {
      margin-bottom: 12px;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-field-external-product-status
      .field__items {
      text-align: center;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-field-external-product-status
      .field__item {
      padding: 2px 6px 1px 8px;
      background: #fff;
      color: #000;
      font-weight: 700;
      font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
      font-size: 0.9375rem;
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-bolero-title
      h3 {
      color: #fff;
      font-size: 1.5625rem;
      font-family: 'Druk Wide Web', futura-pt, HelveticaNeue-Light,
        'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      text-transform: uppercase;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-field-external-product-price {
      padding-bottom: 14px;
      font-size: 1.40625rem;
      font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-node-link
      a {
      padding: 8px 45px;
      background: #000;
      color: #fff;
      font-family: 'Druk Wide Web', futura-pt, HelveticaNeue-Light,
        'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
        'Lucida Grande', sans-serif;
      text-transform: uppercase;
    }
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product
      .field--name-node-link
      a:hover,
    .page-tour.phase-3:not(.page-node)
      .l-content
      .block--views-ac121d74243b1daf647d076a51492453
      .node--external-product:hover
      .field--name-node-link
      a {
      background: #fff;
      color: #000;
    }
    @media screen and (min-width: 20.0625em) {
      .bolero-col--sm-1 .views-row {
        width: calc(100% - 2 * 0.5rem);
      }
      .node.ds-3col-equal > div:not(.contextual-links-wrapper) {
        padding-left: 0.78125rem;
        padding-right: 0.78125rem;
      }
    }
    @media screen and (min-width: 20.0625em) and (max-width: 37.4375em) {
      .hide-for-small-only {
        display: none !important;
      }
    }
    @media screen and (max-width: 20em), screen and (min-width: 37.5em) {
      .show-for-small-only {
        display: none !important;
      }
    }
    @media screen and (min-width: 37.5em) {
      h1 {
        font-size: 3.28125rem;
      }
      h2 {
        font-size: 2.8125rem;
      }
      h3 {
        font-size: 2.34375rem;
      }
      .l-region--navigation > .block {
        margin: 0 5px;
      }
      .bolero-col--md-3 .views-row {
        width: calc(33.33333% - 2 * 0.5rem);
      }
      .l-columns.inner-wrap {
        width: 90%;
      }
      .node.ds-3col-equal > div:not(.contextual-links-wrapper) {
        padding-left: 1.17188rem;
        padding-right: 1.17188rem;
      }
      .node--event .field--name-asf-events-city {
        font-size: 14px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field.field--name-field-on-sale-info {
        white-space: nowrap;
        position: absolute;
        bottom: -25px;
        margin-right: 10px !important;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .block--views-ac121d74243b1daf647d076a51492453
        .node--external-product {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: column;
        flex-flow: column;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .block--views-ac121d74243b1daf647d076a51492453
        .node--external-product
        .field--name-field-external-product-picture {
        margin-bottom: auto;
      }
    }
    @media screen and (min-width: 37.5em) and (max-width: 61.9375em) {
      .l-navigation.top-bar {
        padding: 0.5625rem;
      }
    }
    @media screen and (min-width: 62em) {
      h1 {
        font-size: 3.75rem;
      }
      h2 {
        font-size: 3.125rem;
      }
      h3 {
        font-size: 2.5rem;
      }
      .top-bar {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
      .l-header .l-navigation.top-bar {
        -ms-flex-flow: nowrap;
        flex-flow: nowrap;
      }
      .l-header .l-branding {
        height: 55px;
        display: -ms-flexbox;
        display: flex;
        width: calc(100% - 441px);
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-align: center;
        align-items: center;
      }
      .l-header .l-region--branding {
        display: inline-block;
        width: calc(100% - 250px);
        max-width: 640px;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      .l-header .l-region--navigation {
        height: 55px;
        -ms-flex-align: center;
        align-items: center;
        max-width: 440px;
      }
      .l-header .l-region--navigation a.icon {
        font-size: calc(0.92593vw + 7.04px);
      }
      .site-name > a {
        background-size: 70%;
        top: 0;
      }
      a.menu-icon {
        display: none;
      }
      .block--menu-block-asf-common-mm-nav
        .menu-block-asf_common_mm_nav
        > .menu
        > li:not(.site-name) {
        display: inline-block;
      }
      .l-region--navigation > .block {
        padding: 0 0.5rem;
      }
      .l-page-wrapper {
        padding-top: 55px;
      }
      .not-front .l-header + .l-help {
        padding-top: 55px;
      }
      .node.node--event.ds-3col-equal > div {
        padding-top: 0;
      }
      .node.node--event.ds-3col-equal > div.group-left {
        width: 80px;
      }
      .node.node--event.ds-3col-equal > div.group-middle {
        width: calc(100% - 261px);
        letter-spacing: 0.05em;
      }
      .node.node--event.ds-3col-equal > div.group-right {
        width: 180px;
        padding: 0;
        text-align: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
      .node.node--event.ds-3col-equal > div.group-right a {
        float: right;
      }
      .node.node--event.ds-3col-equal > div.group-middle > div {
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: 45%;
        float: left;
        padding-right: 10px;
      }
      .node.node--event.ds-3col-equal > div.group-middle > div:last-child {
        padding-left: 10px;
        width: 55%;
        clear: none;
        font-size: 100%;
      }
      .node.node--event.ds-3col-equal
        > div.group-middle
        > div:last-child:before {
        color: #444;
        float: left;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event {
        -ms-flex-align: start;
        align-items: flex-start;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-left {
        padding-top: 10px;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-middle {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .group-middle
        > div.field--name-asf-events-venue-title {
        width: 55%;
        padding-right: 20px;
        line-height: 1.3;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .group-middle
        > div.field--name-asf-events-city {
        width: 45%;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-right {
        padding-top: 12px;
      }
    }
    @media screen and (min-width: 62em) and (max-width: 74.9375em) {
      .l-navigation.top-bar {
        padding: 0 0.5625rem;
      }
      .block--menu-block-asf-common-mm-nav {
        max-width: 450px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field.field--name-field-on-sale-info {
        bottom: -45px;
      }
    }
    @media screen and (min-width: 62em) and (max-width: 320px) {
      .l-header .l-region--navigation a.icon {
        font-size: 10px;
      }
    }
    @media screen and (min-width: 62em) and (min-width: 1400px) {
      .l-header .l-region--navigation a.icon {
        font-size: 20px;
      }
    }
    @media screen and (min-width: 75em) {
      .site-name {
        padding: 0 0.5625rem;
      }
      .l-navigation.top-bar {
        padding: 0 1.125rem;
      }
    }
    @media (min-width: 1400px) {
      .block__title {
        font-size: 27px;
      }
    }
    @media (max-width: 320px) {
      .block__title {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 74.9375em) {
      .node.node--event.ds-3col-equal {
        font-size: 80%;
        padding: 10px 0 25px;
      }
      .page-tour.phase-3:not(.page-node) .l-content .view-events {
        padding-left: 0;
        padding-right: 0;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field--name-asf-events-venue-title {
        font-size: 20px;
      }
    }
    @media screen and (max-width: 61.9375em) {
      aside[class*='-off-canvas'] {
        background: #f2f2f2;
      }
      .sticky {
        max-height: 60px;
      }
      .l-region--navigation {
        position: absolute;
        right: 15px;
      }
      .node.node--event.ds-3col-equal > div.group-left {
        font-size: 100%;
        width: 60px;
        padding: 0;
        text-align: left;
      }
      .node.node--event.ds-3col-equal > div.group-middle {
        width: calc(100% - 161px);
        padding: 0 5px;
      }
      .node.node--event.ds-3col-equal > div.group-right {
        width: 95px;
        padding: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
      }
      .node.node--event.ds-3col-equal > div.group-right > .field {
        padding: 0;
        margin: 5px 0;
      }
      .node.node--event.ds-3col-equal > div.group-right a {
        padding: 0 5px;
        line-height: 21px;
        height: 25px;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-left,
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-middle,
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-right {
        width: 100%;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-middle {
        letter-spacing: 0;
        display: block;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: column;
        flex-flow: column;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .group-middle
        > div,
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .group-middle
        > div:last-child {
        width: 100%;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event .group-right {
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
      .page-tour.phase-3:not(.page-node) .l-content .node--event .field {
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field--name-field-event-date
        span {
        display: inline-block;
        width: auto;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field--name-asf-events-venue-title {
        -ms-flex-order: 1;
        order: 1;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field--name-asf-events-city {
        -ms-flex-order: 3;
        order: 3;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field--name-asf-events-city
        .field__item {
        -ms-flex-pack: center;
        justify-content: center;
      }
      .page-tour.phase-3:not(.page-node)
        .l-content
        .node--event
        .field--type-text-with-summary {
        -ms-flex-order: 2;
        order: 2;
      }
    }
    @media screen and (max-width: 37.4375em) {
      .l-header {
        transform: translate3d(0, 0, 0);
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_header {
        height: calc(100vh - 50px);
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_header:after {
        background: url(//cache.umusic.com/_sites/_halo/theweeknd/images/tour-image-desktop.jpg)
          center/cover no-repeat #000;
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_header
        .tour-logo {
        max-width: 200px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_header
        .title {
        bottom: 100px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_header
        .title
        .show-for-small-only {
        max-width: 340px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_header
        .sponsors {
        bottom: 50px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_content
        .sponsors {
        display: none;
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_copy {
        padding: 40px 15px 1px;
      }
      .page-tour.phase-3:not(.page-node)
        .l-page
        .block--block-116
        .tour-block_copy
        p:first-child {
        font-size: 18px;
      }
    }
    @media print {
      img {
        page-break-inside: avoid;
      }
      a[href^='#']:after {
        content: '';
      }
      * {
        background: 0 0 !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
      }
      a,
      a:visited {
        text-decoration: underline;
      }
      a[href]:after {
        content: ' (' attr(href) ')';
      }
      a[href^='#']:after {
        content: '';
      }
      img {
        max-width: 100% !important;
      }
      h2,
      h3,
      p {
        orphans: 3;
        widows: 3;
      }
      h2,
      h3 {
        page-break-after: avoid;
      }
    }
  }
  /*! CSS Used from: https://www.theweeknd.com/sites/g/files/aaj15616/f/css/css_gS35J00Qc2bUEcccBPWfr0Dw2EQCmDfNQM5TfqjXmDo.css ; media=all */
  @media all {
    .ds-3col-equal > .group-left {
      width: 33%;
      float: left;
    }
    .ds-3col-equal > .group-middle {
      width: 34%;
      float: left;
    }
    .ds-3col-equal > .group-right {
      width: 33%;
      float: right;
    }
  }
  /*! CSS Used from: Embedded */
  .tour-block_header .title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .copies.mobile {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .copies.desktop {
      display: none;
    }
    .copies.mobile {
      display: block;
    }
  }
  /*! CSS Used from: Embedded */
  .node--event .field--name-asf-events-city .field__item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  /*! CSS Used keyframes */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /*! CSS Used fontfaces */
  @font-face {
    font-family: fontello;
    src: url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.eot?21517319);
    src: url(https://cache.umusic.com/_sites/_asf/icons/fontello.eot?21517319#iefix)
        format('embedded-opentype'),
      url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.woff?21517319)
        format('woff'),
      url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.ttf?21517319)
        format('truetype'),
      url(https://cache.umusic.com/_sites/_asf/icons/font/fontello.svg?21517319#fontello)
        format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Druk Wide Web';
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.eot);
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.eot?#iefix)
        format('embedded-opentype'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.woff2)
        format('woff2'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/DrukWide-Medium-Web.woff)
        format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: 'Druk Web';
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.eot);
    src: url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.eot?#iefix)
        format('embedded-opentype'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.woff2)
        format('woff2'),
      url(https://cache.umusic.com/_sites/theweeknd.com/fonts/druk/Druk-Bold-Web.woff)
        format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: 'Nimbus Sans L';
    src: url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Bol.eot);
    src: url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Bol.eot?#iefix)
        format('embedded-opentype'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Bol.woff2)
        format('woff2'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Bol.woff)
        format('woff'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Bol.ttf)
        format('truetype'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Bol.svg#NimbusSanL-Bol)
        format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Nimbus Sans L';
    src: url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Reg.eot);
    src: url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Reg.eot?#iefix)
        format('embedded-opentype'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Reg.woff2)
        format('woff2'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Reg.woff)
        format('woff'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Reg.ttf)
        format('truetype'),
      url(//cache.umusic.com/_sites/theweeknd.com/fonts/nimbus/NimbusSanL-Reg.svg#NimbusSanL-Reg)
        format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  &.move-right {
    left: 0;
  }
  &.move-right .left-off-canvas-menu {
    left: 100% !important;
    ${menuSlidesAnimation}
  }
`;
