export const concerts = [
  {
    city: 'São Paulo',
    country: 'Brazil',
    stadiumName: 'Estádio Morumbis',
    date: 'Sat, Sept 7',
    location: 'Brazil',
  },

];
