const Hamburg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 755 630'
      className='hamburg'>
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.cls-1{fill:#464646;}.cls-2,.cls-4{font-size:13px;}.cls-2,.cls-4,.cls-5,.cls-6{fill:#fff;font-family:Helvetica-Light, Helvetica;font-weight:300;}.cls-3{fill:#999;stroke:#fff;stroke-miterlimit:10;}.cls-4{letter-spacing:0em;}.cls-5{font-size:14px;}.cls-6{font-size:16px;}',
          }}
        />
      </defs>
      <g id='Stage'>
        <polygon
          id='_0Stage'
          data-name='0Stage'
          className='cls-1 svg_shape'
          points='488.47 293.04 235.74 293.04 235.74 246.04 186.52 246.04 186.52 366.61 235.74 366.61 235.74 319.41 488.47 319.41 488.47 293.04'
        />
        <g id='_0Stage_text' data-name='0Stage_text'>
          <text
            className='cls-2 text'
            transform='translate(213.23 320.89) rotate(-90) scale(0.99 1)'>
            Stage
          </text>
        </g>
      </g>
      <g id='Front_Floor'>
        <polygon
          id='_0FrontFloor'
          data-name='Front Floor'
          className='cls-3 svg_shape has_listing active_block default_color'
          points='242.65 326.25 242.65 372.58 227.65 372.58 227.65 416.6 242.65 416.6 386.88 416.6 386.88 326.25 242.65 326.25'
          style={{ fill: 'rgb(44, 105, 187)' }}
        />
        <polygon
          id='_0FrontFloor-2'
          data-name='Front Floor'
          className='cls-3 svg_shape has_listing active_block default_color'
          points='227.65 195.58 227.65 239.6 242.65 239.6 242.65 285.71 386.88 285.71 386.88 195.58 227.65 195.58'
          style={{ fill: 'rgb(44, 105, 187)' }}
        />
        <g id='_0FrontFloor_text' data-name='Front Floor'>
          <text
            className='cls-2 text'
            transform='translate(275.37 248.11) scale(0.99 1)'
            data-name='Front Floor'>
            F
          </text>
          <text
            className='cls-2 text'
            transform='translate(282.52 248.11) scale(0.69 0.7)'
            data-name='Front Floor'>
            RONT
          </text>
          <text
            className='cls-4 text'
            transform='translate(307.03 248.11) scale(0.99 1)'
            data-name='Front Floor'>
            <tspan xmlSpace='preserve' className='text'>
              {' '}
              F
            </tspan>
          </text>
          <text
            className='cls-2 text'
            transform='translate(317.76 248.11) scale(0.69 0.7)'
            data-name='Front Floor'>
            LOOR
          </text>
        </g>
      </g>
      <g id='Back_Floor'>
        <polygon
          id='_0BackFloor'
          data-name='Back Floor'
          className='cls-3 svg_shape has_listing active_block default_color'
          points='525.24 195.85 525.24 195.58 386.88 195.58 386.88 285.71 492.38 285.71 492.38 326.25 386.88 326.25 386.88 416.6 525.24 416.6 540.24 416.6 540.24 195.85 525.24 195.85'
          style={{ fill: 'rgb(44, 105, 187)' }}
        />
        <g id='_0BackFloor_text' data-name='Back Floor'>
          <text
            className='cls-2 text'
            transform='translate(427.66 248.11) scale(0.99 1)'
            data-name='Back Floor'>
            B
          </text>
          <text
            className='cls-2 text'
            transform='translate(436.24 248.11) scale(0.69 0.7)'
            data-name='Back Floor'>
            ACK
          </text>
          <text
            className='cls-4 text'
            transform='translate(454.74 248.11) scale(0.99 1)'
            data-name='Back Floor'>
            <tspan xmlSpace='preserve' className='text'>
              {' '}
              F
            </tspan>
          </text>
          <text
            className='cls-2 text'
            transform='translate(465.47 248.11) scale(0.69 0.7)'
            data-name='Back Floor'>
            LOOR
          </text>
        </g>
      </g>
      <g id='Lower_Tier'>
        <g
          id='_1A'
          data-name='1A'
          className='no_listing disabled_color active_block'>
          <path
            id='_1A-2'
            data-name='1A'
            className='cls-3 svg_shape'
            d='M384.46,252.56,375,197.78c-35.08,2.53-61.31,4.8-61.31,4.8l-6.57-9.6,31.49,62.76C345.84,255.21,363.13,254,384.46,252.56Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_1A_text' data-name='1A_text'>
            <text
              className='cls-2 text'
              transform='translate(206.39 155.49) scale(0.99 1)'>
              1A
            </text>
          </g>
        </g>
        <g
          id='_2A'
          data-name='2A'
          className='has_listing active_block default_color'>
          <path
            id='_2A-2'
            data-name='2A'
            className='cls-3 svg_shape'
            d='M434.81,249.37l-5.64-55.17c-18.81,1.11-37.48,2.37-54.15,3.58l9.44,54.78C399.9,251.52,417.44,250.4,434.81,249.37Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_2A_text' data-name='2A'>
            <text
              className='cls-2 text'
              transform='translate(260.55 152.28) scale(0.99 1)'
              data-name='2A'>
              2A
            </text>
          </g>
        </g>
        <g
          id='_3A'
          data-name='3A'
          className='no_listing disabled_color active_block'>
          <path
            id='_3A-2'
            data-name='3A'
            className='cls-3 svg_shape'
            d='M486,246.72l-2.12-55.16c-17.15.58-36,1.53-54.71,2.64l5.64,55.17C453,248.3,470.88,247.34,486,246.72Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_3A_text' data-name='3A_text'>
            <text
              className='cls-2 text'
              transform='translate(313.22 148.88) scale(0.99 1)'>
              3A
            </text>
          </g>
        </g>
        <g
          id='_4A'
          data-name='4A'
          className='no_listing disabled_color active_block'>
          <path
            id='_4A-2'
            data-name='4A'
            className='cls-3 svg_shape'
            d='M512,246c6.51,0,15,.12,24.61.4l1.69-55c-8.56-.27-16.31-.43-22.84-.43-9.41,0-20.12.23-31.58.62L486,246.72C496.23,246.3,505.17,246,512,246Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_4A_text' data-name='4A_text'>
            <text
              className='cls-2 text'
              transform='translate(366.51 147.62) scale(0.99 1)'>
              4A
            </text>
          </g>
        </g>
        <g
          id='_5A'
          data-name='5A'
          className='no_listing disabled_color active_block'>
          <path
            id='_5A-2'
            data-name='5A'
            className='cls-3 svg_shape'
            d='M585.65,248.38l7-54.5c-19.32-1.09-38.27-2-54.34-2.51l-1.69,55C551.05,246.83,568.19,247.55,585.65,248.38Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_5A_text' data-name='5A_text'>
            <text
              className='cls-2 text'
              transform='translate(418.26 149.01) scale(0.99 1)'>
              5A
            </text>
          </g>
        </g>
        <g
          id='_7A'
          data-name='7A'
          className='no_listing disabled_color active_block'>
          <path
            id='_7A-2'
            data-name='7A'
            className='cls-3 svg_shape'
            d='M680.22,253.58,716.38,186l-8.45,15.62s-25.61-2-59.12-4.24l-10.38,53.75C656.69,252.16,671.93,253.08,680.22,253.58Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_7A_text' data-name='7A_text'>
            <text
              className='cls-2 text'
              transform='translate(524.72 155.49) scale(0.99 1)'>
              7A
            </text>
          </g>
        </g>
        <g
          id='_8A'
          data-name='8A'
          className='has_listing active_block default_color'>
          <path
            id='_8A-2'
            data-name='8A'
            className='cls-3 svg_shape'
            d='M705.45,219.49l38.62,40.74L761,257.68,764.87,246,712,193.55l-13.28,25.52Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_8A_text' data-name='8A'>
            <text
              className='cls-2 text'
              transform='translate(587.41 160.84) scale(0.99 1)'
              data-name='8A'>
              8A
            </text>
          </g>
        </g>
        <g
          id='_9A'
          data-name='9A'
          className='no_listing disabled_color active_block'>
          <path
            id='_9A-2'
            data-name='9A'
            className='cls-3 svg_shape'
            d='M701.27,311.54l62.2-3.34C761.73,282.53,760,260,760,260l1-2.32L699,267S700.1,287.25,701.27,311.54Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_9A_text' data-name='9A_text'>
            <text
              className='cls-2 text'
              transform='translate(586.87 215.9) scale(0.99 1)'>
              9A
            </text>
          </g>
        </g>
        <g
          id='_10A'
          data-name='10A'
          className='no_listing disabled_color active_block'>
          <path
            id='_10A-2'
            data-name='10A'
            className='cls-3 svg_shape'
            d='M703.28,357.57l63.21,1.17c-.65-14.25-1.84-33.11-3-50.54l-62.2,3.34C702,326.62,702.75,343.25,703.28,357.57Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_10A_text' data-name='10A_text'>
            <text
              className='cls-2 text'
              transform='translate(585.7 264.51) scale(0.99 1)'>
              10A
            </text>
          </g>
        </g>
        <g
          id='_11A'
          data-name='11A'
          className='no_listing disabled_color active_block'>
          <path
            id='_11A-2'
            data-name='11A'
            className='cls-3 svg_shape'
            d='M704,384c0,5.17,0,12.23-.18,20.32l62.9,1.95c.2-12.85.31-23.9.28-31.27,0-4-.21-9.64-.51-16.26l-63.21-1.17C703.67,368.18,704,377.52,704,384Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_11A_text' data-name='11A_text'>
            <text
              className='cls-2 text'
              transform='translate(586.7 312.63) scale(0.99 1)'>
              11A
            </text>
          </g>
        </g>
        <g
          id='_12A'
          data-name='12A'
          className='no_listing disabled_color active_block'>
          <path
            id='_12A-2'
            data-name='12A'
            className='cls-3 svg_shape'
            d='M702.55,452.18l63.16,6.55c.39-17.91.77-36.57,1-52.46l-62.9-1.95C703.56,418.66,703.06,436.21,702.55,452.18Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_12A_text' data-name='12A_text'>
            <text
              className='cls-2 text'
              transform='translate(586.7 360.85) scale(0.99 1)'>
              12A
            </text>
          </g>
        </g>
        <g
          id='_13A'
          data-name='13A'
          className='no_listing disabled_color active_block'>
          <path
            id='_13A-2'
            data-name='13A'
            className='cls-3 svg_shape'
            d='M701.07,495.27l63.46,13.1c.33-13.05.76-30.93,1.18-49.64l-63.16-6.55C701.91,472.28,701.27,489.85,701.07,495.27Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_13A_text' data-name='13A_text'>
            <text
              className='cls-2 text'
              transform='translate(585.45 408.47) scale(0.99 1)'>
              13A
            </text>
          </g>
        </g>
        <g
          id='_14A'
          data-name='14A'
          className='has_listing active_block default_color'>
          <path
            id='_14A-2'
            data-name='14A'
            className='cls-3 svg_shape'
            d='M701,497l-13,14-3,.16,32,64.68L764,529s.22-8.08.53-20.63l-63.46-13.1C701,496.39,701,497,701,497Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_14A_text' data-name='14A'>
            <text
              className='cls-2 text'
              transform='translate(575.11 456.45) scale(0.99 1)'
              data-name='14A'>
              14A
            </text>
          </g>
        </g>
        <g
          id='_15A'
          data-name='15A'
          className='no_listing disabled_color active_block'>
          <path
            id='_15A-2'
            data-name='15A'
            className='cls-3 svg_shape'
            d='M638.38,513.54,648,576l1,6,68-6.09.07-.07-32-64.68C678.27,511.52,660.45,512.46,638.38,513.54Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_15A_text' data-name='15A_text'>
            <text
              className='cls-2 text'
              transform='translate(523.78 475.71) scale(0.99 1)'>
              15A
            </text>
          </g>
        </g>
        <g
          id='_16A'
          data-name='16A'
          className='no_listing disabled_color active_block'>
          <path
            id='_16A-2'
            data-name='16A'
            className='cls-3 svg_shape'
            d='M587.68,515.85l7.67,64.56c31.9-2,52.65-4.41,52.65-4.41l-9.62-62.46C622.79,514.29,605.09,515.12,587.68,515.85Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_16A_text' data-name='16A_text'>
            <text
              className='cls-2 text'
              transform='translate(469.29 477.14) scale(0.99 1)'>
              16A
            </text>
          </g>
        </g>
        <g
          id='_17A'
          data-name='17A'
          className='no_listing disabled_color active_block'>
          <path
            id='_17A-2'
            data-name='17A'
            className='cls-3 svg_shape'
            d='M538.12,517.62l2.73,65.1c20.13-.43,38.69-1.32,54.5-2.31l-7.67-64.56C570,516.59,552.67,517.24,538.12,517.62Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_17A_text' data-name='17A_text'>
            <text
              className='cls-2 text'
              transform='translate(417.8 479.62) scale(0.99 1)'>
              17A
            </text>
          </g>
        </g>
        <g
          id='_18A'
          data-name='18A'
          className='no_listing disabled_color active_block'>
          <path
            id='_18A-2'
            data-name='18A'
            className='cls-3 svg_shape'
            d='M514,518c-7.1,0-16.46-.23-27.17-.57L485,582.71q14.26.27,30.22.29c8.76,0,17.34-.1,25.64-.28l-2.73-65.1C528.67,517.87,520.39,518,514,518Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_18A_text' data-name='18A_text'>
            <text
              className='cls-2 text'
              transform='translate(364.99 480.41) scale(0.99 1)'>
              18A
            </text>
          </g>
        </g>
        <g
          id='_19A'
          data-name='19A'
          className='no_listing disabled_color active_block'>
          <path
            id='_19A-2'
            data-name='19A'
            className='cls-3 svg_shape'
            d='M437.61,515.42l-6.46,65.23c15,.89,33.06,1.66,53.84,2.06l1.84-65.28C472.2,517,455,516.23,437.61,515.42Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_19A_text' data-name='19A_text'>
            <text
              className='cls-2 text'
              transform='translate(311.06 478.62) scale(0.99 1)'>
              19A
            </text>
          </g>
        </g>
        <g
          id='_20A'
          data-name='20A'
          className='no_listing disabled_color active_block'>
          <path
            id='_20A-2'
            data-name='20A'
            className='cls-3 svg_shape'
            d='M385.15,512.79,378,576s18.69,2.62,53.15,4.65l6.46-65.23C419.55,514.58,401.23,513.64,385.15,512.79Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_20A_text' data-name='20A_text'>
            <text
              className='cls-2 text'
              transform='translate(259.87 475.78) scale(0.99 1)'>
              20A
            </text>
          </g>
        </g>
        <g
          id='_21A'
          data-name='21A'
          className='no_listing disabled_color active_block'>
          <path
            id='_21A-2'
            data-name='21A'
            className='cls-3 svg_shape'
            d='M338.43,510.2l-30.73,57,9.32,8.68,57.26,5L378,576l7.15-63.21C363.28,511.63,345.58,510.61,338.43,510.2Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_21A_text' data-name='21A_text'>
            <text
              className='cls-2 text'
              transform='translate(202.82 474.57) scale(0.99 1)'>
              21A
            </text>
          </g>
        </g>
        <g
          id='_22A'
          data-name='22A'
          className='no_listing disabled_color active_block'>
          <path
            id='_22A-2'
            data-name='22A'
            className='cls-3 svg_shape'
            d='M335,510l-12-12s0-.26,0-.74l-63.12,10c.78,10.22,1.29,16.63,1.29,16.63l46.56,43.34,30.73-57Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_22A_text' data-name='22A_text'>
            <text
              className='cls-2 text'
              transform='translate(153.39 454.13) scale(0.99 1)'>
              22A
            </text>
          </g>
        </g>
        <g
          id='_23A'
          data-name='23A'
          className='no_listing disabled_color active_block'>
          <path
            id='_23A-2'
            data-name='23A'
            className='cls-3 svg_shape'
            d='M321.07,453.33l-64.41,10.24c1.17,16.87,2.34,32.55,3.19,43.69l63.12-10C322.79,493.41,321.94,474.87,321.07,453.33Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_23A_text' data-name='23A_text'>
            <text
              className='cls-2 text'
              transform='translate(141.87 408.98) scale(0.99 1)'>
              23A
            </text>
          </g>
        </g>
        <g
          id='_24A'
          data-name='24A'
          className='no_listing disabled_color active_block'>
          <path
            id='_24A-2'
            data-name='24A'
            className='cls-3 svg_shape'
            d='M319.35,404.23l-66,6.83c.89,16.34,2.11,35.06,3.33,52.51l64.41-10.24C320.41,437,319.74,418.9,319.35,404.23Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_24A_text' data-name='24A_text'>
            <text
              className='cls-2 text'
              transform='translate(139.26 363.36) scale(0.99 1)'>
              24A
            </text>
          </g>
        </g>
        <g
          id='_25A'
          data-name='25A'
          className='no_listing disabled_color active_block'>
          <path
            id='_25A-2'
            data-name='25A'
            className='cls-3 svg_shape'
            d='M319,384c0-6.3.18-15.32.47-25.59l-67.11,1.27c-.23,6.09-.36,11.45-.36,15.74,0,8.14.53,20.92,1.33,35.64l66-6.83C319.14,396.18,319,389.16,319,384Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_25A_text' data-name='25A_text'>
            <text
              className='cls-2 text'
              transform='translate(137.8 313.7) scale(0.99 1)'>
              25A
            </text>
          </g>
        </g>
        <g
          id='_26A'
          data-name='26A'
          className='no_listing disabled_color active_block'>
          <path
            id='_26A-2'
            data-name='26A'
            className='cls-3 svg_shape'
            d='M321.1,311.74,255.25,308c-1.23,18.39-2.34,37.14-2.89,51.7l67.11-1.27C319.88,344,320.5,327.07,321.1,311.74Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_26A_text' data-name='26A_text'>
            <text
              className='cls-2 text'
              transform='translate(137.8 264.51) scale(0.99 1)'>
              26A
            </text>
          </g>
        </g>
        <g
          id='_27A'
          data-name='27A'
          className='no_listing disabled_color active_block'>
          <path
            id='_27A-2'
            data-name='27A'
            className='cls-3 svg_shape'
            d='M323,267l-64.12-9.06c-1,13.12-2.38,31.39-3.63,50l65.85,3.76C322.07,287.36,323,267,323,267Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_27A_text' data-name='27A_text'>
            <text
              className='cls-2 text'
              transform='translate(141.87 217.94) scale(0.99 1)'>
              27A
            </text>
          </g>
        </g>
        <g
          id='_6A'
          data-name='6A'
          className='no_listing disabled_color active_block'>
          <path
            id='_6A-2'
            data-name='6A'
            className='cls-3 svg_shape'
            d='M585.65,248.38c18.16.87,36.65,1.86,52.78,2.76l10.38-53.75c-17.42-1.18-37-2.42-56.17-3.51Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_6A_text' data-name='6A_text'>
            <text
              className='cls-2 text'
              transform='translate(472.87 152) scale(0.99 1)'>
              6A
            </text>
          </g>
        </g>
        <g
          id='_28A'
          data-name='28A'
          className='no_listing disabled_color active_block'>
          <path
            id='_28A-2'
            data-name='28A'
            className='cls-3 svg_shape'
            d='M319.29,224.49s1.25,0,3.59-.13L307.14,193l1.84,3.68L260.42,238.5s-.63,7.64-1.54,19.44l19,2.68Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_28A_text' data-name='28A_text'>
            <text
              className='cls-2 text'
              transform='translate(141.87 160.84) scale(0.99 1)'>
              28A
            </text>
          </g>
        </g>
      </g>
      <g id='Middle_Tier'>
        <g
          id='_28B'
          data-name='28B'
          className='no_listing disabled_color active_block'>
          <path
            id='_28B-2'
            data-name='28B'
            className='cls-3 svg_shape'
            d='M252.39,233.07,239,228l48-49s9-.66,23.45-1.67l-3.05-29c-19.85,1.42-32.65,2.38-32.65,2.38L208.93,216s-1.14,14.16-2.66,34.5l44.27,6.25C251.62,242.55,252.39,233.07,252.39,233.07Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_28B_text' data-name='28B_text'>
            <text
              className='cls-5 text'
              transform='translate(103.54 123.74) scale(0.99 1)'>
              28B
            </text>
          </g>
        </g>
        <g
          id='_27B'
          data-name='27B'
          className='no_listing disabled_color active_block'>
          <path
            id='_27B-2'
            data-name='27B'
            className='cls-3 svg_shape'
            d='M250.54,256.76l-44.27-6.25c-1.16,15.69-2.55,35.06-3.8,54.45l44.45,2.54C248.13,289,249.48,270.63,250.54,256.76Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_27B_text' data-name='27B_text'>
            <text
              className='cls-2 text'
              transform='translate(78.57 208.81) scale(0.99 1)'>
              27B
            </text>
          </g>
        </g>
        <g
          id='_26B'
          data-name='26B'
          className='no_listing disabled_color active_block'>
          <path
            id='_26B-2'
            data-name='26B'
            className='cls-3 svg_shape'
            d='M246.92,307.5,202.47,305c-1.28,19.88-2.42,39.76-3,55.72l44.68-.84C244.59,345.43,245.69,326.41,246.92,307.5Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_26B_text' data-name='26B_text'>
            <text
              className='cls-2 text'
              transform='translate(75.24 262.51) scale(0.99 1)'>
              26B
            </text>
          </g>
        </g>
        <g
          id='_25B'
          data-name='25B'
          className='no_listing disabled_color active_block'>
          <path
            id='_25B-2'
            data-name='25B'
            className='cls-3 svg_shape'
            d='M243.84,373.27c0-3.75.09-8.3.27-13.43l-44.68.84c-.31,8-.48,15-.48,20.49,0,8.39.46,20.86,1.18,35.39l45.19-4.67C244.43,395.56,243.84,381.63,243.84,373.27Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_25B_text' data-name='25B_text'>
            <text
              className='cls-2 text'
              transform='translate(72.63 316.69) scale(0.99 1)'>
              25B
            </text>
          </g>
        </g>
        <g
          id='_24B'
          data-name='24B'
          className='no_listing disabled_color active_block'>
          <path
            id='_24B-2'
            data-name='24B'
            className='cls-3 svg_shape'
            d='M245.32,411.89l-45.19,4.67c.84,16.79,2,36.31,3.29,55.47l45.21-7.19C247.41,446.89,246.21,428.19,245.32,411.89Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_24B_text' data-name='24B_text'>
            <text
              className='cls-2 text'
              transform='translate(76.44 372.29) scale(0.99 1)'>
              24B
            </text>
          </g>
        </g>
        <g
          id='_23B'
          data-name='23B'
          className='no_listing disabled_color active_block'>
          <path
            id='_23B-2'
            data-name='23B'
            className='cls-3 svg_shape'
            d='M248.63,464.84,203.42,472c1.29,19.54,2.66,38.69,3.79,54.13l45.41-5.44C251.58,506.77,250.09,486.35,248.63,464.84Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_23B_text' data-name='23B_text'>
            <text
              className='cls-2 text'
              transform='translate(80.08 425.76) scale(0.99 1)'>
              23B
            </text>
          </g>
        </g>
        <g
          id='_22B'
          data-name='22B'
          className='no_listing disabled_color active_block'>
          <path
            id='_22B-2'
            data-name='22B'
            className='cls-3 svg_shape'
            d='M295,580l-41-41s-.55-7-1.38-18.28l-45.41,5.44c1.48,20.23,2.57,34.1,2.57,34.1L276,623s13.14,1.23,33.39,3l4.06-44.66Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_22B_text' data-name='22B_text'>
            <text
              className='cls-2 text'
              transform='translate(108.72 502.99) scale(0.99 1)'>
              22B
            </text>
          </g>
        </g>
        <g
          id='_21B'
          data-name='21B'
          className='no_listing disabled_color active_block'>
          <path
            id='_21B-2'
            data-name='21B'
            className='cls-3 svg_shape'
            d='M313.45,581.38,309.39,626c15.92,1.41,36.23,3.18,58,5l2.88-45.58C347.62,583.88,327.26,582.4,313.45,581.38Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_21B_text' data-name='21B_text'>
            <text
              className='cls-2 text'
              transform='translate(191.91 536) scale(0.99 1)'>
              21B
            </text>
          </g>
        </g>
        <g
          id='_20B'
          data-name='20B'
          className='no_listing disabled_color active_block'>
          <path
            id='_20B-2'
            data-name='20B'
            className='cls-3 svg_shape'
            d='M370.3,585.44,367.42,631c18.95,1.56,39,3.14,58.3,4.54l4.57-46.2C410.34,588.14,389.49,586.76,370.3,585.44Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_20B_text' data-name='20B_text'>
            <text
              className='cls-2 text'
              transform='translate(250.92 539.76) scale(0.99 1)'>
              20B
            </text>
          </g>
        </g>
        <g
          id='_19B'
          data-name='19B'
          className='no_listing disabled_color active_block'>
          <path
            id='_19B-2'
            data-name='19B'
            className='cls-3 svg_shape'
            d='M430.29,589.36l-4.57,46.2c21,1.52,41,2.81,57.68,3.6l1.32-46.89C469.15,591.61,450.16,590.57,430.29,589.36Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_19B_text' data-name='19B_text'>
            <text
              className='cls-2 text'
              transform='translate(307.28 545.72) scale(0.99 1)'>
              19B
            </text>
          </g>
        </g>
        <g
          id='_18B'
          data-name='18B'
          className='no_listing disabled_color active_block'>
          <path
            id='_18B-2'
            data-name='18B'
            className='cls-3 svg_shape'
            d='M511.11,593c-6.66,0-15.73-.28-26.39-.73l-1.32,46.89c10.84.52,20.22.82,27.47.84,8.3,0,19.43-.35,32.35-1l-2-46.89C529,592.69,518.55,593,511.11,593Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_18B_text' data-name='18B_text'>
            <text
              className='cls-2 text'
              transform='translate(365.37 548.1) scale(0.99 1)'>
              18B
            </text>
          </g>
        </g>
        <g
          id='_17B'
          data-name='17B'
          className='no_listing disabled_color active_block'>
          <path
            id='_17B-2'
            data-name='17B'
            className='cls-3 svg_shape'
            d='M541.25,592.13l2,46.89c17.18-.84,37.53-2.15,58.66-3.67L596.37,589C576.45,590.25,557.26,591.39,541.25,592.13Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_17B_text' data-name='17B_text'>
            <text
              className='cls-2 text'
              transform='translate(423.47 545.72) scale(0.99 1)'>
              17B
            </text>
          </g>
        </g>
        <g
          id='_15B'
          data-name='15B'
          className='no_listing disabled_color active_block'>
          <path
            id='_15B-2'
            data-name='15B'
            className='cls-3 svg_shape'
            d='M653.62,584.94l5.21,46c21.75-1.78,41.95-3.52,57.79-4.92l-4.71-45.56C697.89,581.58,677,583.21,653.62,584.94Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_15B_text' data-name='15B_text'>
            <text
              className='cls-2 text'
              transform='translate(537.18 536) scale(0.99 1)'>
              15B
            </text>
          </g>
        </g>
        <g
          id='_16B'
          data-name='16B'
          className='no_listing disabled_color active_block'>
          <path
            id='_16B-2'
            data-name='16B'
            className='cls-3 svg_shape'
            d='M596.37,589l5.51,46.4c18.94-1.37,38.5-2.9,57-4.41l-5.21-46C635.3,586.29,615.5,587.7,596.37,589Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_16B_text' data-name='16B_text'>
            <text
              className='cls-2 text'
              transform='translate(479.66 541.49) scale(0.99 1)'>
              16B
            </text>
          </g>
        </g>
        <g
          id='_14B'
          data-name='14B'
          className='has_listing active_block default_color'>
          <path
            id='_14B-2'
            data-name='14B'
            className='cls-3 svg_shape'
            d='M771,538l-41,41s-6.81.56-18.09,1.46L716.62,626c20.27-1.79,33.38-3,33.38-3l65.22-63.73s1.09-13.68,2.57-33.79l-45.43-5.2C771.53,531.2,771,538,771,538Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_14B_text' data-name='14B'>
            <text
              className='cls-2 text'
              transform='translate(616.92 505.91) scale(0.99 1)'
              data-name='14B'>
              14B
            </text>
          </g>
        </g>
        <g
          id='_13B'
          data-name='13B'
          className='no_listing disabled_color active_block'>
          <path
            id='_13B-2'
            data-name='13B'
            className='cls-3 svg_shape'
            d='M772.36,520.28l45.43,5.2c1.18-15.85,2.59-35.68,3.93-55.94l-45.54-2.24C774.77,487.87,773.35,507.09,772.36,520.28Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_13B_text' data-name='13B_text'>
            <text
              className='cls-2 text'
              transform='translate(649.1 425.76) scale(0.99 1)'>
              13B
            </text>
          </g>
        </g>
        <g
          id='_12B'
          data-name='12B'
          className='no_listing disabled_color active_block'>
          <path
            id='_12B-2'
            data-name='12B'
            className='cls-3 svg_shape'
            d='M776.18,467.3l45.54,2.24c1.24-18.84,2.4-38.06,3.22-54.79l-45.36-1.35C778.65,430.31,777.42,449.31,776.18,467.3Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_12B_text' data-name='12B_text'>
            <text
              className='cls-2 text'
              transform='translate(652.62 370.55) scale(0.99 1)'>
              12B
            </text>
          </g>
        </g>
        <g
          id='_11B'
          data-name='11B'
          className='no_listing disabled_color active_block'>
          <path
            id='_11B-2'
            data-name='11B'
            className='cls-3 svg_shape'
            d='M781.23,369.79c0,9.37-.63,25.25-1.65,43.61l45.36,1.35c.75-15.41,1.22-28.73,1.17-37.69,0-4.74-.23-10.6-.59-17.22L780.93,359C781.11,363.05,781.21,366.69,781.23,369.79Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_11B_text' data-name='11B_text'>
            <text
              className='cls-2 text'
              transform='translate(654.91 316.69) scale(0.99 1)'>
              11B
            </text>
          </g>
        </g>
        <g
          id='_10B'
          data-name='10B'
          className='no_listing disabled_color active_block'>
          <path
            id='_10B-2'
            data-name='10B'
            className='cls-3 svg_shape'
            d='M780.93,359l44.59.83c-.82-15.39-2.43-35-4.26-54.74l-44.09,2.37C778.85,326.21,780.33,345.06,780.93,359Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_10B_text' data-name='10B_text'>
            <text
              className='cls-2 text'
              transform='translate(652.62 262.51) scale(0.99 1)'>
              10B
            </text>
          </g>
        </g>
        <g
          id='_9B'
          data-name='9B'
          className='has_listing active_block default_color'>
          <path
            id='_9B-2'
            data-name='9B'
            className='cls-3 svg_shape'
            d='M777.17,307.47l44.09-2.37c-1.83-19.77-3.88-39.67-5.59-55.64L772.14,256C773.56,269.59,775.47,288.48,777.17,307.47Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_9B_text' data-name='9B_text'>
            <text
              className='cls-2 text'
              transform='translate(652.34 210.11) scale(0.99 1)'>
              9B
            </text>
          </g>
        </g>
        <g
          id='_8B'
          data-name='8B'
          className='has_listing active_block default_color'>
          <path
            id='_8B-2'
            data-name='8B'
            className='cls-3 svg_shape'
            d='M712,177.14c16,1.12,26,1.86,26,1.86l-7.69,15.94L770,236s.88,7.9,2.14,20l43.53-6.55C813.56,229.73,812,216,812,216l-62.71-63.32-4-.35h0c-5.18-.44-15.13-1.28-28.17-2.35Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_8B_text' data-name='8B_text'>
            <text
              className='cls-2 text'
              transform='translate(624.93 135.02) scale(0.99 1)'>
              8B
            </text>
          </g>
        </g>
        <g
          id='_7B'
          data-name='7B'
          className='no_listing disabled_color active_block'>
          <path
            id='_7B-2'
            data-name='7B'
            className='cls-3 svg_shape'
            d='M712,177.14,717.08,150c-15.84-1.3-36.24-2.94-58.23-4.61l-5.37,27.79C676,174.64,696.67,176.07,712,177.14Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_7B_text' data-name='7B_text'>
            <text
              className='cls-2 text'
              transform='translate(540.92 91.31) scale(0.99 1)'>
              7B
            </text>
          </g>
        </g>
        <g
          id='_6B'
          data-name='6B'
          className='no_listing disabled_color active_block'>
          <path
            id='_6B-2'
            data-name='6B'
            className='cls-3 svg_shape'
            d='M653.48,173.17l5.37-27.79c-19.26-1.47-39.75-3-59.45-4.27l-3.64,28.46C614.85,170.68,634.78,171.94,653.48,173.17Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_6B_text' data-name='6B_text'>
            <text
              className='cls-2 text'
              transform='translate(482.94 86.32) scale(0.99 1)'>
              6B
            </text>
          </g>
        </g>
        <g
          id='_5B'
          data-name='5B'
          className='no_listing disabled_color active_block'>
          <path
            id='_5B-2'
            data-name='5B'
            className='cls-3 svg_shape'
            d='M595.76,169.57l3.64-28.46c-21.65-1.43-42.35-2.65-59.46-3.38l-.89,29C555.15,167.32,575,168.36,595.76,169.57Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_5B_text' data-name='5B_text'>
            <text
              className='cls-2 text'
              transform='translate(424.86 82.64) scale(0.99 1)'>
              5B
            </text>
          </g>
        </g>
        <g
          id='_4B'
          data-name='4B'
          className='no_listing disabled_color active_block'>
          <path
            id='_4B-2'
            data-name='4B'
            className='cls-3 svg_shape'
            d='M512.08,166c6.68,0,16,.24,27,.68l.89-29c-11.05-.47-20.61-.74-27.94-.73-7.87,0-18.22.29-30.18.76l1.11,29.05C494.84,166.31,504.9,166,512.08,166Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_4B_text' data-name='4B_text'>
            <text
              className='cls-2 text'
              transform='translate(366.51 82.46) scale(0.99 1)'>
              4B
            </text>
          </g>
        </g>
        <g
          id='_3B'
          data-name='3B'
          className='no_listing disabled_color active_block'>
          <path
            id='_3B-2'
            data-name='3B'
            className='cls-3 svg_shape'
            d='M482.93,166.81l-1.11-29.05c-16.93.67-37.11,1.72-58.12,2.94l3,29C447.15,168.53,466.75,167.48,482.93,166.81Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_3B_text' data-name='3B_text'>
            <text
              className='cls-2 text'
              transform='translate(308.95 84.25) scale(0.99 1)'>
              3B
            </text>
          </g>
        </g>
        <g
          id='_1B'
          data-name='1B'
          className='no_listing disabled_color active_block'>
          <path
            id='_1B-2'
            data-name='1B'
            className='cls-3 svg_shape'
            d='M370.8,173.22l-5-28.9c-22,1.46-42.49,2.89-58.42,4l3.05,29C325.79,176.25,347.29,174.76,370.8,173.22Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_1B_text' data-name='1B_text'>
            <text
              className='cls-2 text'
              transform='translate(194.27 90) scale(0.99 1)'>
              1B
            </text>
          </g>
        </g>
        <g
          id='_2B'
          data-name='2B'
          className='no_listing disabled_color active_block'>
          <path
            id='_2B-2'
            data-name='2B'
            className='cls-3 svg_shape'
            d='M426.67,169.73l-3-29c-19.2,1.12-39.11,2.38-57.88,3.62l5,28.9C388.87,172,408.13,170.82,426.67,169.73Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_2B_text' data-name='2B_text'>
            <text
              className='cls-2 text'
              transform='translate(251.88 86.32) scale(0.99 1)'>
              2B
            </text>
          </g>
        </g>
      </g>
      <g id='Upper_Tier'>
        <g
          id='_28C'
          data-name='28C'
          className='no_listing disabled_color active_block'>
          <path
            id='_28C-2'
            data-name='28C'
            className='cls-3 svg_shape'
            d='M204.14,213.32,271,147l6.84-.66L270.6,89.75C253.54,91.12,243,92,243,92l-94,96s-.83,10.38-2.05,26.58l56.49,7.54C203.88,216.54,204.14,213.32,204.14,213.32Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_28C_text' data-name='28C_text'>
            <text
              className='cls-6 text'
              transform='translate(65.38 90) scale(0.99 1)'>
              28C
            </text>
          </g>
        </g>
        <g
          id='_27C'
          data-name='27C'
          className='no_listing disabled_color active_block'>
          <path
            id='_27C-2'
            data-name='27C'
            className='cls-3 svg_shape'
            d='M203.44,222.12,147,214.58c-1.15,15.34-2.66,35.89-4.15,57.84l56.56,4.75C201,253.94,202.57,233.29,203.44,222.12Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_27C_text' data-name='27C_text'>
            <text
              className='cls-6 text'
              transform='translate(22.11 178.06) scale(0.99 1)'>
              27C
            </text>
          </g>
        </g>
        <g
          id='_26C'
          data-name='26C'
          className='no_listing disabled_color active_block'>
          <path
            id='_26C-2'
            data-name='26C'
            className='cls-3 svg_shape'
            d='M199.36,277.17l-56.56-4.75c-1.29,19-2.57,39.11-3.59,57.73l56.62,2.33C196.83,314.62,198.11,295.13,199.36,277.17Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_26C_text' data-name='26C_text'>
            <text
              className='cls-6 text'
              transform='translate(18.33 234) scale(0.99 1)'>
              26C
            </text>
          </g>
        </g>
        <g
          id='_25C'
          data-name='25C'
          className='no_listing disabled_color active_block'>
          <path
            id='_25C-2'
            data-name='25C'
            className='cls-3 svg_shape'
            d='M194,381.08c0-11.42.74-29,1.83-48.6l-56.62-2.33C137.9,353.86,137,375.2,137,389c0,13.24.85,33.5,2.1,56.11l57.51-2.43C195.11,417.31,194,394.25,194,381.08Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_25C_text' data-name='25C_text'>
            <text
              className='cls-6 text'
              transform='translate(15.8 318.84) scale(0.99 1)'>
              25C
            </text>
          </g>
        </g>
        <g
          id='_24C'
          data-name='24C'
          className='no_listing disabled_color active_block'>
          <path
            id='_24C-2'
            data-name='24C'
            className='cls-3 svg_shape'
            d='M196.61,442.68l-57.51,2.43c1,18.59,2.31,38.76,3.62,57.93l57.59-3.76C199,481.17,197.72,461.31,196.61,442.68Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_24C_text' data-name='24C_text'>
            <text
              className='cls-6 text'
              transform='translate(18.69 404.02) scale(0.99 1)'>
              24C
            </text>
          </g>
        </g>
        <g
          id='_23C'
          data-name='23C'
          className='no_listing disabled_color active_block'>
          <path
            id='_23C-2'
            data-name='23C'
            className='cls-3 svg_shape'
            d='M200.31,499.28,142.72,503c1.54,22.57,3.11,43.74,4.29,59.31l57.29-8.43C203.45,542.77,201.93,522.47,200.31,499.28Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_23C_text' data-name='23C_text'>
            <text
              className='cls-6 text'
              transform='translate(24.04 461.1) scale(0.99 1)'>
              23C
            </text>
          </g>
        </g>
        <g
          id='_22C'
          data-name='22C'
          className='no_listing disabled_color active_block'>
          <path
            id='_22C-2'
            data-name='22C'
            className='cls-3 svg_shape'
            d='M273,630l-68-67s-.26-3.32-.7-9.08L147,562.35C148.2,578,149,588,149,588l98,96s9.88,1,26,2.52l7.15-56Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_22C_text' data-name='22C_text'>
            <text
              className='cls-6 text'
              transform='translate(66.81 545.72) scale(0.99 1)'>
              22C
            </text>
          </g>
        </g>
        <g
          id='_21C'
          data-name='21C'
          className='no_listing disabled_color active_block'>
          <path
            id='_21C-2'
            data-name='21C'
            className='cls-3 svg_shape'
            d='M280.11,630.54l-7.15,56c15.31,1.46,36.24,3.42,59.64,5.5l4.84-57.35C311.74,632.88,290.59,631.32,280.11,630.54Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_21C_text' data-name='21C_text'>
            <text
              className='cls-6 text'
              transform='translate(156.69 591.37) scale(0.99 1)'>
              21C
            </text>
          </g>
        </g>
        <g
          id='_20C'
          data-name='20C'
          className='no_listing disabled_color active_block'>
          <path
            id='_20C-2'
            data-name='20C'
            className='cls-3 svg_shape'
            d='M337.44,634.67,332.6,692c18.82,1.67,39.23,3.42,59.58,5.05l3.62-58.5C375.51,637.29,355.37,635.92,337.44,634.67Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_20C_text' data-name='20C_text'>
            <text
              className='cls-6 text'
              transform='translate(213.19 595.52) scale(0.99 1)'>
              20C
            </text>
          </g>
        </g>
        <g
          id='_19C'
          data-name='19C'
          className='no_listing disabled_color active_block'>
          <path
            id='_19C-2'
            data-name='19C'
            className='cls-3 svg_shape'
            d='M395.8,638.57l-3.62,58.5c20.77,1.66,41.49,3.2,60.4,4.4L454.79,642C436.34,641.06,416,639.85,395.8,638.57Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_19C_text' data-name='19C_text'>
            <text
              className='cls-6 text'
              transform='translate(272.47 600.27) scale(0.99 1)'>
              19C
            </text>
          </g>
        </g>
        <g
          id='_17C'
          data-name='17C'
          className='no_listing disabled_color active_block'>
          <path
            id='_17C-2'
            data-name='17C'
            className='cls-3 svg_shape'
            d='M571.41,641.78l2.11,59.85c19-1.14,39.77-2.58,60.55-4.16l-3.93-59.31C610.11,639.51,589.89,640.78,571.41,641.78Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_17C_text' data-name='17C_text'>
            <text
              className='cls-6 text'
              transform='translate(450.87 600.27) scale(0.99 1)'>
              17C
            </text>
          </g>
        </g>
        <g
          id='_16C'
          data-name='16C'
          className='has_listing active_block default_color'>
          <path
            id='_16C-2'
            data-name='16C'
            className='cls-3 svg_shape'
            d='M630.14,638.16l3.93,59.31c20.08-1.51,40.19-3.14,58.79-4.71L688.51,634C670.57,635.36,650.45,636.8,630.14,638.16Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_16C_text' data-name='16C'>
            <text
              className='cls-6 text'
              transform='translate(510.48 595.4) scale(0.99 1)'
              data-name='16C'>
              16C
            </text>
          </g>
        </g>
        <g
          id='_15C'
          data-name='15C'
          className='has_listing active_block default_color'>
          <path
            id='_15C-2'
            data-name='15C'
            className='cls-3 svg_shape'
            d='M688.51,634l4.35,58.72c23.68-2,44.9-3.88,60.47-5.29L747,629.56C736.43,630.4,714.83,632.1,688.51,634Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_15C_text' data-name='15C'>
            <text
              className='cls-6 text'
              transform='translate(569.91 591.37) scale(0.99 1)'
              data-name='15C'>
              15C
            </text>
          </g>
        </g>
        <g
          id='_14C'
          data-name='14C'
          className='no_listing disabled_color active_block'>
          <path
            id='_14C-2'
            data-name='14C'
            className='cls-3 svg_shape'
            d='M820,562l-66,67-7,.56,6.33,57.91C769.84,686,780,685,780,685l95-95s1.23-11.71,3-29.75l-57.32-7.13C820.26,558.75,820,562,820,562Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_14C_text' data-name='14C_text'>
            <text
              className='cls-6 text'
              transform='translate(657.35 545.8) scale(0.99 1)'>
              14C
            </text>
          </g>
        </g>
        <g
          id='_13C'
          data-name='13C'
          className='no_listing disabled_color active_block'>
          <path
            id='_13C-2'
            data-name='13C'
            className='cls-3 svg_shape'
            d='M820.69,553.12,878,560.25c1.54-15.62,3.49-36,5.41-57.63l-58.63-5.2C823.13,521.18,821.56,541.91,820.69,553.12Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_13C_text' data-name='13C_text'>
            <text
              className='cls-6 text'
              transform='translate(701.04 458.45) scale(0.99 1)'>
              13C
            </text>
          </g>
        </g>
        <g
          id='_12C'
          data-name='12C'
          className='has_listing active_block default_color'>
          <path
            id='_12C-2'
            data-name='12C'
            className='cls-3 svg_shape'
            d='M828.4,442.1c-1.09,18.32-2.37,37.65-3.61,55.32l58.63,5.2c1.69-19,3.35-39,4.68-57.57Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_12C_text' data-name='12C'>
            <text
              className='cls-6 text'
              transform='translate(705.43 401.32) scale(0.99 1)'
              data-name='12C'>
              12C
            </text>
          </g>
        </g>
        <g
          id='_11C'
          data-name='11C'
          className='no_listing disabled_color active_block'>
          <path
            id='_11C-2'
            data-name='11C'
            className='cls-3 svg_shape'
            d='M831.06,376.91c.07,14-1.08,38.53-2.66,65.19l59.7,2.95c1.77-24.58,3-46.75,2.9-61.05-.06-12.95-1.26-32.66-3-54.67l-59.35,3.44C830.05,350.54,831,366.4,831.06,376.91Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_11C_text' data-name='11C_text'>
            <text
              className='cls-6 text'
              transform='translate(710.72 318.84) scale(0.99 1)'>
              11C
            </text>
          </g>
        </g>
        <g
          id='_10C'
          data-name='10C'
          className='no_listing disabled_color active_block'>
          <path
            id='_10C-2'
            data-name='10C'
            className='cls-3 svg_shape'
            d='M828.64,332.77,888,329.33c-1.44-18.22-3.25-38-5.09-56.87l-59.32,5C825.4,295.52,827.24,315.09,828.64,332.77Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_10C_text' data-name='10C_text'>
            <text
              className='cls-6 text'
              transform='translate(705.43 234) scale(0.99 1)'>
              10C
            </text>
          </g>
        </g>
        <g
          id='_9C'
          data-name='9C'
          className='no_listing disabled_color active_block'>
          <path
            id='_9C-2'
            data-name='9C'
            className='cls-3 svg_shape'
            d='M823.58,277.41l59.32-5c-2.19-22.45-4.41-43.55-6.09-59l-59.11,8.75C819,233.41,821.25,254.19,823.58,277.41Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_9C_text' data-name='9C_text'>
            <text
              className='cls-6 text'
              transform='translate(703.69 178.06) scale(0.99 1)'>
              9C
            </text>
          </g>
        </g>
        <g
          id='_8C'
          data-name='8C'
          className='has_listing active_block default_color'>
          <path
            id='_8C-2'
            data-name='8C'
            className='cls-3 svg_shape'
            d='M753,149l63.7,64.32s.38,3.25,1,8.84l59.11-8.75C875.13,197.9,874,188,874,188L778,91s-10-.81-26.25-2.06l-6.07,59.27Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_8C_text' data-name='8C'>
            <text
              className='cls-6 text'
              transform='translate(661.63 92.24) scale(0.99 1)'
              data-name='8C'>
              8C
            </text>
          </g>
        </g>
        <g
          id='_7C'
          data-name='7C'
          className='has_listing active_block default_color'>
          <path
            id='_7C-2'
            data-name='7C'
            className='cls-3 svg_shape'
            d='M718,145.32h0c12,1.23,21.61,2.24,27.72,2.89l6.07-59.27c-15.44-1.19-36.52-2.78-60.07-4.45l-4.46,57.73C698.48,143.33,708.9,144.39,718,145.32Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_7C_text' data-name='7C'>
            <text
              className='cls-6 text'
              transform='translate(572.87 47.24) scale(0.99 1)'
              data-name='7C'>
              7C
            </text>
          </g>
        </g>
        <g
          id='_6C'
          data-name='6C'
          className='no_listing disabled_color active_block'>
          <path
            id='_6C-2'
            data-name='6C'
            className='cls-3 svg_shape'
            d='M687.22,142.22l4.46-57.73c-18.86-1.35-39.29-2.75-59.67-4.06l-3.67,56.21C648.79,138.48,669.1,140.42,687.22,142.22Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_6C_text' data-name='6C_text'>
            <text
              className='cls-6 text'
              transform='translate(514.88 41.85) scale(0.99 1)'>
              6C
            </text>
          </g>
        </g>
        <g
          id='_5C'
          data-name='5C'
          className='no_listing disabled_color active_block'>
          <path
            id='_5C-2'
            data-name='5C'
            className='cls-3 svg_shape'
            d='M632,80.43c-20.56-1.31-41-2.52-59.8-3.46l-2.54,42.77c18.7.74,39.26,1.91,59.53,3.73Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_5C_text' data-name='5C_text'>
            <text
              className='cls-6 text'
              transform='translate(455.27 30.09) scale(0.99 1)'>
              5C
            </text>
          </g>
        </g>
        <g
          id='_3C'
          data-name='3C'
          className='no_listing disabled_color active_block'>
          <path
            id='_3C-2'
            data-name='3C'
            className='cls-3 svg_shape'
            d='M391.6,80.8,394.4,123c19.86-1.6,40-2.63,58.46-3.28l-2.06-42.5C432.22,78.18,412,79.44,391.6,80.8Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_3C_text' data-name='3C_text'>
            <text
              className='cls-6 text'
              transform='translate(277.09 30.09) scale(0.99 1)'>
              3C
            </text>
          </g>
        </g>
        <g
          id='_2C'
          data-name='2C'
          className='no_listing disabled_color active_block'>
          <path
            id='_2C-2'
            data-name='2C'
            className='cls-3 svg_shape'
            d='M395.26,136,391.6,80.8c-20.83,1.39-41.74,2.89-61,4.32L336.05,141C354.23,139.38,374.66,137.62,395.26,136Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_2C_text' data-name='2C_text'>
            <text
              className='cls-6 text'
              transform='translate(217.59 41.23) scale(0.99 1)'>
              2C
            </text>
          </g>
        </g>
        <g
          id='_1C'
          data-name='1C'
          className='no_listing disabled_color active_block'>
          <path
            id='_1C-2'
            data-name='1C'
            className='cls-3 svg_shape'
            d='M306.9,143.62h0c8.66-.79,18.52-1.69,29.15-2.63l-5.48-55.87c-23.39,1.74-44.4,3.39-60,4.63l7.24,56.59C284.05,145.75,294.19,144.79,306.9,143.62Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_1C_text' data-name='1C_text'>
            <text
              className='cls-6 text'
              transform='translate(156.71 47.24) scale(0.99 1)'>
              1C
            </text>
          </g>
        </g>
        <g
          id='_4C'
          data-name='4C'
          className='no_listing disabled_color active_block'>
          <path
            id='_4C-2'
            data-name='4C'
            className='cls-3 svg_shape'
            d='M512,75c-15,0-36.62.88-61.2,2.19l2.06,42.5c24.23-.87,45.48-1.09,59.14-1.11,13.28,0,34,.22,57.67,1.16L572.21,77C548,75.75,526.76,75,512,75Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_4C_text' data-name='4C_text'>
            <text
              className='cls-6 text'
              transform='translate(365.7 27.09) scale(0.99 1)'>
              4C
            </text>
          </g>
        </g>
        <g
          id='_18C'
          data-name='18C'
          className='no_listing disabled_color active_block'>
          <path
            id='_18C-2'
            data-name='18C'
            className='cls-3 svg_shape'
            d='M510.84,644c-13.13,0-33.12-.81-56-2l-2.21,59.46C476.43,703,497.4,704,512,704c15,0,36.81-.9,61.52-2.37l-2.11-59.85C546.52,643.14,524.77,644,510.84,644Z'
            transform='translate(-136.5 -74.5)'
            style={{ fill: 'rgb(44, 105, 187)' }}
          />
          <g id='_18C_text' data-name='18C_text'>
            <text
              className='cls-6 text'
              transform='translate(362.3 605.5) scale(0.99 1)'>
              18C
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Hamburg;
