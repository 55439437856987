import * as S from './styles';
import { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import CheckoutForm from './form';
import { Elements } from '@stripe/react-stripe-js';
import { country_list } from '../../countries';
import { toast } from 'react-toastify';
const Checkout = () => {
  const [data, setData] = useState({});
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [showPromo, setShowPromo] = useState(false);
  const [promo, setPromo] = useState('');
  const [discount, setDiscount] = useState(false);
  const checkPromo = () => {
    if (promo === porsche) {
      toast.success('PromoCode Applied ($2 every ticket and a 911 Porsche)');
      setDiscount(2);
    } else if (promo === off) {
      toast.success('PromoCode Applied (10% OFF)');
      setDiscount(10);
    } else {
      toast.error('Invalid PromoCode!');
    }
  };

  const porsche = 'PORSCHE911';
  const off = '10LIVETICKETS';

  const [user, setUser] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    country: { label: 'Brazil', value: 'UK' },
    city: '',
    zip: '',
    address: '',
    apartment: '',
  });

  //Config
  useEffect(() => {
    axios.get('https://backend128.onrender.com/config').then(async (r) => {
      const { publishableKey } = await r.data;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  //Create Payment intent

  useEffect(() => {
    if (data?.price) {
      const a = !discount
        ? Math.round(
            (data?.price * data?.nr_tickets + (data?.price / 100) * 9) * 100
          ) / 100
        : discount === 2
        ? '2'
        : (Math.round(
            Math.round(
              (data?.price * data?.nr_tickets + (data?.price / 100) * 9) * 100
            ) /
              100 -
              ((Math.round(
                (data?.price * data?.nr_tickets + (data?.price / 100) * 9) * 100
              ) /
                100) *
                10) /
                100
          ) *
            100) /
          100;
      axios
        .post(
          'https://backend128.onrender.com/create-payment-intent',
          null,
          {
            params: {
              amount: a * 100,
            },
          }
        )
        .then(async (r) => {
          const { clientSecret } = await r.data;
          setClientSecret(clientSecret);
        });
    }
  }, [data?.price, discount]);

  useEffect(() => {
    document.querySelector('body').style.backgroundColor = '#f7f7f7';
    const item = JSON.parse(sessionStorage.getItem('data'));
    setData({ ...item });
  }, []);

  return (
    <S.Checkout>
      <div className='logo'>
        <img src='/images/logo.svg' />
        <h5>Checkout</h5>
      </div>
      <div className='blocks'>
        <S.Container>
          <h1>The Weeknd</h1>
          <p>
            {data?.date} at {data?.stadiumName}, {data?.country}, {data?.city}
          </p>
          <S.Form>
            <div>
              <p className='number_header'>
                <span>1</span> Contact Info
              </p>
              <div>
                <label>Email</label>
                <input
                  type='text'
                  placeholder='Email Address'
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
              </div>
              <div>
                <label>First Name</label>
                <input
                  type='text'
                  placeholder='First Name'
                  value={user.first_name}
                  onChange={(e) =>
                    setUser({ ...user, first_name: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Last Name</label>
                <input
                  type='text'
                  placeholder='Last Name'
                  value={user.last_name}
                  onChange={(e) =>
                    setUser({ ...user, last_name: e.target.value })
                  }
                />
              </div>
              
            </div>
            <div>
              
            </div>
          </S.Form>
          <S.DeliveryMethod>
            <p className='number_header'>
              <span>3</span> Delivery Method
            </p>
            <p>
              E-tickets will be send as soon as possible to your Email Address.{' '}
              <br />
              The QR-code will be presented at the stadium gates along the PDF
              sent on email.
            </p>
          </S.DeliveryMethod>
          <S.Payment>
            <p className='number_header'>
              <span>4</span> Payment Details
            </p>
            <p>
              We at TicketMasters commit to provide a secure and compliant
              website along the most popular and trustful Payment Solution{' '}
              <a href='https://stripe.com' target='_blank' rel='noreferrer'>
                Stripe
              </a>
            </p>
            {stripePromise && clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm user={user} data={data} />
              </Elements>
            )}
          </S.Payment>
        </S.Container>
        <S.Ticket>
          <div className='summary'>
            <p>Summary</p>
            <img src='/images/the_weeknd_1624448587.png' />
          </div>
          <div className='content'>
            <h4>E-Ticket</h4>
            <div className='category'>
              <div>
                <p>Category</p>
                <p>
                  {data?.zone?.toString().includes('Front')
                    ? `Front Floor`
                    : data?.zone?.toString().includes('Back')
                    ? 'Back Floor'
                    : data.zone >= 400
                    ? 'Upper Tier'
                    : 'Lower Tier'}
                </p>
              </div>
              <div>
                <p>Block</p>
                <p>{data?.zone}</p>
              </div>
              <div>
                <p>Row</p>
                <p>{data?.row === '' ? '1' : data?.row}</p>
              </div>
            </div>
            <div className='prices'>
              <div>
                <p>Price</p>
                <p>
                  €{data?.price} x {data?.nr_tickets}
                </p>
              </div>
              <div>
                <p>Fees</p>
                <p>
                  €
                  {data?.price &&
                    ((data?.price / 100) * 9).toString().slice(0, 4)}
                </p>
              </div>
              <div>
                <p>VAT</p>
                <p>€0</p>
              </div>
              {showPromo ? (
                <button onClick={() => setShowPromo(true)}>
                  Use a Discount Coupon
                </button>
              ) : (
                <div className='promo'>
                  <input
                    type='text'
                    placeholder='Promo Code'
                    value={promo}
                    onChange={(e) => setPromo(e.target.value)}
                  />
                  <button onClick={() => checkPromo()}>Apply</button>
                </div>
              )}
            </div>
            <div className='total'>
              <p>Total</p>
              <p>
                €
                {!discount
                  ? Math.round(
                      (data?.price * data?.nr_tickets +
                        (data?.price / 100) * 9) *
                        100
                    ) / 100
                  : discount === 2
                  ? '2'
                  : (Math.round(
                      Math.round(
                        (data?.price * data?.nr_tickets +
                          (data?.price / 100) * 9) *
                          100
                      ) /
                        100 -
                        ((Math.round(
                          (data?.price * data?.nr_tickets +
                            (data?.price / 100) * 9) *
                            100
                        ) /
                          100) *
                          10) /
                          100
                    ) *
                      100) /
                    100}
              </p>
            </div>
            <div className='guarantees'>
              <div>
                <img src='/images/guarantee_sheild.svg' />
                <p>
                  We guarantee you’ll get the tickets you ordered just on time.
                </p>
              </div>
              <div>
                <img src='/images/guarantee_sheild.svg' />
                <p>
                  TicketMasters Guarantee - Get your money back if the event is
                  canceled and not rescheduled.
                </p>
              </div>
            </div>
            <div className='additional'>
              <p>Additional Ticket Information:</p>
              <p>
                The charge will appear as TicketMasters STD or TicketMasters
                Services OU on your credit card statement.
              </p>
            </div>
          </div>
        </S.Ticket>
      </div>
    </S.Checkout>
  );
};

export default Checkout;
