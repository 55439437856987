import styled from 'styled-components';

export const BuyComponent = styled.div`
  background: #f7f7f7;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;
export const Aside = styled.div`
  width: 650px;
  background: white;
  color: #212121;
  height: calc(100vh - 179px);
  overflow: auto;
  position: relative;

  @media (max-width: 1450px) {
    width: 400px;
  }
  @media (max-width: 900px) {
    width: 100%;
    height: fit-content;
    padding-top: 16px;
  }
  .sort_by {
    display: flex;
    align-items: center;
    gap: 16px;

    & > div {
      width: 170px;
    }
  }
  .clear_filters {
    position: absolute;
    top: 18px;
    right: 15px;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: 0.3s ease-in-out;
    @media (max-width: 1450px) {
      position: relative;
      top: 0;
      right: 0;
    }
    &:hover {
      background: rgb(44, 105, 187);
      cursor: pointer;
      @media (max-width: 768px) {
        background: transparent;
      }
    }
    img {
      max-width: 12px;
      height: auto !important;
      margin-right: 8px;
    }
  }
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  .filters {
    height: 70px;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    @media (max-width: 1450px) {
      flex-direction: column;
      gap: 8px;
      height: max-content;
      align-items: flex-start;
    }
  }
`;
export const Stadium = styled.div`
  width: calc(100% - 650px);
  @media (max-width: 1450px) {
    width: calc(100% - 400px);
  }
  @media (max-width: 900px) {
    width: 100%;
    padding: 20px 0;
  }
`;

export const Ticket = styled.div`
  padding: 20px 20px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  @media (max-width: 1450px) {
    gap: 8px;
  }
  &:hover {
    background: #f7f7f7;
    cursor: pointer;
  }
  ${({ active }) => active && `background: #f7f7f7;`}
  h2 {
    font-size: 22px;
    font-weight: 500;
    color: rgb(44, 105, 187);
    margin-bottom: 9px;
  }
  button {
    margin-left: auto;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 20px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(226, 25, 55);
    box-shadow: none !important;
    min-width: 90px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
    margin-bottom: 8px;
    &:hover {
      background-color: rgb(167, 22, 45);
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
