import styled from 'styled-components';

export const Content = styled.div`
  min-height: calc(100vh - 240px);
  color: #212121;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  @media (max-width: 600px) {
    padding: 0 30px;
  }
  h1 {
    color: #1565c0;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 42px;
  }
  p:first-of-type {
    font-size: 18px;
    color: #022d5f;
    margin-bottom: 8px;
  }
  p:last-of-type {
    margin-bottom: 8px;
    font-size: 20px;
    color: #022d5f;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      margin-bottom: 4px;
      br {
        display: none;
      }
    }
    span {
      padding: 10px;
      background: #c2c2c2;
      border-radius: 8px;
      color: white;
      font-weight: 600;
      @media (max-width: 768px) {
        display: block;
        width: fit-content;
        margin-top: 10px;
        margin-bottom: 8px;
      }
    }
  }
  a {
    width: 190px;
    margin: 10px 0;
    height: 36px;
    border: none;
    outline: none;
    height: 45px;
    background: #1565c0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 17px;
    transition: 0.3s ease-in-out;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      background: #0f498c;
      cursor: pointer;
    }
    img {
      max-width: 8px;
      height: auto;
      margin-right: 5px;
    }
  }
`;
