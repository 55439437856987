import styled from 'styled-components';

export const Stadium = styled.div`
  background: #f7f7f7;
  margin: 0 !important;
  /*! CSS Used from: https://www.gigsberg.com/assets/front/css/cached/d4a55e5a97d37d0afde0fb267b8cfa0f.css?v=1669835027 ; media=all */

  .norway .active_block.disabled_color {
    fill: #9f9e9e !important;
    cursor: default;
  }
  .norway .disabled_color :not(text):not(.text):not(.st4) {
    fill: #9f9e9e !important;
  }

  .hamburg {
    /*! CSS Used from: https://www.gigsberg.com/assets/front/css/cached/d4a55e5a97d37d0afde0fb267b8cfa0f.css?v=1670247534 ; media=all */
    @media all {
      transition: 0.1s;

      & :not(.svg_shape) {
        cursor: default;
      }
      .active_block {
        cursor: pointer;
      }
      tspan {
        fill: #fff !important;
      }
      .active_block.disabled_color {
        fill: #9f9e9e !important;
        cursor: default;
      }
      .svg_shape {
        transition: 0.3s;
      }
      .disabled_color :not(text):not(.text):not(.st4) {
        fill: #9f9e9e !important;
      }
      #seat-fix svg {
        direction: ltr;
        width: 60%;
        max-width: calc(100vw - 400px);
        max-height: calc(100vh - 300px);
        margin: 0 auto;
      }
      @media only screen and (max-width: 850px) {
        #seat-fix svg {
          margin-top: 100px;
          width: 70%;
        }
      }
      @media only screen and (max-width: 810px) {
        #seat-fix svg {
          height: 100%;
          margin-top: 0;
          max-width: unset;
          max-height: unset;
        }
        ::-webkit-scrollbar {
          width: 0;
          background: 0 0;
        }
      }
    }
    /*! CSS Used from: Embedded ; media=all */
    @media all {
      .cls-1 {
        fill: #464646;
      }
      .cls-2,
      .cls-4 {
        font-size: 13px;
      }
      .cls-2,
      .cls-4,
      .cls-5,
      .cls-6 {
        fill: #fff;
        font-family: Helvetica-Light, Helvetica;
        font-weight: 300;
      }
      .cls-3 {
        fill: #999;
        stroke: #fff;
        stroke-miterlimit: 10;
      }
      .cls-4 {
        letter-spacing: 0em;
      }
      .cls-5 {
        font-size: 14px;
      }
      .cls-6 {
        font-size: 16px;
      }
    }
  }

  .manchester {
    /*! CSS Used from: https://www.gigsberg.com/assets/front/css/cached/d4a55e5a97d37d0afde0fb267b8cfa0f.css?v=1670247534 ; media=all */
    @media all {
      transition: 0.1s;

      &:not(.svg_shape) {
        cursor: default;
      }
      .active_block {
        cursor: pointer;
      }
      tspan {
        fill: #fff !important;
      }
      .svg_shape {
        transition: 0.3s;
      }
      #seat-fix svg {
        direction: ltr;
        width: 60%;
        max-width: calc(100vw - 400px);
        max-height: calc(100vh - 300px);
        margin: 0 auto;
      }
      @media only screen and (max-width: 850px) {
        #seat-fix svg {
          margin-top: 100px;
          width: 70%;
        }
      }
      @media only screen and (max-width: 810px) {
        #seat-fix svg {
          height: 100%;
          margin-top: 0;
          max-width: unset;
          max-height: unset;
        }
        ::-webkit-scrollbar {
          width: 0;
          background: 0 0;
        }
      }
    }
    /*! CSS Used from: Embedded ; media=all */
    @media all {
      .cls-1 {
        fill: #aaa;
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 0.75px;
      }
      .cls-2 {
        font-size: 18px;
      }
      .cls-2,
      .cls-4,
      .cls-5,
      .cls-6 {
        fill: #fff;
      }
      .cls-2,
      .cls-4,
      .cls-6 {
        font-family: ArialMT, Arial;
      }
      .cls-3 {
        letter-spacing: -0.02em;
      }
      .cls-4 {
        font-size: 14px;
      }
      .cls-6 {
        font-size: 16px;
      }
      .cls-7 {
        fill: #464646;
      }
    }
  }

  @media all {
    .ui-draggable-handle {
      -ms-touch-action: none;
      touch-action: none;
    }
    input:disabled {
      opacity: 1;
    }
    svg {
      transition: 0.1s;
    }
    svg :not(.svg_shape) {
      cursor: default;
    }
    svg .active_block {
      cursor: pointer;
    }
    svg tspan {
      fill: #fff !important;
    }
    svg .active_block.disabled_color {
      cursor: default;
    }
    svg .svg_shape {
      transition: 0.3s;
    }
    svg .disabled_color :not(text):not(.text):not(.st4) {
    }
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 0;
      outline: none;
    }
    div,
    span,
    label {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
    input::-ms-clear {
      display: none;
    }
    #seat-fix {
      overflow: hidden;
      transition: 0.3s;
    }
    @media only screen and (max-width: 610px) {
      .zoom_btn {
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size: 20px;
      }
      .zoom_btn#in {
        top: 20px;
        left: 20px;
      }
      .zoom_btn.out {
        top: 20px;
        left: 70px;
      }
      #seat-fix {
        height: 230px;
        text-align: center;
      }
    }

    margin: 0 14px 20px 5px;
    transition: 0.3s;
    position: relative;

    #page_event_page #sidebar {
      padding: 20px;
    }
    #page_event_page .event_tickets_display #sidebar.col_width2 {
      position: sticky;
      position: -webkit-sticky;
      height: 100%;
      top: 91px;
      padding-right: 0;
    }
    #page_event_page .zoom_wrapper {
      height: 100%;
      display: flex;
      align-items: flex-start;
    }
    #page_event_page .venue_zoom_control {
      right: 0;
      position: absolute;
      display: flex;
      height: fit-content;
      flex-direction: column;
      width: fit-content;
      border-radius: 18px;
      padding: 7px 5px;
      background-color: #fff;
      z-index: 99;
      border: 1px solid #e7e7e7;
    }
    .zoom_seperator {
      background-color: #e7e7e7;
      height: 1px;
      width: 100%;
      margin: 2px 0;
    }
    #seat-fix {
      overflow: hidden;
      transition: 0.3s;
      position: relative;
      display: flex;
      height: 90%;
    }
    #seat-fix svg {
      direction: ltr;
      width: 75%;
      max-width: calc(100vw - 400px);
      max-height: calc(100vh - 300px);
      margin: 0 auto;
    }
    .zoom_btn {
      right: 10px;
      border: 1px solid #dcdcdc;
      border-radius: 46px;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      font-family: none;
      font-size: 20px;
      font-weight: 100;
      color: #fff;
      cursor: pointer;
      background-color: #1d6ac2;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .zoom_btn.out {
      line-height: 19px;
    }
    .zoom_btn.reset_listing {
      background-image: url(https://www.gigsberg.com/assets/front/images/reset.png);
      background-size: 75%;
      background-position: 2px;
      background-repeat: no-repeat;
    }
    @media only screen and (max-width: 850px) {
      #seat-fix {
        align-items: flex-start;
      }
      #seat-fix svg {
        width: 70%;
      }
    }
    @media only screen and (max-width: 827px) and (min-width: 811px) {
      #page_event_page .reset_listing {
        top: 15px;
        left: 15px;
      }
    }
    @media only screen and (max-width: 810px) {
      #page_event_page .event_tickets_display #sidebar.col_width2 {
        padding: 0;
        margin: 0;
      }
      #seat-fix svg {
        height: 500px;
        margin-top: 0;
        max-width: 100%;
        max-height: 100%;
        padding: 0 20px;
        width: 100% !important;
      }
      #page_event_page .event_tickets_display .event_bottom_right {
        margin: 0;
        padding: 10px 0;
      }
      .zoom_btn {
        height: 25px;
        width: 25px;
        line-height: 25px;
        font-size: 24px;
      }
      .out.zoom_btn {
        line-height: 18px;
      }
      .zoom_btn#in {
        top: 20px;
        left: 20px;
      }
      .zoom_btn.reset_listing {
        right: 20px;
        bottom: 20px;
      }
      .zoom_btn.reset_listing {
        background-position: 3px 2px;
        background-size: 70%;
      }
      #seat-fix {
        height: 430px;
        text-align: center;
        @media (max-width: 480px) {
          height: 320px;
        }
      }
      #page_event_page .venue_zoom_control {
        right: 10px;
      }
      ::-webkit-scrollbar {
        width: 0;
        background: 0 0;
      }
    }
  }
  /*! CSS Used from: Embedded ; media=all */
  @media all {
    .cls-1 {
      fill: #999;
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
    .cls-2,
    .cls-3,
    .cls-6 {
      isolation: isolate;
    }
    .cls-2 {
      font-size: 14px;
    }
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-6 {
      fill: #fff;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
    }
    .cls-3 {
      font-size: 13px;
    }
    .cls-4 {
      font-size: 19px;
    }
    .cls-5 {
      fill: #444;
    }
    .cls-6 {
      font-size: 26px;
    }
  }
  ${({ isLondon }) =>
    !isLondon &&
    `g path {
    fill: rgb(44, 105, 187) !important;
  }`}
  .grey_out {
    path,
    rect {
      fill: #999 !important;
    }
    path.text {
      fill: #fff !important;
    }
  }
  .active_block:not(.grey_out) {
    path,
    rect,
    text,
    polygon {
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
    &:hover {
      fill: #1e4982 !important;
      path,
      rect,
      polygon {
        fill: #1e4982 !important;
      }
      path.text {
        fill: #fff !important;
      }
    }
  }
  .active_color {
    fill: #1e4982 !important;
    path,
    rect,
    polygon {
      fill: #1e4982 !important;
    }
    path.text {
      fill: #fff !important;
    }
  }
`;
